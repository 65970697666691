import { el } from 'redom';

import { stringToHtml, textToHtml } from '~services/util';
import { LinkOptions, setSocialLinks } from './sectionLinks';
import { INameId } from '~src/Tools/toolClasses';
import './socials.sass'

export interface IAboutOption {
    picture?: string,
    pseudo?: string,
    description?: string,
    location?: string,
    contact?: string,
    socials?: LinkOptions[],
    summary?: string,
    skills?: INameId[],
    softwares?: INameId[],
}

export interface IAboutOptionSure {
    picture: string,
    pseudo: string,
    description: string,
    location: string,
    contact: string,
    socials: LinkOptions[],
    summary: string,
    skills: INameId[],
    softwares: INameId[],
}

const LOCATION_ICON = '<i class="fa-solid fa-location-dot"></i>';
const CONTACT_ICON = '<i class="fa-solid fa-envelope"></i>';

const FOOTER_HTML_STRING = `
    <footer class="about about-text footer">
        <div class="container">
            <div class="row align-items-top text-center text-md-left">
                <div class="col-12 col-sm-4">
                    <a id="about-button" class="nav-link h1">About</a>
                </div>

                <div id="watermark-container" class="col-12 col-sm-4"></div>

                <div class="col-12 col-sm-4">
                    <div id="f-socials"></div>
                </div>
            </div>
        </div>
    </footer>
`
const FOOTER_HTML = stringToHtml(FOOTER_HTML_STRING);

const ABOUT_HTML_STRING = `
    <section class="fdb-block about about-text">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <img id="picture" alt="image" src="https://res.cloudinary.com/dwrvacpsr/image/upload/v1617525478/no-picture_rvexjh.jpg">
                    <h1 id="pseudo" class="about-title">Artist</h1>
                    <p id="description"></p>
                    <div id="location-container">
                        ${LOCATION_ICON}
                        <p id="location"></p>
                    </div>
                    <div id="contact-container">
                        ${CONTACT_ICON}
                        <p id="contact"></p>
                    </div>
                    <div id="socials"></div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8">
                    <div id="summary-container" class="w-100" >
                        <hr/>
                        <h1 class="about-title">Summary</h1>
                        <p id="summary"></p>
                    </div>
                    <div id="skills-container" class="w-100" >
                        <hr />
                        <h1 class="about-title">Skills</h1>
                        <div id="skills"></div>
                    </div>
                    <div id="softwares-container" class="w-100" >
                        <hr/>
                        <h1 class="about-title">Softwares</h1>
                        <div id="softwares"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
`
const ABOUT_HTML = stringToHtml(ABOUT_HTML_STRING);

export class SectionAbout {

    public aboutHtml: HTMLElement;
    public aboutButton: HTMLElement;
    public footerHtml: HTMLElement;

    constructor(parent: HTMLElement) {
        this.footerHtml = FOOTER_HTML;
        parent.appendChild(this.footerHtml)
        this.aboutHtml = ABOUT_HTML;

        // Wait for footer to be built
        setTimeout(() => {
            this.aboutButton = this.footerHtml.querySelector("#about-button") as HTMLElement;
            this.aboutButton.addEventListener("click", this._onAbout);
            this.checkAboutButtonVisibility()
        }, 200)
    }

    private _onAbout: () => void
    public set onAbout(v: () => void) {
        this._onAbout = v;
    }

    private _options: IAboutOptionSure = {
        picture: '',
        pseudo: '',
        description: '',
        location: '',
        contact: '',
        summary: '',
        socials: [],
        skills: [],
        softwares: [],
    }

    public set options(v: IAboutOption) {
        if (v.picture !== undefined) this.picture = v.picture
        if (v.pseudo !== undefined) this.pseudo = v.pseudo
        if (v.description !== undefined) this.description = v.description
        if (v.location !== undefined) this.location = v.location
        if (v.contact !== undefined) this.contact = v.contact
        if (v.socials !== undefined) this.socials = v.socials
        if (v.summary !== undefined) this.summary = v.summary
        if (v.skills !== undefined) this.skills = v.skills
        if (v.softwares !== undefined) this.softwares = v.softwares
        this.checkAboutButtonVisibility()
    }

    public get options(): IAboutOptionSure {
        return { ...this._options };
    }

    public checkAboutButtonVisibility() {
        if (this.aboutButton) {
            this.aboutButton.style.display = "none"
            const keys = Object.keys(this.options)
            keys.forEach(k => {
                const v = this.options[k]
                if (v.length && k !== 'socials') this.aboutButton.style.display = "flex"
            });
        }
    }

    public set pseudo(v: string) {
        this._options.pseudo = v;
        const pseudoHtml = this.aboutHtml.querySelector("#pseudo") as HTMLElement;
        pseudoHtml.innerHTML = textToHtml(v);
    }

    public get pseudo(): string {
        return this._options.pseudo
    }

    public set picture(v: string) {
        this._options.picture = v;
        let pictureHtml = this.aboutHtml.querySelector("#picture") as HTMLImageElement;
        pictureHtml.src = v;
    }

    public get picture(): string {
        return this._options.picture
    }

    public set description(v: string) {
        this._options.description = v;
        const descriptionHtml = this.aboutHtml.querySelector("#description") as HTMLElement;
        descriptionHtml.innerHTML = textToHtml(v);
    }

    public get description(): string {
        return this._options.description
    }

    public set location(v: string) {
        this._options.location = v;
        const locationContainer = this.aboutHtml.querySelector("#location-container") as HTMLElement;
        locationContainer.style.display = (v.length) ? 'flex' : 'none';
        const locationHtml = this.aboutHtml.querySelector("#location") as HTMLElement;
        locationHtml.innerHTML = textToHtml(v);
    }

    public get location(): string {
        return this._options.location
    }

    public set contact(v: string) {
        this._options.contact = v;
        const contactContainer = this.aboutHtml.querySelector("#contact-container") as HTMLElement;
        contactContainer.style.display = (v.length) ? 'flex' : 'none';
        const contactHtml = this.aboutHtml.querySelector("#contact") as HTMLElement;
        contactHtml.innerHTML = textToHtml(v);
    }

    public get contact(): string {
        return this._options.contact
    }

    public set summary(v: string) {
        this._options.summary = v;
        const summaryContainer = this.aboutHtml.querySelector("#summary-container") as HTMLElement;
        summaryContainer.style.display = (v.length) ? 'block' : 'none';
        const summaryHtml = this.aboutHtml.querySelector("#summary") as HTMLElement;
        summaryHtml.innerHTML = textToHtml(v);
    }

    public get summary(): string {
        return this._options.summary
    }

    public set socials(v: LinkOptions[]) {
        this._options.socials = v;
        const socialsHtml = this.aboutHtml.querySelector("#socials") as HTMLElement;
        setSocialLinks(socialsHtml, v);
        const socialsFooterHtml = this.footerHtml.querySelector("#f-socials") as HTMLElement;
        setSocialLinks(socialsFooterHtml, v);
    }

    public get socials(): LinkOptions[] {
        return [...this._options.socials]
    }

    public set skills(v: INameId[]) {
        this._options.skills = v;
        const skillsContainer = this.aboutHtml.querySelector("#skills-container") as HTMLElement;
        skillsContainer.style.display = (v.length) ? 'block' : 'none';

        const skillsHtml = this.aboutHtml.querySelector("#skills") as HTMLElement;
        skillsHtml.innerHTML = '';
        v.forEach(s => {
            let skill = el('div.skill', s.name)
            skillsHtml.appendChild(skill);
        });
    }

    public get skills(): INameId[] {
        return [...this._options.skills]
    }

    public set softwares(v: INameId[]) {
        this._options.softwares = v;
        const softwaresContainer = this.aboutHtml.querySelector("#softwares-container") as HTMLElement;
        softwaresContainer.style.display = (v.length) ? 'block' : 'none';

        const softwaresHtml = this.aboutHtml.querySelector("#softwares") as HTMLElement;
        softwaresHtml.innerHTML = '';
        v.forEach(s => {
            let software = el('div.software', s.name)
            softwaresHtml.appendChild(software);
        });
    }

    public get softwares(): INameId[] {
        return [...this._options.softwares]
    }
}
