import { Vector2, Vector4 } from '@babylonjs/core/Maths/math';
import cloneDeep from 'lodash/cloneDeep';

import { Point2, Point4 } from '~src/Node/nodePosition';
import {
    Shader, IShader, SHADER_FILTER_KEYS, SHADER_MASK_VEC4_KEYS, SHADER_NOISE_KEYS, IFilters
} from './shader';

export const WHITE_NOISE = 'https://d2w9uul1fh5o24.cloudfront.net/editor/scrollImages/v2/512_webp/noise_000.webp';

const HSL_WHITE = [0, 1, 1, 0];
const HSL_BLACK = [0, 1, 0, 0];

export abstract class ShaderSave extends Shader {
    protected _shader: IShader = {};

    public get shader(): IShader {
        return { ...this._shader };
    }

    public setAndSaveShader(shader: IShader) {
        //! Do it before add after render to make sure it saved and it is working
        this._setAndSaveShader(shader);
        //! Need to do the change after render or some changes are not applied
        this.system.addOnce(() => {
            this._setAndSaveShader(shader);
        });
    }

    public addShaderOptions(shader: IShader) {
        const newShader = cloneDeep(this._shader);
        const keys = Object.keys(shader);
        keys.forEach((key) => {
            newShader[key] = shader[key];
        });
        this.setAndSaveShader(newShader)
    }

    protected _setAndSaveShader(shader: IShader) {
        this._shader = { ...shader };
        if (!shader || !this.isReady) return;
        if (!Object.keys(shader).length) {
            this.deleteShader();
        } else {
            SHADER_MASK_VEC4_KEYS.forEach((key) => {
                if (shader[key] !== undefined) this.setAndSaveShaderVector4(key, shader[key]);
            });
            SHADER_NOISE_KEYS.forEach((key) => {
                if (shader[key] !== undefined) this.setAndSaveShaderValue(key, shader[key]);
            });
            if (shader.filters !== undefined) this.setAndSaveFilters(shader.filters);
            if (shader.textureNoise !== undefined) this.setAndSaveTextureNoiseUrl(shader.textureNoise);
            if (shader.textureFrequency !== undefined) this.setAndSaveTextureFrequency(shader.textureFrequency);
            if (shader.shaderColor1 !== undefined) this.setAndSaveShaderColor1(shader.shaderColor1);
            if (shader.shaderColor2 !== undefined) this.setAndSaveShaderColor2(shader.shaderColor2);
        }
    }

    protected initShaderUniform() {
        if (!this.isReady) return;
        this.resetFilters();
        this.setShaderVector2('offset', Vector2.Zero());
        SHADER_MASK_VEC4_KEYS.forEach((key) => {
            this.setShaderVector4(key, Vector4.Zero());
        });
        SHADER_NOISE_KEYS.forEach((key) => {
            this.setShaderValue(key, 0);
        });
        this.setTextureNoiseUrl(WHITE_NOISE);
        this.setTextureFrequency(1);
        this.setShaderValue('visibility', 1);
        this.setShaderColor('shaderColor1', HSL_WHITE); // HSL White
        this.setShaderColor('shaderColor2', HSL_WHITE); // HSL Black
        this.checkResolution();
    }

    public resetShader() {
        if (!this.isReady) return;
        this.initShader();
        this.setAndSaveShader(this._shader);
        this.checkResolution();
    }

    public deleteShader() {
        this._shader = {};
        this.initShader();
    }

    public initShader() {
        this.initShaderUniform();
        this.initShaderSampler();
    }

    protected initShaderSampler() {
        if (!this.isReady) return;
        //! Not setting the textureNoise can be consider a bug
        //! If needed, check https://forum.babylonjs.com/t/shader-texture-changing-problem/9640/20
        const whiteTexture = this.system.getTexture(WHITE_NOISE);
        this.setTextureNoise(whiteTexture);
        this.setTextureFrequency(1);
        if (this.system.textureMouseTrail) this.setTextureMouseTrail(this.system.textureMouseTrail);
        else this.setTextureMouseTrail(whiteTexture);
    }

    private setAndSaveTextureFrequency(textureFrequency: number) {
        this._shader.textureFrequency = textureFrequency;
        this.setTextureFrequency(textureFrequency);
    }

    private resetFilters() {
        SHADER_FILTER_KEYS.forEach((uniform) => {
            this.setShaderValue(uniform, 0);
        });
    }

    private setAndSaveFilters(filters: IFilters) {
        this._shader.filters = filters;
        this.resetFilters();
        filters.forEach((f) => {
            this.setShaderValue(f.type, f.intensity);
        });
    }

    protected setAndSaveTextureNoiseUrl(textureNoise: string) {
        this._shader.textureNoise = textureNoise;
        this.setTextureNoiseUrl(textureNoise, () => {
            // Otherwise we don't see the change in editor
            this.system.scene.render();
        });
    }

    protected setAndSaveShaderValue(option: string, value: number) {
        this._shader[option] = value;
        this.setShaderValue(option, value);
    }

    protected setAndSaveShaderVector2(option: string, value: Point2) {
        this._shader[option] = value;
        if (value) this.setShaderVector2(option, new Vector2(value.x, value.y));
    }

    protected setAndSaveShaderVector4(option: string, value: Point4) {
        this._shader[option] = value;
        if (value) this.setShaderVector4(option, new Vector4(value.x, value.y, value.z, value.w || 0));
    }

    protected setAndSaveShaderColor1(color: number[]) {
        this._shader.shaderColor1 = color;
        if (!color) color = HSL_WHITE;
        this.setShaderColor('shaderColor1', color);
    }

    protected setAndSaveShaderColor2(color: number[]) {
        if (!color) color = HSL_WHITE;
        this._shader.shaderColor2 = color;
        this.setShaderColor('shaderColor2', color);
    }
}
