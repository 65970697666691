precision highp float;

// Uniform	            Type	Description
// world	            mat4	mesh global transforming state (move + rotate + scale)
// worldView	        mat4	global view part of mesh
// worldViewProjection	mat4	global camera
// view	mat4	        mesh    local view part
// projection	        mat4	local camera

// Attributes
attribute vec2 uv;
attribute vec3 position;
attribute vec3 normal;

uniform mat4 world, view, worldViewProjection;

vec4 getPositionWorld()
{
    return world * vec4(position, 1.0);
}

vec4 getVertexPosition()
{
    return worldViewProjection * vec4(position, 1.0);
}

float getCameraDistance(vec4 vPositionW)
{
    return (view * vPositionW).z;
}

vec3 getNormalWorld()
{
    return (mat3(world) * normal).xyz;

    // FROM BJS
    // mat3 normalWorld = mat3(world);
    // vNormalW = normalize(normalWorld * normalUpdated);

    // FORMER CODE NOT WORKING
    // vec4 t = world * vec4(position, 1.0);
    // return normal - t.xyz;
}