import { getDevice } from '~services/util';
import { SystemBuild, SystemEvent } from './systemBuild';

const checkElementVisible = (element: HTMLElement, margin?: number): boolean => {
    if (element === document.body) return true;
    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    // is canvas on screen depending on scroll
    if (!margin) margin = 0;
    const onScreen = !(rect.bottom <= -margin || rect.top - viewHeight >= margin);
    // is parent display or not
    let parentDisplayed = true;
    if (element.offsetParent) {
        parentDisplayed = !!element.offsetParent;
    }
    const containerVisible = (onScreen && parentDisplayed);
    return containerVisible;
};

export class SystemResponsive extends SystemBuild {
    /**
    * Check size and platform on which the 3D is rendered
    */

    constructor(canvas: HTMLCanvasElement) {
        super(canvas);

        // Call to launch the loop, initialize with and height of canvas
        // plus make a first resize check
        this.setResizeContainerLoop();
        this.checkDevice();
    }

    private sizeCheckInterval = 500;

    // Only way to make sure the scene is always fitted with the container
    // is to have a timer checking for changes
    // window resize does not always work in some specific cases
    private setResizeContainerLoop() {
        this.checkSizeChange();
        setInterval(() => {
            this.checkSizeChange();
        }, this.sizeCheckInterval);
    }

    protected checkSizeChange() {
        const canvasChanged = this.checkCanvasSize();
        const windowChanged = this.checkWindowSize();
        const platformChanged = this.checkPixelRatio();
        if (canvasChanged || windowChanged || platformChanged) this.updateSize();
    }

    /**
     * Width of canvas
     */

    public width = 100;

    /**
     * Height of canvas
     */

    public height = 100;

    private checkCanvasSize(): boolean {
        const { offsetWidth, offsetHeight } = this.canvas;
        if (offsetWidth !== this.width || offsetHeight !== this.height) {
            this.width = offsetWidth;
            this.height = offsetHeight;
            return true;
        }
        return false;
    }

    /**
     * Width of window
     */
    private windowWidth = 100;

    /**
     * Height of window
     */
    private windowHeight = 100;

    private checkWindowSize(): boolean {
        const { innerWidth, innerHeight } = window;
        if (innerWidth !== this.windowWidth || innerHeight !== this.windowHeight) {
            this.windowWidth = innerWidth;
            this.windowHeight = innerHeight;
            return true;
        }
        return false;
    }

    public isOnMobile = false;

    private checkDevice() {
        const device = getDevice();
        if (device === 'Mobile') this.isOnMobile = true;
        else this.isOnMobile = false;
        // On mobile we see the Frames on scroll => Nope this is due to the "save batterie" mode
        // this.setLimitFPS(!this.isOnMobile);
    }

    /**
     * Scale between the scene size and the container size
     */
    private pixelRatio = 1;

    private checkPixelRatio(): boolean {
        if (this.isOnMobile) {
            this.maxScaling = 2;
        } else {
            this.maxScaling = 1;
        }

        const { devicePixelRatio } = window;
        const newPR = Math.min(this.maxScaling, devicePixelRatio);

        // or it will create conflicts
        if (newPR !== this.pixelRatio) {
            this.pixelRatio = newPR;
            return true;
        }
        return false;
    }

    /**
    * Check if element visible by the screen
    */

    protected containerVisible = false;

    protected checkVisible(): boolean {
        const containerVisible = checkElementVisible(this.canvas);
        if (!containerVisible) this.engine.setSize(0, 0);
        else if (containerVisible && !this.containerVisible) {
            this.engine.resize();
            this.scene.render();
        }
        this.containerVisible = containerVisible;
        return this.containerVisible;
    }

    private updateSize() {
        if (this.launched) {
            this.checkVisible();
            //! if container not diplayed, engine.resize can double the canvas size
            //! and make the window explode
            this.engine.resize();
            this.notify(SystemEvent.Resize, 0);
        }
    }
}
