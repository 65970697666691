#include<NoiseTechniques>
precision highp float;

uniform float hardwareScaling;
uniform float softness;
uniform float size;
uniform float trail;
uniform float noiseIntensity;
uniform float noiseSpeed;
uniform vec2 resolution;
uniform vec2 mouse;

uniform sampler2D iChannel0;
uniform sampler2D shapeTexture;

varying vec2 vUV;

// In case needed, glsl shape formulas: https://www.shadertoy.com/view/lsBfRc

void main(void) {
    float trailRatio = pow(trail, 1./3.) / 1.05;
    float sizeRatio = size / 4.;
    float softnessRatio = softness * 1.5;

    // Fix position depending on resolution and hardware scaling
    vec2 hmouse = mouse;
    hmouse.y = -mouse.y + 1.;
    hmouse *= resolution/2.;
    hmouse /= hardwareScaling;

    vec2 mousePosition = (gl_FragCoord.xy-hmouse);
    mousePosition /= resolution.x;
    // hardwareScaling to keep same circle size whatever the scaling
    mousePosition *= hardwareScaling;
    // resRatio to keep same circle size whatever the ratio
    float resRatio = resolution.x / resolution.y;
    mousePosition *= resRatio;
    mousePosition += vec2(sizeRatio/2., sizeRatio/2.);
    mousePosition /= sizeRatio;

    // Add noise
    if (noiseIntensity != 0.) {
        float noiseIntensityRatio = .1 * noiseIntensity;
        float noiseSize = 5.;
        vec3 pos = vec3(mousePosition / noiseSize, time * noiseSpeed * 0.1);
        float sinNoise = sin_noise(pos);
        sinNoise *= noiseIntensityRatio;
        mousePosition.x += sinNoise - noiseIntensityRatio;
        mousePosition.y -= sinNoise - noiseIntensityRatio;
    }
    mousePosition = clamp(mousePosition, 0., 1.);

    float blob = texture2D(shapeTexture, mousePosition).a;
    float stack = texture2D(iChannel0, vUV).r * trailRatio;
    float color = stack + blob;
    color = min(color, 1.);
    gl_FragColor = vec4(vec3(color), 1.0);
}