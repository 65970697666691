import '@babylonjs/core/Materials/standardMaterial';
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import {
    Color4, Vector2, Vector4
} from '@babylonjs/core/Maths/math';
import { Effect } from '@babylonjs/core/Materials/effect';

import { System } from '~src/System/system';
import { ShaderInteraction } from '../Shader/shaderInteraction';
import { getRandomId } from '~services/util';
import { BABYLONJS_UNIFORMS, SHADER_OPTION_UNIFORMS } from '../Shader/shader';

import FogShader from './fog.glsl';
import shapeVertexShader from './shape.vertex.glsl';
import shapeFragmentShader from './shape.fragment.glsl';

Effect.IncludesShadersStore.Fog = FogShader;
Effect.ShadersStore.shapeVertexShader = shapeVertexShader;
Effect.ShadersStore.shapeFragmentShader = shapeFragmentShader;

const SHAPE_CUSTOM_UNIFORMS = [
    'time',
    'fogStart',
    'fogEnd',
    'fogColor',
    'useLight',
    'textureFrequency',
    'lightRotHeight',
    // 'fogColor1',
    // 'fogColor2'
];

const SHADER_SHAPE_UNIFORMS = BABYLONJS_UNIFORMS.concat(SHADER_OPTION_UNIFORMS, SHAPE_CUSTOM_UNIFORMS);
//! textureNoise needed for alpha
export const SHADER_SAMPLERS = ['textureSampler', 'textureNoise'];
let SharedMaterial: ShaderMaterial;

export class SimpleShaderShape extends ShaderInteraction {
    public shaderMaterial: ShaderMaterial;

    public id: string;

    constructor(system: System, html?: HTMLElement) {
        super(system, html);
        this.id = getRandomId();

        this.createMaterial();
        this.isReady = true;
        this.initShader();

        this.setShaderValue('useLight', 1);
        this.setShaderValue('isLayer', 0);
        this.system.addShaderMaterial(this.shaderMaterial);
    }

    private createMaterial() {
        if (!SharedMaterial) {
            SharedMaterial = new ShaderMaterial(
                'shader',
                this.system.scene,
                'shape',
                {
                    needAlphaBlending: true,
                    needAlphaTesting: false, //! False otherwise we see edges on text
                    attributes: ['position', 'normal', 'uv'],
                    uniforms: SHADER_SHAPE_UNIFORMS,
                    // useClipPlane: null,
                    samplers: SHADER_SAMPLERS
                }
            );
            //* Depth Prepass allow better performance but do not work with Card
            // SharedMaterial.needDepthPrePass = true;
            // SharedMaterial.forceDepthWrite = true;
            // SharedMaterial.backFaceCulling = false;
            // SharedMaterial.separateCullingPass = true;
        }
        this.shaderMaterial = SharedMaterial.clone(`${this.id}_material`);
        this.shaderMaterial.freeze();
        //! Fix to make sure texture noise is well setted
        this.shaderMaterial.onEffectCreatedObservable.add((effectMesh) => {
            effectMesh.effect.onBindObservable.add((effect) => {
                if (this.textureSampler) {
                    this.shaderMaterial.setTexture('textureSampler', this.textureSampler);
                }
                if (this.textureNoise) {
                    this.shaderMaterial.setTexture('textureNoise', this.textureNoise);
                }
            });
        });
    }

    protected setTextureMouseTrail(texture: Texture) {
        this.shaderMaterial.setTexture('textureMouseTrail', texture);
    }

    protected setTextureSampler(texture: Texture) {
        this.textureSampler = texture;
        this.shaderMaterial.setTexture('textureSampler', texture);
    }

    protected setTextureNoise(texture: Texture) {
        this.shaderMaterial.setTexture('textureNoise', texture);
    }

    protected setTextureFrequency(frequency: number) {
        //! Value Needed or shader bug
        if (frequency) this.setShaderValue('textureFrequency', frequency);
    }

    protected setShaderValue(option: string, value: number) {
        this.shaderMaterial.setFloat(option, value);
    }

    protected setShaderColor(filter: string, color: number[]) {
        if (!color) return;
        const alpha = (color[3] !== undefined) ? color[3] : 1;
        const hsl = new Color4(color[0], color[1], color[2], alpha);
        this.shaderMaterial.setColor4(filter, hsl);
    }

    protected setShaderVector2(option: string, vector: Vector2) {
        this.shaderMaterial.setVector2(option, vector);
    }

    protected setShaderVector4(option: string, vector: Vector4) {
        this.shaderMaterial.setVector4(option, vector);
    }
}
