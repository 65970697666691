uniform float alpha;
uniform float textureFrequency;
uniform sampler2D textureNoise;

//! smoothstep reverse alpha result on safari so we use the math equivalent
// as explpained here: https://registry.khronos.org/OpenGL-Refpages/gl4/html/smoothstep.xhtml
float smoothstepSafariCompatible(float edge0, float ege1, float x)
{ 
    float t = clamp((x - edge0) / (ege1 - edge0), 0.0, 1.0);
    return t * t * (3.0 - 2.0 * t);
}

float getAlphaFromNoiseTexture() 
{
    if (alpha == 0.) return 1.;
    //* alpha need to scale so that all noise texture goes to 0 and 1
    float gap = 0.07;
    float alphaLarge = (alpha * (1.0 + 2.0 * gap)) - gap;
    // Alpha Effect
    float alphaMask = 0.;
    float smooth_size = .1;
    vec2 noiseUV = vUV;
    // textureSize not working on safari
    ivec2 textureSize2d = textureSize(textureSampler,0);
    float textureX = float(textureSize2d.x);
    float textureY = float(textureSize2d.y);
    noiseUV.x *= textureX/textureY;
    noiseUV *= textureFrequency;

    float alphaNoise = texture2D(textureNoise, noiseUV).r;
    alphaMask = smoothstepSafariCompatible(alphaLarge - smooth_size, alphaLarge + smooth_size, alphaNoise);

    // float alphaNoise = (alpha * 3.) + (perlin_noise(vec3(camUV * 20., 0.)) - 1.);
    // float alphaNoise = alpha * (perlin_noise(vec3(camUV * 20., 0.)) + 2.);
    // alpha = max(min(alpha, 1.), 0.);
    return alphaMask;
}