import { Vector2 } from '@babylonjs/core/Maths/math';

import { DegreeToRadian } from '~src/Node/nodePosition';

export const PARTICLE_BY_LINE = 30;

// PARTICLE_LINES must equal PARTICLE_BY_LINE or texture is distorded
export const PARTICLE_LINES = 100;
export const PARTICLE_LINES_LENGTH = 2;

export type TPath = 'Plane' | 'Circle' | 'Triangle' | 'Square';

export type TPathFunction = (i: number, density: number, scope: number) => Vector2;

export const getDensityByShape = (shape: TPath, density: number): number => {
    if (shape === 'Triangle') {
        return (Math.round(density / 3) * 3) + 1;
    } if (shape === 'Square') {
        return (Math.round(density / 4) * 4) + 1;
    }
    // shape === 'Plane' || shape === 'Circle'
    return density;
};

export const Plane: TPathFunction = (i: number, density: number, scope: number) => {
    const PLANE_LENGTH = 100;
    const HALF_PLANE_LENGTH = PLANE_LENGTH / 2;
    const step = PLANE_LENGTH / density;
    const pos = i * step;
    return new Vector2(pos - HALF_PLANE_LENGTH, -scope);
};

export const Square: TPathFunction = (i: number, density: number, scope: number) => {
    // to make sure the path is closed
    const minusDensity = density - 1;
    if (i === minusDensity) i = 0;
    const pSide = Math.round(minusDensity / 4);
    const iSide = Math.floor(i / pSide);
    const iStep = i % pSide;
    const iPerc = (2 * iStep) / pSide;
    const iPos = iPerc * scope;
    const moveForward = iPos - scope;
    const moveBackward = -iPos + scope;
    if (iSide === 0) { // Bottom
        return new Vector2(moveForward, -scope);
    } if (iSide === 1) { // Right
        return new Vector2(scope, moveForward);
    } if (iSide === 2) { // Top
        return new Vector2(moveBackward, scope);
    } // Left
    return new Vector2(-scope, moveBackward);
};

const TWOPI = 2 * Math.PI;
export const Circle: TPathFunction = (i: number, density: number, scope: number) => {
    // to make sure the path is closed
    const minusDensity = density - 1;
    if (i === minusDensity) i = 0;
    const angle = (TWOPI * i) / minusDensity;
    const x = scope * Math.cos(angle);
    const z = scope * Math.sin(angle);
    return new Vector2(x, z);
};

const SIXTY_DEGREE = 60 * DegreeToRadian;
export const Triangle: TPathFunction = (i: number, density: number, scope: number) => {
    //* Triangle calculation
    const triHypothenuse = scope / Math.cos(SIXTY_DEGREE);
    const triHalfSide = triHypothenuse * Math.sin(SIXTY_DEGREE);
    const triSide = triHalfSide * 2;
    const coef = (scope + triHypothenuse) / triHalfSide;

    // to make sure the path is closed
    const minusDensity = density - 1;
    if (i === minusDensity) i = 0;
    const pSide = Math.round(minusDensity / 3);
    const iSide = Math.floor(i / pSide);

    const iStep = i % pSide;
    const iPerc = iStep / pSide;
    const vec = Vector2.Zero();
    if (iSide === 0) { // Bottom
        vec.x = (iPerc * triSide) - triHalfSide;
        vec.y = -scope;
    } else if (iSide === 1) { // Right
        vec.x = -(iPerc * triHalfSide) + triHalfSide;
        vec.y = -coef * vec.x + triHypothenuse;
    } else { // Left
        vec.x = -(iPerc * triHalfSide);
        vec.y = coef * vec.x + triHypothenuse;
    }
    return vec;
};
