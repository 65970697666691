import { Point3 } from '~src/Node/nodePosition';
import {
    IShader,
    GEOMETRY_KEYS,
    SHADER_COLOR_KEYS,
    SHADER_FILTER_KEYS,
} from '~src/Material/Shader/shader';
import { ShaderInteraction } from '~src/Material/Shader/shaderInteraction';
import { InteractionTimelines } from './interactionTimelines';

export class Interaction extends InteractionTimelines {
    public checkSteps() {
        GEOMETRY_KEYS.forEach((key) => {
            if (this.change[key]) {
                this.steps[1][key] = this.change[key];
                this.checkStepsGeometry(key);
            }
        });
        this.setFilterValues(this.change);
    }

    private checkStepsShader(key: string, value) {
        const step0 = this.steps[0];
        const step1 = this.steps[1];
        step1[key] = value;
        if (SHADER_COLOR_KEYS.includes(key)) {
            if (step1[key]) {
                step0[key] = [...step1[key]];
                // Have lightness and saturation to 1 in order to have white
                step0[key][1] = 1;
                step0[key][2] = 1;
            }
        } else if (SHADER_FILTER_KEYS.includes(key)) {
            if (step1[key]) {
                step0[key] = 0;
            }
        }
    }

    private deleteKeysFromStep(keys: string[]) {
        const step1 = this.steps[1];
        const step0 = this.steps[0];
        keys.forEach((key) => {
            delete step1[key];
            delete step0[key];
        });
    }

    public setFilterValues(shader: IShader) {
        this.deleteKeysFromStep(SHADER_FILTER_KEYS);
        const { filters } = shader;
        this.change.filters = filters;
        if (filters) {
            filters.forEach((filter) => {
                this.checkStepsShader(filter.type, filter.intensity);
            });
        }
    }

    public setColorValues(shader: IShader) {
        this.deleteKeysFromStep(SHADER_COLOR_KEYS);
        this.change.shaderColor1 = shader.shaderColor1;
        if (shader.shaderColor1) {
            this.checkStepsShader('shaderColor1', shader.shaderColor1);
        }
        this.change.shaderColor2 = shader.shaderColor2;
        if (shader.shaderColor2) {
            this.checkStepsShader('shaderColor2', shader.shaderColor2);
        }
    }

    public setGeometryValue(geometry: string, value: Point3) {
        this.change[geometry] = value;
        this.checkSteps();
        this.reset(false);
    }

    private checkStepsGeometry(geometry: string) {
        const step0 = this.steps[0];
        const step1 = this.steps[1];
        if (!step1[geometry]) return;
        if (this.isMoveEvent()) {
            const reverse = {};
            const keys = Object.keys(step1[geometry]);
            keys.forEach((key) => {
                reverse[key] = -step1[geometry][key];
            });
            step0[geometry] = reverse;
        } else {
            if (!step0[geometry]) step0[geometry] = {};
            const keys = Object.keys(step1[geometry]);
            keys.forEach((key) => {
                step0[geometry][key] = 0;
            });
        }
    }

    public reset(timelines = true) {
        this.resetElements();
        // Kill timeliness of current elements
        this.killElementsTimeline();
        this.checkInteractionElements();
        // Create timeliness of new elements
        if (timelines) this.resetTimelines();
    }

    public resetElements() {
        this.elements.forEach((element) => {
            element.resetInteraction();
        });
    }

    public resetTimelines() {
        // Remove all timeline if event has changed
        // So that it gets reset once needed
        this.killElementsTimeline();
        this.elements.forEach((element) => {
            this.resetElementTimeline(element);
        });
    }

    public checkInteractionElementsAndUpdate() {
        const newElements = this.getInteractionElements();
        this.elements.forEach((element) => {
            if (newElements.indexOf(element) === -1) {
                this.killElementTimeline(element);
            }
        });
        newElements.forEach((element) => {
            if (this.elements.indexOf(element) === -1) {
                this.resetElementTimeline(element);
            }
        });
        this.elements = newElements;
    }

    public isScrollElement(): boolean {
        return (this.isScrollEvent() && !this.isCameraInteraction());
    }

    private previewTime = 1;

    public preview() {
        this.isPreviewing = true;
        this.timelines.forEach((timeline) => {
            timeline.preview(this.previewTime, this.isScrollElement());
        });
        setTimeout(() => {
            this.isPreviewing = false;
        }, this.previewTime * 1000 + 500);
    }

    private resetElementTimeline(element: ShaderInteraction) {
        if (this.isMoveEvent()) {
            this.setMoveElementTimelines(element);
        } else if (this.isScrollEvent()) {
            this.setScrollElementTimeline(element);
        } else if (this.isMouseHoverClickEvent()) {
            this.setElementTimeline(element, 'tlMouseHover');
        } else {
            this.setElementTimeline(element, 'tl');
        }
    }
}
