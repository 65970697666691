// uniform float fogDensity;
// uniform sampler2D textureFog;
// uniform float textureFogFrequency;
// uniform vec3 fogColor1;
// uniform vec3 fogColor2;

uniform vec3 fogColor;
uniform float fogStart;
uniform float fogEnd;

varying float vCameraDistance;
varying vec3 vNormalW;
varying vec4 vPositionW;
varying vec2 vUV;

vec3 getFogColor(vec3 color)
{
    float ratio = (fogEnd - vCameraDistance) / (fogEnd - fogStart);
    ratio = clamp(ratio, 0.0, 1.0);
    return mix(fogColor, color, ratio);

    // vec2 cammeraUV = vNormalW.xy;
    // // cammeraUV = cammeraUV * scale - 0.5 * 2.0 * scale;
    // cammeraUV = fract(cammeraUV); // Repeat texture
    // float fogMask = texture2D(textureFog, cammeraUV).r;
    // vec3 fogColor = fogMask * fogColor1 + (1. - fogMask) * fogColor2;

    // float densityRatio = (1. - fogDensity);
    // float start = 15., end = 25. + 50.0 * densityRatio;
    // float offset = fogMask / densityRatio;
    // float dist = (5.*offset + vCameraDistance);
    // float dist = vCameraDistance;
    // float ratio = dist < start ? 1. : 1. - clamp((dist - start)/(end - start), 0., 1.);
    // return ratio * color.rgb + (1. - ratio) * fogColor;
}