import { Vector2 } from '@babylonjs/core/Maths/math';

import { System } from '../System/system';
import { Observable } from '../Tools/observable';
import { Timeline } from '~src/System/systemTimeline';

export enum NakerMouseEvent {
    MOVE,
    FOLLOW,
    CLICK
}

export class MouseCatcher extends Observable<NakerMouseEvent, Vector2> {
    /**
     * Use to animate the catching
     */
    private system: System;

    private animation: Timeline;

    private browser: HTMLElement;

    /**
     * Use to animate the catching
     * @param system System of the 3D scene
     * @param responsive If there is responsive changes, we may have to adapt progress height
     */
    constructor(system: System, browser: HTMLElement) {
        super('MouseCatcher');
        this.browser = browser;
        this.system = system;
        this.animation = new Timeline(system, 'mouse', { ease: 'power4.out' });

        this.browser.addEventListener('mouseleave', () => {
            this.putInCenter();
        });
        // this.browser.addEventListener('mouseenter', () => { this.startMouse(); });
        this.browser.addEventListener('pointermove', (evt) => {
            this.checkMousePosition(evt);
        });

        // When we scroll the mouse position is also changing
        this.browser.addEventListener('scroll', () => {
            this.checkMousePosition(this.lastEvent);
        });

        this.browser.addEventListener('pointerup', (evt) => {
            this.getMousePosition(evt);
            this.notify(NakerMouseEvent.CLICK, this.position.clone());
        });
    }

    lastEvent: Event;

    private checkMousePosition(evt: Event) {
        // Mobile is conflicting with scroll on mobile devices
        if (this.system.isOnMobile) return;
        this.inCenter = false;
        this.lastEvent = evt;
        const mousepos = this.getMousePosition(evt);
        if (mousepos) this.catch(mousepos);
    }

    private getMousePosition(evt: Event): Vector2 {
        if (!evt) {
            return Vector2.Zero();
        }
        return this.getMouseScreenPosition(evt);
    }

    // Whatever the page container, this will work
    // Convenient for browser in editor
    position = Vector2.Zero();

    positionPercentage = new Vector2(0.5, 0.5);

    getMouseScreenPosition(evt): Vector2 {
        if (evt) {
            // x & y position within the element.
            this.position = new Vector2(evt.clientX, evt.clientY);
            const rect = this.system.canvas.getBoundingClientRect();
            this.position.x -= rect.left;
            this.position.y -= rect.top;
            this.positionPercentage.x = this.position.x / rect.width;
            this.positionPercentage.y = this.position.y / rect.height;
        }
        return this.positionPercentage;
    }

    public inCenter = false;

    public putInCenter() {
        this.inCenter = true;
        const rect = this.system.canvas.getBoundingClientRect();
        this.position = new Vector2(rect.width / 2, rect.height / 2);
        this.positionPercentage = new Vector2(0.5, 0.5);
        this.catch(this.positionPercentage);
    }

    /**
    * Speed of the progress used when mousewheel or drag on phone
    */
    private speed = 10;

    /**
     * Catch the progress
     * @param top Top position to be catched
     * @param speed At what speed should we catch the new position
     */
    private moveCatch = Vector2.Zero();

    public catch(mouse: Vector2) {
        this.notify(NakerMouseEvent.MOVE, mouse.clone());
        if (this.hasEventObservers(NakerMouseEvent.FOLLOW)) {
            const start = this.moveCatch.clone();
            const change = mouse.subtract(start);
            const howmany = 5 / this.speed;
            this.animation.simple(howmany, (perc) => {
                const progress = change.multiply(new Vector2(perc, perc));
                this.moveCatch = start.add(progress);
                this.notify(NakerMouseEvent.FOLLOW, this.moveCatch.clone());
            });
        }
    }

    public dispose() {
        this.animation.dispose();
    }
}
