import { Vector3, Color3 } from '@babylonjs/core/Maths/math';
import { IVector3Like } from '@babylonjs/core/Maths/math.like';
import { ShaderMaterial } from '@babylonjs/core/Materials/shaderMaterial';
import { Scene } from '@babylonjs/core/scene';
// import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial';

// import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
// import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader';
// import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';

import remove from 'lodash/remove';
import { SystemLoad } from './systemLoad';

export const FAR = 10000000000;

const FOG_START = 15;
const FOG_END = 25;
const FOG_DENSITY_AP = 60;
export const DEFAULT_LIGHTROTHEIGHT: IVector3Like = { x: 0, y: 0, z: 1 };

export class SystemMaterial extends SystemLoad {
    private shaderMaterials: ShaderMaterial[] = [];

    public addShaderMaterial(shaderMaterial: ShaderMaterial) {
        this.shaderMaterials.push(shaderMaterial);
        this.setFogDensity(this.fogDensity);
        this.setFogColor3(this.fogColor);
        this.setLightRotHeight(this.lightRotHeight);
    }

    public removeShaderMaterial(shaderMaterial: ShaderMaterial) {
        remove(this.shaderMaterials, (sh) => sh === shaderMaterial);
        shaderMaterial.setFloat('fogStart', FAR);
        shaderMaterial.setFloat('fogEnd', FAR + 1);
        shaderMaterial.setVector3('lightRotHeight', Vector3.One());
    }

    private fogDensity = 0;

    public setFogDensity(fogDensity: number) {
        this.fogDensity = fogDensity;
        const FOG_END_WITH_DENSITY = FOG_END + (1 - fogDensity) * FOG_DENSITY_AP;
        if (fogDensity) {
            this.scene.fogEnabled = true;
            // this.scene.fogMode = Scene.FOGMODE_EXP;
            // this.scene.fogDensity = fogDensity;
            this.scene.fogMode = Scene.FOGMODE_LINEAR;
            this.scene.fogStart = FOG_START;
            this.scene.fogEnd = FOG_END_WITH_DENSITY;
        }
        this.shaderMaterials.forEach((sh) => {
            sh.setFloat('fogStart', FOG_START);
            sh.setFloat('fogEnd', FOG_END_WITH_DENSITY);
            // sh.setFloat('fogDensity', fogDensity);
            // const texture = this.getTexture('https://d2w9uul1fh5o24.cloudfront.net/editor/scrollImages/v2/512_webp/noise_143.webp', null, true);
            // sh.setTexture('textureFog', texture);
            // sh.setColor3('fogColor1', new Color3(0, 0.4, 0));
            // sh.setColor3('fogColor2', new Color3(0.5, 0.4, 0));
        });
    }

    private lightRotHeight = DEFAULT_LIGHTROTHEIGHT;

    public setLightRotHeight(lightRotHeight: IVector3Like) {
        this.lightRotHeight = lightRotHeight;
        const adjustedLightRotHeight = new Vector3(
            lightRotHeight.x * 100,
            lightRotHeight.y * 100,
            lightRotHeight.z,
        );
        this.shaderMaterials.forEach((sh) => {
            sh.setVector3('lightRotHeight', adjustedLightRotHeight);
        });

        if (this.directionnalLight) {
            this.directionnalLight.direction.x = -lightRotHeight.x;
            this.directionnalLight.direction.y = -lightRotHeight.y;
        }
    }

    private fogColor = Color3.Black();

    public setFogColor(color: number[]) {
        const fogColor = new Color3(color[0] / 255, color[1] / 255, color[2] / 255);
        this.setFogColor3(fogColor);
    }

    public setFogColor3(fogColor: Color3) {
        this.fogColor = fogColor;
        this.scene.fogColor = fogColor;
        //! Important to have clear color match the background so that shader alpha is correct
        //! And clearColor needs to have alpha to 1 so that project thumbnail is correct
        this.scene.clearColor = fogColor.toColor4(1);
        this.shaderMaterials.forEach((sh) => {
            sh.setColor3('fogColor', fogColor);
        });
    }
}
