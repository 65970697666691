import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { CreateBox } from '@babylonjs/core/Meshes/Builders/boxBuilder';
import { CreatePlane } from '@babylonjs/core/Meshes/Builders/planeBuilder';
import { CreateSphere } from '@babylonjs/core/Meshes/Builders/sphereBuilder';
import { CreateDisc } from '@babylonjs/core/Meshes/Builders/discBuilder';
import { CreateCapsule } from '@babylonjs/core/Meshes/Builders/capsuleBuilder';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreateTorus } from '@babylonjs/core/Meshes/Builders/torusBuilder';
import { CreateGround } from '@babylonjs/core/Meshes/Builders/groundBuilder';
import { CreatePolyhedron } from '@babylonjs/core/Meshes/Builders/polyhedronBuilder';
import { CreateRibbon } from '@babylonjs/core/Meshes/Builders/ribbonBuilder';
// import { CylinderBuilder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
// import { DiscBuilder } from '@babylonjs/core/Meshes/Builders/discBuilder';
import { Vector3 } from '@babylonjs/core/Maths/math';

import { getRandomId } from '~/services/util';
import { SimpleShaderShape } from '../Material/ShapeSimple/shaderShape';

export type TMeshShape =
    'Plane'
    | 'Sphere'
    | 'Cube'
    | 'Disc'
    | 'Triangle'
    | 'Capsule'
    | 'Cone'
    | 'Torus'
    | 'Ground'
    | 'Ribbon'

export type TPolyhedronShape =
    'Octahedron'
    | 'Dodecahedron'
    | 'Icosahedron'
    | 'Rhombicuboctahedron'
    | 'Triangular Prism'
    | 'Pentagonal Prism'
    | 'Hexagonal Prism'
    | 'Square Pyramid'
    | 'Pentagonal Pyramid'

export type TShape = TMeshShape | TPolyhedronShape | 'Card'

export const MESH_SHAPE: TMeshShape[] = [
    'Plane',
    // 'Sphere',
    'Cube',
    'Disc',
    'Triangle',
    // 'Capsule',
    'Cone',
    // 'Torus',
    // 'Ground',
    'Ribbon'
];

const shapeNotCulling: (TShape)[] = [
    'Plane',
    'Disc',
    'Triangle',
];

export const isShapeNeedCulling = (shape: TShape): boolean => {
    const ok = shapeNotCulling.includes(shape);
    return !ok;
};

export const POLYHEDRON_SHAPE: TPolyhedronShape[] = [
    'Octahedron',
    'Icosahedron',
    'Rhombicuboctahedron',
    // 'Dodecahedron',
    'Triangular Prism',
    'Pentagonal Prism',
    // 'Hexagonal Prism',
    // 'Square Pyramid',
    // 'Pentagonal Pyramid'
];

export const ALL_SHAPE: (TShape)[] = MESH_SHAPE.concat(POLYHEDRON_SHAPE);

export class ElementShape extends SimpleShaderShape {
    protected useShaderMaterial = true;

    // Needed for component List Element
    public id = getRandomId();

    protected setMesh(mesh: Mesh) {
        if (this.mesh) this.mesh.dispose();
        this.mesh = mesh;
        this.addChildren(mesh);
    }

    protected addChildren(mesh: Mesh) {
        this.setNode(mesh);
        if (this.shaderMaterial && this.useShaderMaterial) {
            mesh.material = this.shaderMaterial;
        }
    }

    private polyhedron = {
        Tetrahedron: 0,
        Octahedron: 1,
        Dodecahedron: 2,
        Icosahedron: 3,
        Rhombicuboctahedron: 4,
        'Triangular Prism': 5,
        'Pentagonal Prism': 6,
        'Hexagonal Prism': 7,
        'Square Pyramid': 8,
        'Pentagonal Pyramid': 9,
    };

    protected shape: TShape;

    protected getShape(shape: TShape): Mesh {
        this.shape = shape;
        const id = getRandomId();
        let mesh: Mesh;

        if (shape === 'Plane') {
            mesh = CreatePlane(`plane_${id}`, { size: 1 }, this.system.scene);
        } else if (shape === 'Cube') {
            mesh = CreateBox(`cube_${id}`, { size: 1 }, this.system.scene);
        } else if (shape === 'Disc') {
            mesh = CreateDisc(`disc_${id}`, { tessellation: 16 }, this.system.scene);
        } else if (shape === 'Triangle') {
            mesh = CreateDisc(`triangle_${id}`, { tessellation: 3 }, this.system.scene);
            mesh.rotation.z = Math.PI;
        } else if (shape === 'Capsule') {
            mesh = CreateCapsule(`tube_${id}`, {
                radius: 0.25, capSubdivisions: 3, subdivisions: 3, tessellation: 5, height: 1
            }, this.system.scene);
        } else if (shape === 'Cone') {
            mesh = CreateCylinder(`cone_${id}`, { diameterTop: 0, tessellation: 10 }, this.system.scene);
        } else if (shape === 'Torus') {
            mesh = CreateTorus(`torus_${id}`, { tessellation: 5 }, this.system.scene);
        } else if (shape === 'Sphere') {
            mesh = CreateSphere(`sphere_${id}`, { diameter: 1, segments: 16 }, this.system.scene);
            mesh.rotation.z = Math.PI;
        } else if (shape === 'Ground') {
            mesh = CreateGround(`ground_${id}`, { width: 1, height: 1, subdivisions: 2 }, this.system.scene);
            mesh.rotation.x = -Math.PI / 2;
        } else if (this.polyhedron[shape]) {
            mesh = CreatePolyhedron(`poly_${id}`, { type: this.polyhedron[shape], size: 0.5 }, this.system.scene);
            mesh.rotation.x = -Math.PI / 2;
            this.setPolyHedronUV(mesh);
        } else if (shape === 'Ribbon') {
            mesh = CreateRibbon(`ribbon_${id}`, { pathArray: [[]], updatable: true, sideOrientation: Mesh.DOUBLESIDE }, this.system.scene);
            // mesh.forceSharedVertices();
        } else {
            mesh = CreatePlane(`plane_${id}`, { size: 1 }, this.system.scene);
            console.warn(`Bad shape name ${shape}`);
        }
        mesh.convertToUnIndexedMesh();
        //! Can't use that as some Mesh will not be visible with scroll
        // mesh.doNotSyncBoundingInfo = true;
        return mesh;
    }

    //* Calculation inspired from https://playground.babylonjs.com/#I092BE, https://playground.babylonjs.com/#PBLS4Y#693
    private setPolyHedronUV(mesh: Mesh) {
        let ver = VertexData.ExtractFromMesh(mesh)
        let positions = ver.positions || [];
        let indices = ver.indices;

        let min = 0, max = 0;
        for (var j = 0; j < positions.length / 3; j++) {
            if (positions[j] > max) max = positions[j]
            if (positions[j] < min) min = positions[j]
        }
        let gap = max - min;
        // console.log(min, max, gap)

        var uvs: number[] = [];
        for (var p = 0; p < positions.length / 3; p++) {
            uvs.push((positions[3 * p] - (min)) / gap, (positions[3 * p + 2] - (min)) / gap);
        }

        //Empty array to contain calculated values or normals added
        var normals = [];

        //Calculations of normals added
        VertexData.ComputeNormals(positions, indices, normals);

        var vertexData = new VertexData();

        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals; //Assignment of normal to vertexData added
        vertexData.uvs = uvs;

        vertexData.applyToMesh(mesh);
    }

    protected updateRibbon(path: Vector3[][]) {
        this.shape = 'Ribbon';
        const ribbonOption = {
            pathArray: path,
            sideOrientation: Mesh.BACKSIDE,
            //* closePath makes ribon move
            // closePath: true
        };

        if (!this.mesh) {
            const id = getRandomId();
            const mesh = CreateRibbon(`ribbon_${id}`, {
                ...ribbonOption,
                updatable: true,
            }, this.system.scene);
            this.setMesh(mesh);
            mesh.isPickable = false;
        } else {
            CreateRibbon(this.mesh.name, {
                ...ribbonOption,
                instance: this.mesh,
            }, this.system.scene);
        }
        //* In order to optimize ribbon update (https://doc.babylonjs.com/divingDeeper/mesh/dynamicMeshMorph#more-speed--freezenormals-)
        //! Can't freeze normal or light doesn't work
        // this.mesh.freezeNormals();
        //! Can't use forceVertice or ribbon bug with scroll
        // this.mesh.forceSharedVertices();
    }

    public setShape(shape: TMeshShape): Mesh {
        if (shape === this.shape) return this.mesh;
        const meshShape = this.getShape(shape);
        //* So that images and text stay in front of back no matter what
        // meshShape.renderingGroupId = 1;
        this.setMesh(meshShape);
        meshShape.isPickable = false;
        return meshShape;
    }

    public remove() {
        this._remove();
    }

    protected _remove() {
        // No need to as this.node = this.mesh
        // this.mesh.dispose();
        this.node.dispose();
        this.geometryParent.dispose();
        this.interactionParent.dispose();
        this.shaderMaterial.dispose();
        this.system.scene.render();
        this.system.removeShaderMaterial(this.shaderMaterial);
        if (this.html) this.html.remove();
    }
}
