import gsap from 'gsap';
import { getRandomId, clamp } from '~services/util';
import { SystemAnimation } from './systemAnimation';

export class Timeline extends gsap.core.Timeline {
    private system: SystemAnimation;

    private key: string;

    private ease: string;

    public running = false;

    constructor(system: SystemAnimation, key?: string, parameters?: gsap.TimelineVars) {
        super({ defaults: parameters });
        if (key) this.key = `${key} - ${getRandomId()}`;
        else this.key = getRandomId();

        if (parameters && parameters.ease) this.ease = parameters.ease;
        this.system = system;
        this.onStart = () => {
            this.running = true;
            this.system.addAnimation(this.key);
        };
        this.onComplete = () => {
            this.running = false;
            this.system.removeAnimation(this.key);
        };

        this.tween = this.tweenTo(0, {
            duration: 1,
            onUpdate: () => {
                const easeProgress = this.easeFunction(this.tween.progress());
                if (this.funct) this.funct(easeProgress);
            },
            onComplete: () => {
                if (this.functEnd) this.functEnd();
                this.stop();
            }
        });
    }

    public dispose() {
        this.pause();
        this.kill();
        gsap.killTweensOf(this);
    }

    private tween: gsap.core.Tween;

    private easeFunction = gsap.parseEase('power0.in');

    private funct: (perc: number) => void;

    private functEnd: () => void;

    public simple(duration: number, funct?: (perc: number) => void, functEnd?: () => void) {
        this.running = true;
        this.system.addAnimation(this.key);
        //! Important to multiply by duration or it doesn't work right
        //! Use ease here and not on Tween in ShaderInteraction
        const easeString = this.ease || 'power0.in';
        this.easeFunction = gsap.parseEase(easeString);
        this.tween.duration(duration);
        this.funct = funct;
        this.functEnd = functEnd;
        this.tween.restart(true);
    }

    public stop() {
        this.running = false;
        this.system.removeAnimation(this.key);
        if (this.infiniteFunction) {
            this.system.scene.unregisterBeforeRender(this.infiniteFunction);
            this.infiniteFunction = null;
        }
    }

    private infiniteFunction: () => void;

    public infinite(funct?: () => void) {
        this.running = true;
        this.system.addAnimation(this.key);
        if (funct) {
            this.infiniteFunction = funct;
            this.system.scene.registerBeforeRender(this.infiniteFunction);
        }
    }

    public gotoProgress(progress: number) {
        const duration = this.duration();
        this.gotoProgressDuration(progress, duration);
    }

    private lastPreview;

    private previewTimeout;

    public preview(time: number, isScrollElement: boolean) {
        this.progress(0);
        this.lastPreview = new Date().getTime();
        if (isScrollElement) {
            this.gotoProgressDuration(1, time);
        } else {
            const halfTime = time / 2;
            this.gotoProgressDuration(1, halfTime);
            const timeout = halfTime * 1000 + 100;
            clearTimeout(this.previewTimeout);
            this.previewTimeout = setTimeout(() => {
                const time = new Date().getTime();
                if (time - this.lastPreview > 580) {
                    this.gotoProgressDuration(0, halfTime);
                }
            }, timeout);
        }
    }

    public gotoProgressDuration(progress: number, duration: number) {
        this.running = true;
        this.system.addAnimation(this.key);
        progress = clamp(progress);
        const intDuration = this.duration();
        //! Important to multiply by duration or it doesn't work right
        //! Use ease here and not on Tween in ShaderInteraction
        this.tweenTo(progress * intDuration, {
            duration,
            ease: this.ease,
            onComplete: () => {
                this.stop();
            }
        });
    }
}
