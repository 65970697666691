import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { CreatePolygon, ExtrudePolygon } from '@babylonjs/core/Meshes/Builders/polygonBuilder';
import { ExtrudeShape } from '@babylonjs/core/Meshes/Builders/shapeBuilder';
import { ISize, Vector3, Vector4 } from '@babylonjs/core/Maths/math';
import { VertexBuffer } from '@babylonjs/core/Buffers/buffer';
import earcut from 'earcut';
import find from 'lodash/find';

import { getRandomId } from '~services/util';
import { Scene } from '@babylonjs/core/scene';

export interface IRadiusDepth {
    radius?: number
    depth?: number
}

export interface IExtrudeShape extends ISize, IRadiusDepth { }

interface ISavedRoundedCard extends IExtrudeShape {
    mesh: Mesh
}

const savedRoundedCard: ISavedRoundedCard[] = [];

const optimizeNormals = (mesh: Mesh) => {
    const positions = mesh.getVerticesData(VertexBuffer.PositionKind);
    const normals = mesh.getVerticesData(VertexBuffer.NormalKind);
    const indices = mesh.getIndices();

    if (!positions || !normals || !indices) {
        return;
    }

    const faceNormalSign = 1;

    const vertexNormal = {};
    const nbFaces = (indices.length / 3) || 0;
    for (let index = 0; index < nbFaces; index++) {
        // Compute the face normal
        const v1x = indices[index * 3] * 3;
        const v1y = v1x + 1;
        const v1z = v1x + 2;
        const v2x = indices[index * 3 + 1] * 3;
        const v2y = v2x + 1;
        const v2z = v2x + 2;
        const v3x = indices[index * 3 + 2] * 3;
        const v3y = v3x + 1;
        const v3z = v3x + 2;

        const p1p2x = positions[v1x] - positions[v2x];
        const p1p2y = positions[v1y] - positions[v2y];
        const p1p2z = positions[v1z] - positions[v2z];

        const p3p2x = positions[v3x] - positions[v2x];
        const p3p2y = positions[v3y] - positions[v2y];
        const p3p2z = positions[v3z] - positions[v2z];

        // compute the face normal with the cross product
        let faceNormalX = faceNormalSign * (p1p2y * p3p2z - p1p2z * p3p2y);
        let faceNormalY = faceNormalSign * (p1p2z * p3p2x - p1p2x * p3p2z);
        let faceNormalZ = faceNormalSign * (p1p2x * p3p2y - p1p2y * p3p2x);

        let length = Math.sqrt(
            faceNormalX * faceNormalX
            + faceNormalY * faceNormalY
            + faceNormalZ * faceNormalZ
        );
        length = length === 0 ? 1.0 : length;

        faceNormalX /= length;
        faceNormalY /= length;
        faceNormalZ /= length;

        const hash = `${faceNormalX.toFixed(5)}_${faceNormalY.toFixed(5)}_${faceNormalZ.toFixed(5)}`;
        // const hash = faceNormalX + "_" + faceNormalY + "_" + faceNormalZ;

        for (let i = 0; i < 3; ++i) {
            const vidx = indices[index * 3 + i];
            let vn = vertexNormal[vidx];
            if (!vn) {
                vn = {
                    normal: [0, 0, 0],
                    mapNormals: new Set(),
                };
                vertexNormal[vidx] = vn;
            }
            if (!vn.mapNormals.has(hash)) {
                vn.mapNormals.add(hash);
                vn.normal[0] += faceNormalX;
                vn.normal[1] += faceNormalY;
                vn.normal[2] += faceNormalZ;
            }
        }
    }

    const normal = new Vector3();
    const vertexKeys = Object.keys(vertexNormal);
    vertexKeys.forEach((vidx: string) => {
        const vn = vertexNormal[vidx];

        normal.copyFromFloats(vn.normal[0], vn.normal[1], vn.normal[2]);
        normal.normalize();

        const vidxN = parseFloat(vidx);
        normals[vidxN * 3 + 0] = normal.x;
        normals[vidxN * 3 + 1] = normal.y;
        normals[vidxN * 3 + 2] = normal.z;
    });

    mesh.setVerticesData(VertexBuffer.NormalKind, normals);
};

const getCardPoints = (extrudeShape: IExtrudeShape): Vector3[] => {
    const { radius, width, height } = extrudeShape;
    const radiusM = Math.min(radius, width / 2, height / 2);
    // Polygon shape in XoZ plane held in array
    const points: Vector3[] = [];
    if (!radiusM) {
        // bottom left
        points.push(new Vector3(-width / 2, 0, -height / 2));
        // bottom right;
        points.push(new Vector3(width / 2, 0, -height / 2));
        // top right
        points.push(new Vector3(width / 2, 0, height / 2));
        // top left;
        points.push(new Vector3(-width / 2, 0, height / 2));
        // back to bottom left
        points.push(new Vector3(-width / 2, 0, -height / 2));
        return points;
    }

    // And the more radiusM the more step are needed
    const deltaAngle = Math.PI / (radiusM * 100);

    // bottom edge
    points.push(new Vector3(-width / 2 + radiusM, 0, -height / 2));
    points.push(new Vector3(width / 2 - radiusM, 0, -height / 2));
    // bottom right corner
    for (let angle = -Math.PI / 2 + deltaAngle; angle < 0; angle += deltaAngle) {
        const x = width / 2 - radiusM + radiusM * Math.cos(angle);
        const z = -height / 2 + radiusM + radiusM * Math.sin(angle);
        points.push(new Vector3(x, 0, z));
    }
    // right edge;
    points.push(new Vector3(width / 2, 0, -height / 2 + radiusM));
    points.push(new Vector3(width / 2, 0, height / 2 - radiusM));
    // top right corner
    for (let angle = deltaAngle; angle < Math.PI / 2; angle += deltaAngle) {
        const x = width / 2 - radiusM + radiusM * Math.cos(angle);
        const z = height / 2 - radiusM + radiusM * Math.sin(angle);
        points.push(new Vector3(x, 0, z));
    }
    // top edge
    points.push(new Vector3(width / 2 - radiusM, 0, height / 2));
    points.push(new Vector3(-width / 2 + radiusM, 0, height / 2));
    // top left corner
    for (let angle = Math.PI / 2 + deltaAngle; angle < Math.PI; angle += deltaAngle) {
        const x = -width / 2 + radiusM + radiusM * Math.cos(angle);
        const z = height / 2 - radiusM + radiusM * Math.sin(angle);
        points.push(new Vector3(x, 0, z));
    }
    // left edge;
    points.push(new Vector3(-width / 2, 0, height / 2 - radiusM));
    points.push(new Vector3(-width / 2, 0, -height / 2 + radiusM));
    // bottom left corner
    for (let angle = Math.PI + deltaAngle; angle < (3 * Math.PI) / 2; angle += deltaAngle) {
        const x = -width / 2 + radiusM + radiusM * Math.cos(angle);
        const z = -height / 2 + radiusM + radiusM * Math.sin(angle);
        points.push(new Vector3(x, 0, z));
    }
    return points;
};

const setUVToEdge = (mesh: Mesh) => {
    const uvs = [];
    for (let i = 0; i < mesh.getTotalVertices(); ++i) {
        uvs.push(0.5, 0.001);
    }
    mesh.setVerticesData('uv', uvs);
};

//! Playground to test change on Card
// https://playground.babylonjs.com/#G0A3MW#53
// Full rounded card example: https://playground.babylonjs.com/#AY7B23

export class NodeExtrude {
    scene: Scene

    constructor(scene: Scene) {
        this.scene = scene;
    }

    private returnVisibleClone(m: Mesh): Mesh {
        const id = getRandomId();
        const newMesh = m.clone(`card_${id}`);
        newMesh.isVisible = true;
        return newMesh;
    }

    private defautDepth = 1;

    private getExtrudePolygonCard(extrudeShape: IExtrudeShape) {
        const cardPoints = getCardPoints(extrudeShape);

        const faceUV = [
            new Vector4(0, 0, 1, 1),
            new Vector4(0, 0, 0, 0),
            new Vector4(0, 0, 0, 0)
        ];
        const mesh = ExtrudePolygon('polygon', {
            shape: cardPoints,
            depth: this.defautDepth,
            faceUV,
        }, this.scene, earcut);
        mesh.rotation.x = -Math.PI / 2;
        return mesh;
    }

    private getExtrudeShapeCard(extrudeShape: IExtrudeShape) {
        const cardPoints = getCardPoints(extrudeShape);
        // for polygon points need to be in XZ plane, so swap

        const extrusion = this.getExtrudeShape(cardPoints);
        setUVToEdge(extrusion);

        const capFront = this.getPolygonFromPoints(cardPoints, Mesh.FRONTSIDE);
        const capBack = this.getPolygonFromPoints(cardPoints, Mesh.BACKSIDE);
        capBack.position.z = this.defautDepth;
        setUVToEdge(capBack);

        const mergedShape = Mesh.MergeMeshes([capFront, extrusion, capBack]);
        optimizeNormals(mergedShape);
        return mergedShape;
    }

    private getPolygonFromPoints(cardPoints: Vector3[], sideOrientation: number): Mesh {
        const polygon = CreatePolygon('polygon', { shape: cardPoints, sideOrientation }, this.scene, earcut);
        polygon.rotation.x = -Math.PI / 2;
        return polygon;
    }

    private getCardFlat(extrudeShape: IExtrudeShape): Mesh {
        const cardPoints = getCardPoints(extrudeShape);
        return this.getPolygonFromPoints(cardPoints, Mesh.FRONTSIDE);
    }

    private getExtrudeShape(cardPoints: Vector3[]): Mesh {
        const depthPath = [
            Vector3.Zero(),
            new Vector3(0, 0, this.defautDepth)
        ];

        const cap = [];
        for (let i = 0; i < cardPoints.length; i++) {
            cap.push(new Vector3(cardPoints[i].x, cardPoints[i].z, 0));
        }
        cap.push(cap[0]);
        return ExtrudeShape('wtf', { shape: cap, path: depthPath }, this.scene);
    }

    public getCardWithRadius(extrudeShape: IExtrudeShape): Mesh {
        const { width, height, radius } = extrudeShape;
        const optionAlreadyExist: ISavedRoundedCard = find(savedRoundedCard, (s) => {
            const isEqualOption = s.width === width && s.height === height && s.radius === radius;
            return isEqualOption;
        });

        if (optionAlreadyExist) {
            return this.returnVisibleClone(optionAlreadyExist.mesh);
        }

        const mesh = this.getExtrudeShapeCard(extrudeShape);
        mesh.isVisible = false;
        const saveoption = { ...extrudeShape, mesh };
        savedRoundedCard.push(saveoption);

        return this.returnVisibleClone(mesh);
    }
}

export const CreateExtrude = (name: string, options: IExtrudeShape, scene: Scene) => {
    const nodeExtrude = new NodeExtrude(scene);
    return nodeExtrude.getCardWithRadius(options)
};