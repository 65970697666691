import { Vector2 } from '@babylonjs/core/Maths/math';
import remove from 'lodash/remove';

import { System } from '../System/system';
import { ShaderCamera } from '~src/Material/Camera/shaderCamera';
import { ElementEvent, ElementsManager } from '~src/Element/elementsManager';
import { MouseCatcher, NakerMouseEvent } from '~src/Catchers/mouseCatcher';
import { InteractionManagerPlay } from './interactionManagerPlay';
import { ShaderInteraction, EVENT } from '~src/Material/Shader/shaderInteraction';
import { InteractionOptions } from './interactionElements';
import { Interaction } from './interaction';
import { ElementShape } from '~src/Element/elementShape';
import { ScrollCatcher } from '~src/Catchers/scrollCatcher';
import { isButtonTag, isImageTag } from '~src/Tools/toolClasses';

const getElementXGap = (element: ElementShape, mouse: Vector2): number => {
    const elementPos = element.geometryParent.absolutePosition;
    const scaleX = element.mesh.scaling.x;
    const gap = (scaleX / 2 + (mouse.x - elementPos.x)) / (scaleX);
    return gap;
};

const getElementYGap = (element: ElementShape, mouse: Vector2): number => {
    const elementPos = element.geometryParent.absolutePosition;
    const scaleY = element.mesh.scaling.y;
    const gap = (scaleY / 2 + (mouse.y - elementPos.y)) / (scaleY);
    return gap;
};

export class InteractionManagerMouse extends InteractionManagerPlay {
    private mouseCatcher: MouseCatcher;

    protected scrollCatcher: ScrollCatcher;

    constructor(
        browser: HTMLElement,
        system: System,
        elementsManager: ElementsManager,
        shaderCamera: ShaderCamera,
        mouseCatcher: MouseCatcher,
        scrollCatcher: ScrollCatcher
    ) {
        super(browser, system, elementsManager, shaderCamera);
        this.mouseCatcher = mouseCatcher;
        this.scrollCatcher = scrollCatcher;

        browser.addEventListener('pointerup', () => {
            if (!this.menuVisible) this.checkClickInteraction(false);
        });

        browser.addEventListener('pointerdown', () => {
            if (!this.menuVisible) this.checkClickInteraction(true);
        });

        this.mouseCatcher.on(NakerMouseEvent.MOVE, () => {
            if (!this.menuVisible) {
                this.checkMoveInteraction();
                const mouseOutside = this.mouseCatcher.inCenter;
                if (!mouseOutside) this.checkHoverInteraction();
            }
        });

        scrollCatcher.onChange(() => {
            this.checkMoveInteraction();
            this.checkHoverInteraction();
        });

        this.elementsManager.on(ElementEvent.Added, (element) => {
            this.checkNewMeshPickable(element);
        });
        this.elementsManager.on(ElementEvent.Loaded, (element) => {
            this.checkNewMeshPickable(element);
        });
    }

    private checkNewMeshPickable(element: ElementShape) {
        if (this.pickableMeshes && element.mesh) element.mesh.isPickable = true;
    }

    public checkPickableMeshes() {
        const elements = this.elementsManager.getElements();
        // If element is a button or an image, it is always pickable
        // Image so that cursor is not changed when button is behind it
        elements.forEach((e) => {
            if (e.mesh) {
                e.mesh.isPickable = isButtonTag(e.html.tagName) || isImageTag(e.html.tagName);
            }
        });
        // If element is in an internation, it is pickable
        const ints = this.getInteractionByEvent(EVENT.HOVER);
        if (ints.length) {
            elements.forEach((e) => {
                ints.forEach((int) => {
                    if (int.elements.includes(e)) {
                        if (e.mesh) e.mesh.isPickable = true;
                    }
                });
            });
        }
    }

    private pickableMeshes = false;

    public setPickableMeshes(pickable: boolean) {
        if (pickable) {
            this.pickableMeshes = true;
            const elements = this.elementsManager.getElements();
            elements.forEach((e) => {
                if (e.mesh) e.mesh.isPickable = true;
            });
        } else {
            this.checkPickableMeshes();
        }

        this.pickableMeshes = pickable;
    }

    private menuVisible = false;

    public setMenuVisible(visible: boolean): void {
        if (visible === this.menuVisible) return;
        this.menuVisible = visible;
        this.checkMenuInteraction(visible);
    }

    private moveInteractions: Interaction[] = [];

    protected addMoveInteraction(interactionOptions: InteractionOptions): Interaction {
        const interaction = this.setInteraction(interactionOptions);
        this.moveInteractions.push(interaction);
        return interaction;
    }

    protected removeMoveInteraction(interaction: Interaction) {
        remove(this.moveInteractions, (i) => i === interaction);
    }

    private getMousePosition(mouseScreen: Vector2): Vector2 {
        const pick = this.system.pickFromPositionStatic(mouseScreen);
        if (pick.hit) {
            return new Vector2(pick.pickedPoint.x, pick.pickedPoint.y);
        }
        return Vector2.Zero();
    }

    private checkMoveInteraction() {
        this.moveInteractions.forEach((interaction) => {
            this.launchMoveInteraction(interaction);
        });
    }

    private launchMoveInteraction(interaction: Interaction) {
        let visibleElements = interaction.getVisibleElements();
        if (interaction.isCameraInteraction()) visibleElements = interaction.elements

        // if (interaction.isCameraInteraction()) {
        const { positionPercentage } = this.mouseCatcher;
        visibleElements.forEach((element) => {
            const t = element.getTimelineByKey('tlMoveX');
            if (!t) interaction.setMoveElementTimelines(element);
            const gapX = positionPercentage.x;
            const gapY = positionPercentage.y;
            let zVec = new Vector2(gapX - 0.5, gapY - 0.5);
            const gapXY = zVec.length() * 2;
            interaction.playXYTimeline(element, { x: gapX, y: gapY, z: gapXY });
        });
        // } else {
        //     const mouse = this.getMousePosition(this.mouseCatcher.position);
        //     visibleElements.forEach((element) => {
        //         const t = element.getTimelineByKey('tlMoveX');
        //         if (!t) interaction.setMoveElementTimelines(element);
        //         const gapX = getElementXGap(element as ElementShape, mouse);
        //         const gapY = getElementYGap(element as ElementShape, mouse);
        //         const gapXY = this.getElementXYGap(element as ElementShape, mouse);
        //         interaction.playXYTimeline(element, { x: gapX, y: gapY, z: gapXY });
        //     });
        // }
    }

    private getElementXYGap(element: ElementShape, mouse: Vector2): number {
        const elementPos = element.geometryParent.absolutePosition;
        const vector2Pos = new Vector2(elementPos.x, elementPos.y);
        const vectorGap = mouse.subtract(vector2Pos);
        return vectorGap.length();
    }

    private checkEventInteraction(event: EVENT, condition: boolean) {
        const interactions = this.getInteractionByEvent(event);
        const element = this.elementsManager.getHoveredElement();
        interactions.forEach((interaction) => {
            if ((element || interaction.isCameraInteraction()) && condition) {
                if (element !== this.hoveredElement) {
                    this.undoMouseInteraction(event);
                    this.doMouseInteraction(element, event);
                }
            } else {
                this.undoMouseInteraction(event);
            }
        });
    }

    private checkCameraEventInteraction(event: EVENT, condition: boolean) {
        const interactions = this.getInteractionByEvent(event);
        interactions.forEach((int) => {
            if (int.isCameraInteraction()) {
                let t = this.shaderCamera.getTimelineByKey('tlMouseHover');
                if (!t) t = int.setElementTimeline(this.shaderCamera, 'tlMouseHover');
                if (condition) int.playTimeline(t, 1);
                else int.playTimeline(t, 0);
            }
        });
    }

    private checkClickInteraction(down: boolean) {
        this.checkEventInteraction(EVENT.CLICK, down);
        this.checkCameraEventInteraction(EVENT.CLICK, down);
    }

    public checkMenuInteraction(down: boolean) {
        //! Can't have Menu interaction on Element yet
        // this.checkEventInteraction(EVENT.MENU, down);
        this.checkCameraEventInteraction(EVENT.MENU, down);
    }

    private hoveredElement: ShaderInteraction;

    private checkHoverInteraction() {
        this.checkEventInteraction(EVENT.HOVER, true);
    }

    private doMouseInteraction(element: ElementShape, event: EVENT) {
        const int = this.getInteractionElementByEvent(element, event);
        if (int) {
            let t = element.getTimelineByKey('tlMouseHover');
            if (!t) t = int.setElementTimeline(element, 'tlMouseHover');
            int.playTimeline(t, 1);
        }
        this.hoveredElement = element;
    }

    private undoMouseInteraction(event: EVENT) {
        if (this.hoveredElement) {
            const int = this.getInteractionElementByEvent(this.hoveredElement, event);
            const t = this.hoveredElement.getTimelineByKey('tlMouseHover');
            if (int && t) int.playTimeline(t, 0);
            this.hoveredElement = null;
        }
    }
}
