import { IVector3Like } from '@babylonjs/core/Maths/math.like';

import { System } from '../System/system';
import { DEFAULT_LIGHTROTHEIGHT } from '~src/System/systemMaterial';
import { ScrollCatcher } from '../Catchers/scrollCatcher';
import { ShaderCamera } from '~src/Material/CameraSimple/shaderCamera';
import { IShader } from '~src/Material/Shader/shader';
import { IMaskMouseTrail, MaskMouseTrail } from '~src/Material/Mask/maskMouseTrail';
import { IPageCameraSection, PageCameraSection } from './pageCameraSection';
import { SectionsManager } from '~src/Section/sectionsManager';

export interface IPageCamera extends IPageCameraSection {
    fog?: number,
    fogColor?: number[],
    motionBlur?: number,
    shader?: IShader,
    mouseTrail?: IMaskMouseTrail,
    lightRotHeight?: IVector3Like,
}

export const MINIMUM_FOG = 0;

const DEFAULTCAMERAOPTIONS: IPageCamera = {
    height: 1,
    fog: MINIMUM_FOG,
    fogColor: [0, 0, 0],
    motionBlur: 0,
    shader: {},
    mouseTrail: {},
    lightRotHeight: DEFAULT_LIGHTROTHEIGHT,
};

export class PageCamera extends PageCameraSection {
    private system: System;

    private scrollCatcher: ScrollCatcher;

    private shaderCamera: ShaderCamera;

    private maskMouseTrail: MaskMouseTrail;

    private sectionsManager: SectionsManager;

    constructor(
        browser: HTMLElement,
        system: System,
        scrollCatcher: ScrollCatcher,
        shaderCamera: ShaderCamera,
        maskMouseTrail: MaskMouseTrail,
        sectionsManager: SectionsManager
    ) {
        super(browser);
        this.system = system;
        this.scrollCatcher = scrollCatcher;
        this.shaderCamera = shaderCamera;
        this.maskMouseTrail = maskMouseTrail;
        this.sectionsManager = sectionsManager;

        this.scrollCatcher.onChange(this.scrollCamera, this);
        this.setVisibleHTML(false);
    }

    public _options: IPageCamera = DEFAULTCAMERAOPTIONS;

    public set options(options: IPageCamera) {
        if (!options) options = DEFAULTCAMERAOPTIONS;
        this.setScrollSnap(options.scrollSnap);
        this.setHeight(options.height);
        this.setFogColor(options.fogColor);
        this.setFog(options.fog);
        this.setLightRotHeight(options.lightRotHeight);
        this.setMotionBlur(options.motionBlur);
        this.shaderCamera.setAndSaveShader(options.shader);
        this.maskMouseTrail.options = options.mouseTrail;
    }

    public get options(): IPageCamera {
        return {
            ...this._options,
            shader: this.shaderCamera.shader,
            mouseTrail: this.maskMouseTrail.options,
        };
    }

    public updateSectionAndScroll() {
        this.scrollCatcher.resetScroll();
        this.scrollCamera();
    }

    private scrollCamera() {
        const { geometryParent } = this.shaderCamera;
        const { scrollGeometry } = this.scrollCatcher;
        geometryParent.rotation = scrollGeometry.rotation;
        geometryParent.position = scrollGeometry.position;
    }

    public setFog(fog = this._options.fog) {
        fog = (fog && fog > MINIMUM_FOG) ? fog : MINIMUM_FOG;
        this._options.fog = fog;
        this.system.setFogDensity(fog);
    }

    public setFogColor(fogColor = this._options.fogColor) {
        this._options.fogColor = fogColor;
        this.system.setFogColor(fogColor);
    }

    public setLightRotHeight(lightRotHeight = this._options.lightRotHeight) {
        this._options.lightRotHeight = lightRotHeight;
        this.system.setLightRotHeight(lightRotHeight);
    }

    private motionBlurLoaded = false;

    public setMotionBlur(motionBlur = this._options.motionBlur) {
        this._options.motionBlur = motionBlur;
        const needToLoad = motionBlur > 0 && !this.motionBlurLoaded;
        const alreadyLoaded = this.motionBlurLoaded;
        if (needToLoad || alreadyLoaded) {
            this.motionBlurLoaded = true;
            import('../Modules/motionBlur').then((mod) => {
                mod.setMotionBlur(motionBlur, this.system.scene, this.system.freeCamera);
            });
        }
    }

    public resetHeightAndPath(doNotUpdatePath?: boolean) {
        this.resetHeight();
        this.scrollCatcher.checkHeight();
        const sections = this.sectionsManager.getSections();
        const { height } = this._options;
        this.scrollCatcher.setPathLength(height, sections.length, doNotUpdatePath);
        if (doNotUpdatePath) this.sectionsManager.checkSectionsGeometry();
    }

    public setHeight(height: number = 1000, doNotUpdatePath?: boolean) {
        this._options.height = height;
        this.resetHeightAndPath(doNotUpdatePath);
    }
}
