import { Engine } from '@babylonjs/core/Engines/engine';
import { Scene } from '@babylonjs/core/scene';
import { Color3 } from '@babylonjs/core/Maths/math';
// import '@babylonjs/core/Materials/Textures/Loaders/ktxTextureLoader';
//! Needed I don't know WHY
import '@babylonjs/core/Materials/Textures/colorGradingTexture';

import { Observable } from '../Tools/observable';

export enum SystemEvent {
    Resize, // SystemResponsive
    MouseTrailTexture // MouseTrail Texture effect
}

export class SystemBuild extends Observable<SystemEvent, number> {
    /**
    * Max Hardware scaling of BabylonJS Engine
    */
    protected maxScaling = 1;

    /**
    * BabylonJS Engine
    */
    public engine: Engine;

    /**
     * BabylonJS Scene
     */
    public scene: Scene;

    /**
     * Canvas used to draw the 3D scene
     */
    public canvas: HTMLCanvasElement;

    /**
     * Creates a new System
     * @param canvas Element where the scene will be drawn
     */
    constructor(canvas: HTMLCanvasElement) {
        super('System');
        // if (!Engine.isSupported()) throw 'WebGL not supported';
        this.canvas = canvas;
        // For now keep false as the last argument of the engine,
        // We don't want the canvas to adapt to screen ratio as it slow down too much the scene
        // preserveDrawingBuffer and stencil needed for screenshot
        // premultipliedAlpha needed for .HDR to .ENV transformation

        const engineOption = {
            limitDeviceRatio: this.maxScaling,
            preserveDrawingBuffer: true,
            stencil: true,
            // premultipliedAlpha: false,
            //* If need to test WebGL 1
            // disableWebGL2Support: true
        };
        this.engine = new Engine(this.canvas, false, engineOption);
        // NOTE to avoid request for manifest files because it can block loading on safari
        this.engine.enableOfflineSupport = false;
        this.engine.setHardwareScalingLevel(0.5);
        // this.engine.setHardwareScalingLevel(0.25);
        this.buildScene();
        this.optimize();

        this.engine.getFontOffset = (font: string): { ascent: number; height: number; descent: number } => {
            const fontMetrics = getFontOffsetCanvas(font);
            return fontMetrics;
        };

        //* In case need to test later
        // let getFontOffset = (font: string): { ascent: number; height: number; descent: number } => {
        //     const text = document.createElement("div");
        //     text.innerHTML = "Hg";
        //     text.setAttribute("style", `font: ${font} !important;`);
        //     // text.setAttribute("style", `font: ${font} !important; line-height: 1 !important;`);

        //     const block = document.createElement("div");
        //     block.style.display = "inline-block";
        //     block.style.width = "1px";
        //     block.style.height = "0px";
        //     block.style.verticalAlign = "bottom";

        //     const div = document.createElement("div");
        //     div.style.whiteSpace = "nowrap";
        //     div.style.position = "fixed";
        //     div.style.top = "0px";
        //     // div.style.display = "grid";
        //     // div.style.display = "flex";
        //     div.appendChild(text);
        //     div.appendChild(block);

        //     document.body.appendChild(div);

        //     const fontMetrics = getFontOffsetCanvas(font)
        //     let ascent = fontMetrics.ascent;
        //     let fontHeight = fontMetrics.height;
        //     let boundHeight = text.getBoundingClientRect().height;
        //     // try {
        //     //     fontHeight = block.getBoundingClientRect().top - text.getBoundingClientRect().top;
        //     //     block.style.verticalAlign = "baseline";
        //     //     ascent = block.getBoundingClientRect().top - text.getBoundingClientRect().top;
        //     // } finally {
        //     //     // document.body.removeChild(div);
        //     // }
        //     document.body.removeChild(div);

        //     let height = 0
        //     if (fontHeight > boundHeight) {
        //         const heightRatio = boundHeight / fontHeight
        //         height = boundHeight
        //         ascent *= heightRatio
        //     } else {
        //         const heightRatio = boundHeight / fontHeight
        //         height = fontHeight
        //         ascent *= heightRatio
        //     }

        //     const descent = height - ascent;
        //     return { ascent, height, descent };
        // }

        interface FontOffset {
            ascent: number
            height: number
            descent: number
        }

        let fontCanvas: OffscreenCanvas | HTMLCanvasElement | null = null;
        let ctx: OffscreenCanvasRenderingContext2D | null = null;
        function getFontOffsetCanvas(font: string): FontOffset {
            if (!fontCanvas) {
                try {
                    fontCanvas = new OffscreenCanvas(100, 100); // will be resized later
                } catch (e) {
                    // The browser does not support WebGL context in OffscreenCanvas, fallback on a regular canvas
                    // The browser either does not support OffscreenCanvas or WebGL context in OffscreenCanvas, fallback on a regular canvas
                    fontCanvas = document.createElement("canvas");
                }

                ctx = fontCanvas.getContext('2d');
                if (!ctx) {
                    fontCanvas = document.createElement('canvas');
                    ctx = fontCanvas.getContext('2d');
                }
            }
            ctx.font = font;

            // ctx.textBaseline = "alphabetic";
            // const descent = ctx.measureText('Hg').actualBoundingBoxDescent;
            // ctx.textBaseline = "bottom";
            // const ascent = ctx.measureText('Hg').actualBoundingBoxAscent;

            ctx.textBaseline = 'baseline';
            const textMetrics = ctx.measureText('Hg');
            const descent = textMetrics.fontBoundingBoxDescent;
            const ascent = textMetrics.fontBoundingBoxAscent;
            return { ascent, height: ascent + descent, descent };
        }
    }

    /**
    * @ignore
    */
    private buildScene() {
        this.scene = new Scene(this.engine);
        this.scene.shadowsEnabled = false;
        this.scene.ambientColor = new Color3(1, 1, 1);
        this.scene.skipPointerMovePicking = true;
        // this.scene.useOrderIndependentTransparency = true;
        // Test to fix shader alpha issue
        // this.scene.useOrderIndependentTransparency = true;
        // Avoid automatic pick and improve performance
        // Can't use it now as it breaks GUI events
        // this.scene.detachControl();
    }

    /**
    * Tell if system currently rendering scene
    */
    protected rendering = false;

    /**
    * Tell if scene needs to be render
    */
    public launched = false;

    /**
     * Allow to launch scene rendering (when everything is loaded for instance)
     */

    public launchRender() {
        //! Waiting for ready creates bug with Ribbon
        this.launched = true;
        this.notify(SystemEvent.Resize, 0);
    }

    private readyCallback: (() => void)[] = []

    public checkSceneReady(callback: () => void) {
        // Make sure scene is ready
        this.readyCallback.push(callback)
        this.scene.executeWhenReady(() => {
            //! scene.render will call executeWhenReady again creating a callbacck loop
            //! setTimeout prevent that
            setTimeout(() => {
                this.scene.render();
                this.readyCallback.forEach((c) => { c() })
                this.readyCallback = []
            }, 100);
        });
    }

    /**
     * Stop scene rendering
     */
    public stopRender() {
        this.launched = false;
        this.pauseRender();
    }

    /**
     * @ignore
     */
    public pauseRender() {
        if (!this.rendering) return;
        // console.log('stop');
        this.rendering = false;
        this.engine.stopRenderLoop();
    }

    /**
     * @ignore
     */
    public startRender() {
        if (this.rendering || !this.launched) return;
        // console.log('start');
        this.rendering = true;
        this.engine.resize();
        this.forceRender();
    }

    public isRendering(): boolean {
        return this.rendering;
    }

    public forceRender() {
        this.engine.stopRenderLoop();
        if (this.limitFPS) {
            this.engine.runRenderLoop(() => {
                if (this.limitSwitch) this.scene.render();
                this.limitSwitch = !this.limitSwitch;
            });
        } else {
            this.engine.runRenderLoop(() => {
                this.scene.render();
            });
        }
    }

    /**
     * Optimize scene to make rendering faster
     * https://doc.babylonjs.com/how_to/optimizing_your_scene#reducing-shaders-overhead
     */

    public optimize() {
        this.scene.autoClear = false; // Color buffer
        this.scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
        this.scene.blockMaterialDirtyMechanism = true;
        // this.scene.clearCachedVertexData();
        // this.scene.cleanCachedTextureBuffer();
        // let activeTest = 0;
        // this.scene.registerBeforeRender(() => {
        //     activeTest++;
        //     this.scene.freezeActiveMeshes();
        //     if (activeTest > 30) {
        //         activeTest = 0;
        //         this.scene.unfreezeActiveMeshes();
        //     }
        // });
        // this.scene.setRenderingAutoClearDepthStencil(
        //     renderingGroupIdx,
        //     autoClear,
        //     depth,
        //     stencil
        // );
    }

    public checkActiveMeshes() {
        this.scene.unfreezeActiveMeshes();
        this.scene.freezeActiveMeshes();
    }

    protected limitFPS = false;

    // Keep first value as true so that render function is called straight away
    // Otherwise you could have a flash
    protected limitSwitch = true;

    public setLimitFPS(limitFPS: boolean) {
        if (limitFPS === this.limitFPS) return;
        this.limitFPS = limitFPS;
    }
}
