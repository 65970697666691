import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import remove from 'lodash/remove';

import {
    IShaderColor,
    GEOMETRY_KEYS,
    SHADER_COLOR_KEYS,
    SHADER_INTERACTION_KEYS,
    SHADER_MASK_KEYS,
} from './shader';
import { Timeline } from '~src/System/systemTimeline';
import { DegreeToRadian, Geometry } from '~src/Node/nodePosition';
import { ShaderSave } from './shaderSave';

const GEOMETRY_WITHAXES_KEYS = [];
GEOMETRY_KEYS.forEach((key) => {
    GEOMETRY_WITHAXES_KEYS.push(`${key}x`);
    GEOMETRY_WITHAXES_KEYS.push(`${key}y`);
    GEOMETRY_WITHAXES_KEYS.push(`${key}z`);
});
const INTERACTION_KEYS = SHADER_INTERACTION_KEYS.concat(GEOMETRY_WITHAXES_KEYS);

export enum EVENT {
    //* MOUSE EVENTS
    CLICK = 0,
    DOUBLECLICK = 1,
    HOVER = 2,
    //* SCROLL EVENTS
    SCROLL = 3, // Follow scroll progress
    SCROLLINTO = 4, // Launch anim when element enter the view
    //* MOUSE MOVE EVENTS
    MOVE = 5,
    //* MENU SHOW HIDE EVENT
    MENU = 6,
}

export interface EventItem {
    name: string,
    enum: EVENT
}
export const EventItems: EventItem[] = [
    { name: EVENT[EVENT.CLICK], enum: EVENT.CLICK },
    { name: EVENT[EVENT.HOVER], enum: EVENT.HOVER },
    { name: EVENT[EVENT.MOVE], enum: EVENT.MOVE },
    { name: EVENT[EVENT.SCROLL], enum: EVENT.SCROLL },
    { name: EVENT[EVENT.MENU], enum: EVENT.MENU },
    // { name: EVENT[EVENT.SCROLLINTO], enum: EVENT.SCROLLINTO },
];

export interface IShaderFilterKeys extends IShaderColor {
    alpha?: number
    chromatic?: number
    blur?: number
    pixel?: number
    ripple?: number
    fisheye?: number
    zoom?: number
    grain?: number
    amplitude?: number
}

export interface InteractionStep extends Geometry, IShaderFilterKeys {
    percentage: number
}

const COLOR_CHANNEL_KEYS = ['h', 's', 'l'];

const killTimeline = (timeline: Timeline) => {
    if (!timeline) return;
    timeline.dispose();
};

const reverseSteps = (steps: InteractionStep[]): InteractionStep[] => {
    const newSteps = cloneDeep(steps);
    newSteps.reverse();
    for (let i = 0; i < steps.length; i++) {
        newSteps[i].percentage = steps[i].percentage;
    }
    return newSteps;
};

const stepFuryToGsap = (furyStep: InteractionStep) => {
    const gsapStep = clone(furyStep);
    if (gsapStep.position !== undefined) {
        const keys = Object.keys(gsapStep.position);
        keys.forEach((axe) => {
            gsapStep[`position${axe}`] = gsapStep.position[axe];
        });
    }
    if (gsapStep.rotation !== undefined) {
        const keys = Object.keys(gsapStep.rotation);
        keys.forEach((axe) => {
            gsapStep[`rotation${axe}`] = gsapStep.rotation[axe];
        });
    }
    if (gsapStep.scaling !== undefined) {
        const keys = Object.keys(gsapStep.scaling);
        keys.forEach((axe) => {
            gsapStep[`scaling${axe}`] = gsapStep.scaling[axe];
        });
    }
    SHADER_COLOR_KEYS.forEach((colorKey) => {
        const hsl = gsapStep[colorKey];
        if (hsl !== undefined) {
            let i = 0;
            COLOR_CHANNEL_KEYS.forEach((key) => {
                gsapStep[`${colorKey}${key}`] = hsl[i];
                i++;
            });
        }
    });
    return gsapStep;
};

const geometryRatio = 1 / 10;

const stepGsapToFury = (gsapStep): InteractionStep => {
    const fStep = clone(gsapStep);
    fStep.position = {};
    //! We reverse position y here
    if (fStep.positionx !== undefined) fStep.position.x = fStep.positionx * geometryRatio;
    if (fStep.positiony !== undefined) fStep.position.y = -fStep.positiony * geometryRatio;
    if (fStep.positionz !== undefined) fStep.position.z = fStep.positionz * geometryRatio;
    fStep.rotation = {};
    //! We reverse rotation axis here
    if (fStep.rotationx !== undefined) fStep.rotation.y = fStep.rotationx * DegreeToRadian;
    if (fStep.rotationy !== undefined) fStep.rotation.x = fStep.rotationy * DegreeToRadian;
    if (fStep.rotationz !== undefined) fStep.rotation.z = fStep.rotationz * DegreeToRadian;
    fStep.scaling = {};
    if (fStep.scalingx !== undefined) fStep.scaling.x = fStep.scalingx * geometryRatio;
    if (fStep.scalingy !== undefined) fStep.scaling.y = fStep.scalingy * geometryRatio;
    if (fStep.scalingz !== undefined) fStep.scaling.z = fStep.scalingz * geometryRatio;
    SHADER_COLOR_KEYS.forEach((colorKey) => {
        const h = fStep[`${colorKey}h`];
        if (h !== undefined) {
            const hslArray = [];
            COLOR_CHANNEL_KEYS.forEach((key) => {
                hslArray.push(fStep[`${colorKey}${key}`]);
            });
            fStep[colorKey] = hslArray;
        }
    });
    return fStep;
};

const getEffectValuesByKeys = (
    effectValues: InteractionStep,
    keys: string[]
): InteractionStep => {
    const newValues = {};
    keys.forEach((key) => {
        if (effectValues[key] !== undefined) newValues[key] = effectValues[key];
    });
    return newValues;
};

export type TimelineKeys = 'tl' | 'tlMoveXY' | 'tlMoveX' | 'tlMoveY' | 'tlScroll' | 'tlMouseHover';

interface ITimelineKey {
    key: TimelineKeys,
    timeline: Timeline
}

export abstract class ShaderInteraction extends ShaderSave {
    public timeline: Timeline;

    public timelines: ITimelineKey[] = [];

    public killTimelines() {
        this.timelines.forEach((t) => {
            killTimeline(t.timeline);
        });
    }

    public killTimelineByKey(timelineKey: TimelineKeys) {
        const timeline = this.getTimelineByKey(timelineKey);
        if (timeline) killTimeline(timeline);
        this.resetInteraction();
        remove(this.timelines, (t) => t.key === timelineKey);
    }

    public getTimelineByKey(timelineKey: TimelineKeys): Timeline {
        const t = find(this.timelines, (t) => t.key === timelineKey);
        return t ? t.timeline : null;
    }

    private setAttributes(attributes) {
        const keys = Object.keys(attributes);
        keys.forEach((key) => {
            this.html.setAttribute(key, attributes[key]);
        });
    }

    private getAttributes(keysAttribute: Object) {
        return this.getAttributesFromArray(Object.keys(keysAttribute));
    }

    private getAttributesFromArray(keysAttribute: string[]) {
        const elementAttributes = {};
        keysAttribute.forEach((key) => {
            const attr = this.html.getAttribute(key);
            if (attr !== 'undefined') {
                elementAttributes[key] = parseFloat(attr);
            }
        });
        return elementAttributes;
    }

    private getAvailableAttributes() {
        const allAttributes = {};
        const textAtt = this.getAttributesFromArray(INTERACTION_KEYS);
        const keys = Object.keys(textAtt);
        keys.forEach((key) => {
            if (
                textAtt[key] !== undefined
                && textAtt[key] !== null
                && !Number.isNaN(textAtt[key])
            ) {
                allAttributes[key] = textAtt[key];
            }
        });
        return allAttributes;
    }

    public checkAttributes() {
        const availableAttributes = this.getAvailableAttributes();
        this.setShaderValues(availableAttributes);
    }

    public removeAttributes() {
        SHADER_INTERACTION_KEYS.forEach((key) => {
            this.html.removeAttribute(key);
        });
        SHADER_MASK_KEYS.forEach((key) => {
            this.html.removeAttribute(key);
        });
        GEOMETRY_KEYS.forEach((key) => {
            this.html.removeAttribute(key);
            this.html.removeAttribute(`${key}x`);
            this.html.removeAttribute(`${key}y`);
            this.html.removeAttribute(`${key}z`);
        });
        this.html.removeAttribute('percentage');
        COLOR_CHANNEL_KEYS.forEach((key) => {
            this.html.removeAttribute(`shaderColor${key}`);
        });
    }

    public setTimeline(
        steps: InteractionStep[],
        ease: string,
        duration: number,
        timelineKey: TimelineKeys,
        reverse?: boolean,
        doNotInit?: boolean,
    ): Timeline {
        steps = steps.sort((a, b) => a.percentage - b.percentage);
        if (reverse) steps = reverseSteps(steps);
        this.killTimelineByKey(timelineKey);
        //! Important to have default with ease otherwise timeline have a bad easing
        const newTimeline = new Timeline(this.system, timelineKey, { ease });
        if (!doNotInit) this.setStep(steps[0]);
        for (let i = 1; i < steps.length; i++) {
            const previusStep = steps[i - 1];
            const nextStep = steps[i];
            const previusGsap = stepFuryToGsap(previusStep);
            const nextGsap = stepFuryToGsap(nextStep);
            const stepDuration = (nextStep.percentage - previusStep.percentage) * duration;
            newTimeline.fromTo(this.html, { attr: previusGsap }, {
                ...{ attr: nextGsap },
                //! Keep power0 otherwise gsap set a default ease value
                //! But for mouse or scroll we want to avoid having a default ease
                ease: 'power0.inOut',
                duration: stepDuration,
                onUpdateParams: [nextGsap],
                onUpdate: (nextGsap) => {
                    this.setGsapValues(nextGsap);
                },
            });
        }
        newTimeline.pause();
        this.timelines.push({ key: timelineKey, timeline: newTimeline });
        return newTimeline;
    }

    public setStep(step: InteractionStep) {
        this.setFuryValues(step);
        //* Needed to set attributes
        const stepWithDefault = { ...step, ...this._shader };
        const gsapStart = stepFuryToGsap(stepWithDefault);
        this.setAttributes(gsapStart);
    }

    public setInteractionValues(effectValues: InteractionStep) {
        if (this.isReady) {
            const geometryValues = getEffectValuesByKeys(effectValues, GEOMETRY_KEYS);
            this.setGeometryInteraction(geometryValues);
            const shaderValues = getEffectValuesByKeys(effectValues, SHADER_INTERACTION_KEYS);
            this.setShaderValues(shaderValues);
            if (shaderValues.shaderColor1) this.setShaderColor('shaderColor1', shaderValues.shaderColor1);
            if (shaderValues.shaderColor2) this.setShaderColor('shaderColor2', shaderValues.shaderColor2);
        }
    }

    public setFuryValues(step: InteractionStep) {
        const stepWithDefault = { ...step, ...this._shader };
        this.setInteractionValues(stepWithDefault);
    }

    private setGsapValues(gsapStep) {
        const gsapStepNew = this.getAttributes(gsapStep);
        const furyStep = stepGsapToFury(gsapStepNew);
        this.setInteractionValues(furyStep);
    }

    public resetInteraction() {
        this.resetShader();
        this.resetGeometryInteraction();
    }
}
