import { EffectWrapper, EffectRenderer } from '@babylonjs/core/Materials/effectRenderer';
import { Vector2 } from '@babylonjs/core/Maths/math';
import { Effect } from '@babylonjs/core/Materials/effect';
import { CustomProceduralTexture } from '@babylonjs/core/Materials/Textures/Procedurals/customProceduralTexture';
import { PostProcess } from '@babylonjs/core/PostProcesses/postProcess';
import { RenderTargetTexture } from '@babylonjs/core/Materials/Textures';
import { Constants } from '@babylonjs/core/Engines/constants';

import { System } from '~src/System/system';
import { getRandomId } from '~services/util';
import { SHADER_RESOLUTION_UNIFORMS } from '../Shader/shader';

export abstract class Mask {
    public texture: RenderTargetTexture;

    protected eWrapper: EffectWrapper;

    protected renderer: EffectRenderer;

    //! Effect must be called only in onApply function as explained here
    // => https://forum.babylonjs.com/t/shadertoy-using-buffera-as-input-to-buffer-a/27681/21
    // protected effect: Effect | CustomProceduralTexture;

    protected system: System;

    protected abstract updateTargetResolution(): void;

    constructor(
        system: System,
        fragmentShader: string,
        samplers: string[],
        uniforms: string[]
    ) {
        this.system = system;

        this.renderer = new EffectRenderer(system.engine);

        // Create the even-numbered frame EffectWrapper
        this.eWrapper = new EffectWrapper({
            engine: system.engine,
            fragmentShader,
            uniformNames: SHADER_RESOLUTION_UNIFORMS.concat(uniforms),
            samplerNames: samplers,
            name: 'effect wrapper'
        });

        this.eWrapper.onApplyObservable.addOnce(() => {
            uniforms.forEach((key) => {
                this.eWrapper.effect.setFloat(key, 0);
            });
        });
    }

    protected refreshResolution() {
        this.eWrapper.onApplyObservable.addOnce(() => {
            const { effect } = this.eWrapper;
            this.checkResolution(effect);
        });
    }

    private checkResolution(effect: Effect | CustomProceduralTexture) {
        const width = this.system.engine.getRenderWidth();
        const height = this.system.engine.getRenderHeight();
        effect.setFloat('hardwareScaling', this.system.engine.getHardwareScalingLevel());
        effect.setVector2('resolution', new Vector2(width, height));
    }

    protected getRenderTargetTexture(): RenderTargetTexture {
        const width = this.system.engine.getRenderWidth();
        const height = this.system.engine.getRenderHeight();

        return new RenderTargetTexture(
            getRandomId(),
            { width, height },
            this.system.scene,
            false,
            false,
            Constants.TEXTURETYPE_FLOAT
        );
    }

    protected updateTargetTextureResolution(texture: RenderTargetTexture) {
        const width = this.system.engine.getRenderWidth();
        const height = this.system.engine.getRenderHeight();
        texture.resize({ width, height });
        this.refreshResolution();
        this.renderer.render(this.eWrapper, this.texture);
    }

    //* Post process for test purpose
    protected usePostProcess() {
        Effect.ShadersStore.displayingFragmentShader = `
            precision highp float;

            uniform sampler2D iChannel0;
            varying vec2 vUV;

            void main(void) {
                vec4 color = texture2D(iChannel0, vUV);
                gl_FragColor = color;
            }
        `;

        const imageEffect = new PostProcess(
            'displaying',
            'displaying',
            [],
            ['iChannel0'],
            1.0,
            this.system.freeCamera
        );

        imageEffect.onApply = (effect) => {
            effect.setTexture('iChannel0', this.texture);
        };

        this.system.setNeedProcess(false);
        this.system.forceRender();
    }
}
