#include<Position>
#include<Fog>

// Options
uniform float amplitude;

void main(void) {
    vPositionW = getPositionWorld();
    vNormalW = getNormalWorld();
    vCameraDistance = getCameraDistance(vPositionW);
    vUV = uv;

    // float mask = getNoiseUVAndMask().z;
    // if (mask == 0.0) {
        gl_Position = getVertexPosition();
    // } else {
    //     // Amplitude will make the geometry move
    //     vec3 pos = position;
    //     pos.y += 1.0 * amplitude * (mask - 0.5);
    //     gl_Position = worldViewProjection * vec4(pos, 1.);
    // }
}