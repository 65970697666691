import { ReactElement } from 'react';

export type TUrlChange = (url: string) => void;

export const EMPTY_IMAGE_LINK = 'https://res.cloudinary.com/dwrvacpsr/image/upload/v1617525478/no-picture_rvexjh.jpg';

export const CLOUDFRONT_STAGING_URL = 'https://d1muem27am86th.cloudfront.net/';
export const CLOUDFRONT_STAGING_LOCAL_URL = 'https://furystaging.s3.eu-west-3.amazonaws.com/';

export interface IChildrenProps {
    children: ReactElement;
}