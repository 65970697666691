import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import {
    Color3, Color4, Vector2, Vector4
} from '@babylonjs/core/Maths/math';
import { PostProcess } from '@babylonjs/core/PostProcesses/postProcess';
import { Effect } from '@babylonjs/core/Materials/effect';

import { SHADER_SAMPLERS, SHADER_UNIFORMS } from '../Shader/shader';
import { System } from '~src/System/system';
import { ShaderInteraction } from '../Shader/shaderInteraction';

import cameraVertexShader from './camera.vertex.glsl';
import cameraFragmentShader from './camera.fragment.glsl';

Effect.ShadersStore.cameraVertexShader = cameraVertexShader;
Effect.ShadersStore.cameraFragmentShader = cameraFragmentShader;

const SHADER_CAMERA_UNIFORMS = SHADER_UNIFORMS.concat(['clearColor']);

export class ShaderCamera extends ShaderInteraction {
    private furyPostProcess: PostProcess;

    private furyEffect: Effect;

    constructor(system: System, browser: HTMLElement, camera?: FreeCamera) {
        super(system, browser);
        const cam = camera || system.freeCamera
        this.furyPostProcess = new PostProcess(
            'fury post process',
            'camera',
            SHADER_CAMERA_UNIFORMS,
            SHADER_SAMPLERS,
            1,
            cam
        );
        this.setNode(cam);
        this.furyEffect = this.furyPostProcess.getEffect();

        this.furyEffect.executeWhenCompiled(() => {
            // this.checkShaderValues();
            this.isReady = true;
            this.initShader();
            this.setAndSaveShader(this._shader);

            //! Texture needs to be updated with onApply or it doesn't always work
            //! Same thing with effectScale and isLayer
            this.furyPostProcess.onApply = (effect) => {
                effect.setTexture('textureNoise', this.textureNoise);
                effect.setTexture('textureMouseTrail', this.mouseTrailTexture);
                effect.setFloat('textureFrequency', this.textureFrequency);
                effect.setColor4('clearColor', this.clearColor, this.clearColor[3]);

                const valueKeys = Object.keys(this.values);
                valueKeys.forEach((key) => {
                    effect.setFloat(key, this.values[key]);
                });
                const colors2Keys = Object.keys(this.colors);
                colors2Keys.forEach((key) => {
                    effect.setColor4(key, this.colors[key], this.colors[key][3]);
                });
                const vectors2Keys = Object.keys(this.vectors2);
                vectors2Keys.forEach((key) => {
                    effect.setVector2(key, this.vectors2[key]);
                });
                const vectors4Keys = Object.keys(this.vectors4);
                vectors4Keys.forEach((key) => {
                    effect.setVector4(key, this.vectors4[key]);
                });
            };
        });
    }

    private clearColor = new Color4(0, 0, 0, 1);

    public setClearColor(color: number[]) {
        const clearColor = new Color3(color[0] / 255, color[1] / 255, color[2] / 255);
        this.clearColor = clearColor.toColor4(1);
    }

    private mouseTrailTexture: Texture;

    public setTextureMouseTrail(texture: Texture) {
        this.mouseTrailTexture = texture;
    }

    // textureNoise setted in onApply
    protected setTextureNoise(texture: Texture) { }

    // Camera Postprocess does not need textureSamepler as this is the scene
    protected setTextureSampler(texture: Texture) { }

    private textureFrequency: number;

    protected setTextureFrequency(frequency: number) {
        this.textureFrequency = frequency;
    }

    private values = {};

    protected setShaderValue(option: string, value: number) {
        this.values[option] = value;
    }

    private colors = {};

    protected setShaderColor(option: string, color: number[]) {
        if (!color) return;
        const alpha = (color[3] !== undefined) ? color[3] : 1;
        const hsl = new Color4(color[0], color[1], color[2], alpha);
        this.colors[option] = hsl;
    }

    private vectors2 = {};

    protected setShaderVector2(option: string, value: Vector2) {
        this.vectors2[option] = value;
    }

    private vectors4 = {};

    protected setShaderVector4(option: string, value: Vector4) {
        this.vectors4[option] = value;
    }
}
