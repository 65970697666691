import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import {
    Vector3, Vector2, Matrix, Quaternion
} from '@babylonjs/core/Maths/math';

import { System } from '../System/system';

export const getElementScreenPositionFromCenter = (
    parent: HTMLElement,
    element: HTMLElement
): Vector2 => {
    const rectParent = parent.getBoundingClientRect();
    const rectElement = element.getBoundingClientRect();
    const x = (rectElement.left + rectElement.width / 2) - rectParent.left;
    const y = (rectElement.top + rectElement.height / 2) - rectParent.top + parent.scrollTop;
    return new Vector2(x, y);
};

export const getElementParentRelativePosition = (
    parent: HTMLElement,
    element: HTMLElement
): Vector2 => {
    const rectParent = parent.getBoundingClientRect();
    const rectElement = element.getBoundingClientRect();
    const x = (rectElement.left + rectElement.width / 2) - (rectParent.left + rectParent.width / 2);
    const y = (rectElement.top + rectElement.height / 2) - (rectParent.top + rectParent.height / 2);
    return new Vector2(x, -y);
};

export const getElementScrollPositionFromCenter = (
    parent: HTMLElement,
    element: HTMLElement
): Vector2 => {
    const fromTop = getElementScreenPositionFromCenter(parent, element);
    const rectBrowser = parent.getBoundingClientRect();
    const x = fromTop.x - rectBrowser.width / 2;
    const y = fromTop.y - rectBrowser.height / 2;
    return new Vector2(x, y);
};

export const getElementScrollPositionFromTop = (
    parent: HTMLElement,
    element: HTMLElement
): number => {
    const rectElement = element.getBoundingClientRect();
    const elementCenter = rectElement.top + rectElement.height / 2;
    const rectParent = parent.getBoundingClientRect();
    const parentCenter = rectParent.top + rectParent.height / 2 - parent.scrollTop;
    return (elementCenter - parentCenter) / (parent.scrollHeight - rectParent.height);
};

export interface Point2 {
    x?: number
    y?: number
}

export interface Point3 extends Point2 {
    z?: number
}

export interface Point4 extends Point3 {
    w?: number
}

export interface GeometryVector {
    position?: Vector3
    rotation?: Vector3
    scaling?: Vector3
}

export interface Geometry {
    position?: Point3
    rotation?: Point3
    scaling?: Point3
}

export const RadianToDegree = 180 / Math.PI;
export const RadianToDegreeVector = new Vector3(RadianToDegree, RadianToDegree, RadianToDegree);

export const DegreeToRadian = Math.PI / 180;
export const DegreeToRadianVector = new Vector3(DegreeToRadian, DegreeToRadian, DegreeToRadian);

export const getGeometryFromTransform = (transform: string, degree?: boolean): Geometry => {
    const matrix = new DOMMatrixReadOnly(transform);
    const arr = matrix.toFloat32Array();
    const test = new Matrix();
    const position = Vector3.Zero();
    const quaternion = Quaternion.Zero();
    const scaling = Vector3.One();
    Matrix.FromFloat32ArrayToRefScaled(arr, 0, 1, test);
    test.decompose(scaling, quaternion, position);

    //* Offset scaling for depth
    scaling.z -= 1;

    const rotation = quaternion.toEulerAngles();
    if (degree) rotation.multiplyInPlace(RadianToDegreeVector);
    return { position, rotation, scaling };
};

export const getTransformFromGeometry = (geo: Geometry): string => {
    const { position, rotation, scaling } = geo;
    let transfom = '';
    if (position) {
        transfom += `translate3d(${position.x || 0}px, ${position.y || 0}px, ${position.z || 0}px)`;
    }
    if (rotation) {
        transfom += ` rotateX(${rotation.x || 0}deg) rotateY(${rotation.y || 0}deg) rotateZ(${rotation.z || 0}deg)`;
    }
    if (scaling) {
        const vectorScaling = Vector3.One();
        ['x', 'y', 'z'].forEach((axe) => {
            if (scaling[axe] !== undefined) vectorScaling[axe] = scaling[axe];
        });
        //* Offset scaling for depth
        vectorScaling.z += 1;
        transfom += ` scale3D(${vectorScaling.x}, ${vectorScaling.y}, ${vectorScaling.z})`;
    }
    return transfom;
};

export class NodePosition {
    protected system: System;

    public geometryParent: TransformNode;

    public html: HTMLElement;

    private htmlParent: HTMLElement;

    constructor(system: System, html?: HTMLElement) {
        this.system = system;
        this.setHtml(html);
        this.geometryParent = new TransformNode('parent', this.system.scene);
    }

    public setHtml(html: HTMLElement) {
        this.html = html;
    }

    public setNodeParent(nodeParent: NodePosition) {
        this.geometryParent.parent = nodeParent.geometryParent;
        this.htmlParent = nodeParent.html;
    }

    public unsetNodeParent() {
        this.geometryParent.parent = null;
        this.htmlParent = null;
    }

    public setPosition(position: Vector3) {
        this.geometryParent.position = position;
    }

    protected setRotationDeg(rotation: Vector3) {
        this.setRotationRadian(rotation.multiply(DegreeToRadianVector));
    }

    public setRotationRadian(rotation: Vector3) {
        this.geometryParent.rotation = rotation;
    }

    private getTransformGeometry(): Geometry {
        const style = window.getComputedStyle(this.html);
        return getGeometryFromTransform(style.transform);
    }

    public getPosition(): Vector2 {
        let nodeRelativePos = Vector2.Zero();
        if (this.htmlParent) {
            nodeRelativePos = getElementParentRelativePosition(this.htmlParent, this.html);
        } else {
            nodeRelativePos = getElementScrollPositionFromCenter(this.system.htmlParent, this.html);
        }
        return nodeRelativePos;
    }

    public getTopPercentage(): number {
        return getElementScrollPositionFromTop(this.system.htmlParent, this.html);
    }

    public checkPosition() {
        const nodeRelativePos = this.getPosition();
        this.setPixelPosition(nodeRelativePos);
    }

    protected setPixelPosition(pixelPosition: Vector2) {
        this.setPixelPositionVector3(new Vector3(pixelPosition.x, pixelPosition.y, 0));
    }

    public setPixelPositionVector3(pixelPosition: Vector3) {
        const { position, rotation } = this.getTransformGeometry();
        const x = pixelPosition.x / this.system.scenePixelRatio;
        const y = pixelPosition.y / this.system.scenePixelRatio;
        const z = (position.z - pixelPosition.z) / this.system.scenePixelRatio;
        this.setPosition(new Vector3(x, y, z));
        this.setRotationRadian(new Vector3(rotation.x, rotation.y, rotation.z));
    }
}
