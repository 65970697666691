import remove from 'lodash/remove';
import { System } from '../System/system';
import { ElementEvent, ElementsManager } from '../Element/elementsManager';
import { InteractionManagerMouse } from './interactionManagerMouse';
import { MouseCatcher } from '~src/Catchers/mouseCatcher';
import { ShaderCamera } from '~src/Material/Camera/shaderCamera';
import { ScrollCatcher } from '../Catchers/scrollCatcher';
import { ShaderInteraction } from '~src/Material/Shader/shaderInteraction';
import { InteractionOptions } from './interactionElements';
import { Interaction } from './interaction';
import { SectionEvent, SectionsManager } from '~src/Section/sectionsManager';

export interface ScrollInteractionOptions extends InteractionOptions {
    scrollTriggers: (ScrollTrigger | gsap.core.Timeline)[]
}

export class InteractionManagerScroll extends InteractionManagerMouse {
    constructor(
        browser: HTMLElement,
        system: System,
        shaderCamera: ShaderCamera,
        elementsManager: ElementsManager,
        sectionManager: SectionsManager,
        mouseCatcher: MouseCatcher,
        scrollCatcher: ScrollCatcher
    ) {
        super(browser, system, elementsManager, shaderCamera, mouseCatcher, scrollCatcher);

        this.elementsManager.on(ElementEvent.Removed, (element) => {
            this.removeElementInScrollInteraction(element);
            this.refreshTriggers()
        });

        this.elementsManager.on(ElementEvent.Loaded, () => {
            this.refreshTriggers();
        });

        sectionManager.on(SectionEvent.Moved, () => {
            this.refreshTriggers();
        });

        this.scrollCatcher.onChange((perc) => {
            this.checkScroll(perc);
        });
    }

    private cameraScrollUp = false;

    public checkScroll(perc: number) {
        this.scrollInteractions.forEach((interaction) => {
            if (interaction.isCameraInteraction()) {
                const timeline = interaction.scrollTriggers[0];
                if (timeline) {
                    if (perc < 0.2 && !this.cameraScrollUp) {
                        this.cameraScrollUp = true;
                        interaction.playTimeline(timeline, 1);
                    } else if (perc > 0.8 && this.cameraScrollUp) {
                        this.cameraScrollUp = false;
                        interaction.playTimeline(timeline, 0);
                    }
                }
            }
        });
    }

    private scrollInteractions: Interaction[] = [];

    protected addScrollInteraction(interactionOptions: ScrollInteractionOptions): Interaction {
        const interaction = this.setInteraction(interactionOptions, () => {
            interaction.elements.forEach((element) => {
                interaction.setScrollElementTimeline(element);
            });
        });
        this.scrollInteractions.push(interaction);
        return interaction;
    }

    protected removeScrollInteraction(interaction: Interaction) {
        const removed = remove(this.scrollInteractions, (i) => i === interaction)[0];
        if (removed) {
            removed.elements.forEach((element) => {
                removed.killElementTimelineByKey(element, 'tlScroll');
            });
        }
    }

    private removeElementInScrollInteraction(element: ShaderInteraction) {
        this.scrollInteractions.forEach((scrollInteraction) => {
            remove(scrollInteraction.elements, (e) => e === element);
            remove(scrollInteraction.timelines, (t) => t.element === element);
        });
    }

    // Timeline is link with scroll and can't be restarted
    // Need to create new timeline for each element: https://greensock.com/forums/topic/24967-restarting-timeline-with-scrolltrigger/
    // Use that to refresh animation after change: https://greensock.com/forums/topic/27872-how-to-reset-scrolltrigger-trigger-position/
    public refreshTriggers() {
        this.scrollInteractions.forEach((ssa) => {
            ssa.scrollTriggers.forEach((st) => {
                // if this is the camera animation, there is no refresh
                if (st.refresh) st.refresh();
            });
        });
    }
}
