import remove from 'lodash/remove';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import {
    InteractionStep, ShaderInteraction, TimelineKeys, EVENT
} from '~src/Material/Shader/shaderInteraction';
import { GEOMETRY_KEYS, SHADER_INTERACTION_KEYS } from '~src/Material/Shader/shader';
import { InteractionElements } from './interactionElements';
import { Timeline } from '~src/System/systemTimeline';
import { Point3 } from '~src/Node/nodePosition';

gsap.registerPlugin(ScrollTrigger);
// Tests to avoid conflict with iphone url bar but not needed
// ScrollTrigger.normalizeScroll(true);
// ScrollTrigger.config({ ignoreMobileResize: true });

const filterStepValues = (
    steps: InteractionStep[],
    keys: string[],
    axe?: string
): InteractionStep[] => {
    let newSteps = []
    steps.forEach((step) => {
        const stepKeys = Object.keys(step);
        let newStep = {}
        stepKeys.forEach((stepKey) => {
            newStep.percentage = step.percentage;
            if (axe) {
                GEOMETRY_KEYS.forEach((key) => {
                    if (step[key] && step[key][axe]) {
                        newStep[key] = {}
                        newStep[key][axe] = step[key][axe]
                    }
                })
            } else if (keys.includes(stepKey)) {
                newStep[stepKey] = step[stepKey];
            }
        });
        newSteps.push(newStep)
    });
    return newSteps;
};

export class InteractionTimelines extends InteractionElements {
    public isPreviewing = false;

    public playTimeline(timeline: Timeline, progress: number) {
        if (!this.isPreviewing) {
            if (timeline) timeline.gotoProgress(progress);
            else console.error('Careful, timeline undefined');
        }
    }

    public playXYTimeline(element: ShaderInteraction, xy: Point3) {
        this.playTimeline(element.getTimelineByKey('tlMoveX'), xy.x);
        this.playTimeline(element.getTimelineByKey('tlMoveY'), xy.y);
        this.playTimeline(element.getTimelineByKey('tlMoveXY'), xy.z);
    }

    public setElementTimeline(
        element: ShaderInteraction,
        timelineKey: TimelineKeys,
    ): Timeline {
        return this.setElementTimelineWithStep(element, this.steps, timelineKey);
    }

    private setElementTimelineWithStep(
        element: ShaderInteraction,
        steps: InteractionStep[],
        timelineKey: TimelineKeys,
        doNotInit?: boolean,
    ): Timeline {
        this.killElementTimelineByKey(element, timelineKey);
        const newTimeline = element.setTimeline(
            steps,
            this.ease,
            this.duration,
            timelineKey,
            this.reverse,
            doNotInit
        );
        this.timelines.push(newTimeline);
        return newTimeline;
    }

    public setStartInteraction() {
        const step = this.steps[0];
        this.setElementsStep(step);
    }

    public killElementsTimeline(): void {
        this.elements.forEach((element) => {
            this.killElementTimeline(element);
        });
    }

    protected killElementTimeline(element: ShaderInteraction) {
        if (this.isMoveEvent()) {
            this.killElementTimelineByKey(element, 'tlMoveX');
            this.killElementTimelineByKey(element, 'tlMoveY');
            this.killElementTimelineByKey(element, 'tlMoveXY');
        } else if (this.isScrollEvent()) {
            this.killElementTimelineByKey(element, 'tlScroll');
        } else if (this.isMouseHoverClickEvent()) {
            this.killElementTimelineByKey(element, 'tlMouseHover');
        } else {
            this.killElementTimelineByKey(element, 'tl');
        }
    }

    protected killElementTimelineByKey(element: ShaderInteraction, timelineKey: TimelineKeys) {
        const elementTimeline = element.getTimelineByKey(timelineKey);
        //! Remove first or it won't work
        remove(this.timelines, (t) => t === elementTimeline);
        element.killTimelineByKey(timelineKey);
    }

    /// MOUSE ///
    public setMoveElementTimelines(element: ShaderInteraction) {
        const stepX = filterStepValues(this.steps, GEOMETRY_KEYS, 'x');
        this.setElementTimelineWithStep(element, stepX, 'tlMoveX', true);

        const stepY = filterStepValues(this.steps, GEOMETRY_KEYS, 'y');
        this.setElementTimelineWithStep(element, stepY, 'tlMoveY', true);

        const stepXY = filterStepValues(this.steps, GEOMETRY_KEYS, 'z');
        // stepXY = filterStepValues(stepXY, SHADER_INTERACTION_KEYS);
        this.setElementTimelineWithStep(element, stepXY, 'tlMoveXY', true);
    }

    /// SCROLL ///
    public scrollTriggers = [];

    public timelines: Timeline[] = [];

    public setScrollElementTimeline(element: ShaderInteraction) {
        if (!this.isCameraInteraction()) {
            const timeline = this.setElementTimeline(element, 'tlScroll');
            // If Scrub, it means it follows scroll
            // const scrub = this.duration;
            const scrub = (this.event === EVENT.SCROLLINTO) ? false : this.duration;

            const scrollTrigger = ScrollTrigger.create({
                animation: timeline,
                scroller: this.browser,
                trigger: element.html,
                // when the top of the trigger hits 100px above the bottom of the viewport/scroller
                start: 'top bottom-=30%',
                // 200px beyond where the start is
                end: '+=50%',
                scrub,
                // toggleActions: "play pause resume reset", // do whatever you want here.
                // markers: true, // Show helpers to understand the parameters
                onRefresh: () => {
                    const { end, start } = scrollTrigger;
                    if (end < 0) {
                        //! If element at the top of page, set interaction to end
                        element.setFuryValues(this.steps[0]);
                    } else if (start > 0) {
                        //! If element later on scroll, set interaction to start
                        element.setFuryValues(this.steps[1]);
                    }
                }
            });
            this.scrollTriggers.push(scrollTrigger);
        } else {
            const timeline = this.setElementTimeline(element, 'tlScroll');
            this.scrollTriggers = [timeline];
        }
    }
}
