import { Vector3 } from '@babylonjs/core/Maths/math';
import find from 'lodash/find';

import { GeometryVector } from '~src/Node/nodePosition';

export type TScrollPathFunction = (unit: number) => GeometryVector;

export const ScrollPathLinear: TScrollPathFunction = (unit: number) => {
    const position = new Vector3(0, 0, unit);
    const rotation = Vector3.Zero();
    return { position, rotation };
};

export const ScrollPathSnake: TScrollPathFunction = (unit: number) => {
    const step = unit / 10;
    const x = Math.cos(step) * 5;
    const eye = -Math.sin(step) / 2;
    const position = new Vector3(x, 0, unit);
    const rotation = new Vector3(0, eye, 0);
    return { position, rotation };
};

export const ScrollPathRollerCoaster: TScrollPathFunction = (unit: number) => {
    const step = unit / 10;
    const y = Math.cos(step) * 5;
    const eye = Math.cos(step - Math.PI / 2) / 2;
    const position = new Vector3(0, y, unit);
    const rotation = new Vector3(eye, 0, 0);
    return { position, rotation };
};

export const ScrollPathSlide: TScrollPathFunction = (unit: number) => {
    const step = unit / 10;
    const cos = Math.cos(step);
    const sin = Math.sin(step);
    const x = cos * 10;
    const z = sin * 10;
    const position = new Vector3(x, -unit, z);
    const rotation = new Vector3(0, -step, 0);
    return { position, rotation };
};

export const ScrollPathSpin: TScrollPathFunction = (unit: number) => {
    const step = unit;
    const position = new Vector3(0, 0, step);
    const rotation = new Vector3(0, 0, step / 10);
    return { position, rotation };
};

export const ScrollPathCircle: TScrollPathFunction = (unit: number) => {
    const step = unit / 10;
    const cos = Math.cos(step);
    const sin = Math.sin(step);
    const x = cos * 10;
    const z = sin * 10;
    //* Put a small unit so that ribbon doesn't "flicker"
    const position = new Vector3(x, -unit / 1000, z);
    const rotation = new Vector3(0, -step, 0);
    return { position, rotation };
};


export enum EScrollPath {
    LINEAR,
    SNAKE,
    ROLLERCOASTER,
    SLIDE,
    SPIN,
    CIRCLE
}

export interface ScrollPathDetail {
    enum: EScrollPath,
    name: string,
    fn: TScrollPathFunction
}

export const SCROLL_PATHS: ScrollPathDetail[] = [
    {
        enum: EScrollPath.LINEAR,
        name: 'Linear',
        fn: ScrollPathLinear
    },
    {
        enum: EScrollPath.SNAKE,
        name: 'Snake',
        fn: ScrollPathSnake
    },
    {
        enum: EScrollPath.ROLLERCOASTER,
        name: 'RollerCoaster',
        fn: ScrollPathRollerCoaster
    },
    {
        enum: EScrollPath.SLIDE,
        name: 'Slide',
        fn: ScrollPathSlide
    },
    {
        enum: EScrollPath.SPIN,
        name: 'Spin',
        fn: ScrollPathSpin
    },
    {
        enum: EScrollPath.CIRCLE,
        name: 'Circle',
        fn: ScrollPathCircle
    }
];

export const getScrollPathFromEnum = (enu: EScrollPath): ScrollPathDetail => {
    const scrollPathDetail = find(SCROLL_PATHS, (s) => s.enum === enu);
    return scrollPathDetail;
};
