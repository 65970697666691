import { ElementShape, TShape } from './elementShape';
import { System } from '~src/System/system';
import { Geometry } from '~src/Node/nodePosition';
import { elementPropertiesToFury } from '~src/Tools/toolStyleTranslation';
import { CreateExtrude, IExtrudeShape, IRadiusDepth } from '~src/Node/nodeExtrude';
import { Mesh } from '@babylonjs/core/Meshes/mesh';

export interface CardOptions extends IRadiusDepth {
    geometry?: Geometry,
}

//! Playground to test change on Card
// https://playground.babylonjs.com/#G0A3MW#53
// Full rounded card example: https://playground.babylonjs.com/#AY7B23

export class ElementCard extends ElementShape {
    constructor(system: System, html?: HTMLElement) {
        super(system, html);
        const tag = html.tagName.toLowerCase();
        if (!html.classList.length) html.classList.add(tag);
        this.checkScaling();
    }

    protected textureQuality = 150;

    protected checkScaling() {
        this.checkScalingCard();
    }

    protected checkScalingCard(): boolean {
        const extrudeShape = this.getExtrudeShapeOptions();
        const sizeChanged = this.hasSizeChanged(extrudeShape);
        const radiusChanged = this.hasRadiusChanged(extrudeShape);
        const depthChanged = this.hasDepthChanged(extrudeShape);
        if (sizeChanged || radiusChanged || depthChanged) this.getExtrudedCard(extrudeShape);
        return sizeChanged;
    }

    private currentWidth = 0;

    private currentHeight = 0;

    private hasSizeChanged(extrudeShape: IExtrudeShape): boolean {
        const { width, height } = extrudeShape;
        const isSameWidth = width === this.currentWidth;
        const isSameHeight = height === this.currentHeight;
        this.currentWidth = width;
        this.currentHeight = height;
        return !(isSameWidth && isSameHeight);
    }

    private currentRadius = 0;

    private hasRadiusChanged(extrudeShape: IExtrudeShape): boolean {
        const { radius } = extrudeShape;
        const isNotSameRadius = radius !== this.currentRadius;
        this.currentRadius = radius;
        return isNotSameRadius;
    }

    private currentDepth = 0;

    private hasDepthChanged(extrudeShape: IExtrudeShape): boolean {
        const { depth } = extrudeShape;
        const isNotSameDepth = depth !== this.currentDepth;
        this.currentDepth = depth || 0;
        return isNotSameDepth;
    }

    public getExtrudeShapeOptions(): IExtrudeShape {
        const style = elementPropertiesToFury(this.html) as CardOptions;
        const { radius, geometry } = style;
        const size = this.getSize();
        const { width, height } = size;
        const borderWidthRatio = radius / (width * this.textureQuality);
        const borderHeightRatio = radius / (height * this.textureQuality);
        const maxSize = Math.max(width, height);
        const fixedRadius = Math.max(borderWidthRatio, borderHeightRatio) * maxSize;
        const depth = (geometry.scaling) ? geometry.scaling.z : 0;
        return { ...size, depth, radius: fixedRadius };
    }

    protected setCardMesh(mesh: Mesh) {
        if (this.mesh) mesh.isPickable = this.mesh.isPickable;
        mesh.needVisibility = true;
        this.setMesh(mesh);
    }

    protected getExtrudedCard(extrudeShape: IExtrudeShape) {
        const { depth } = extrudeShape;
        // const depthChanged = this.hasDepthChanged(extrudeShape);
        // if (depthChanged || sizeChanged || radiusChanged) {
        // FlatCard bug when rotation with Pick, Texture and Autoclear
        // if (!depth) {
        //     const mesh = this.getCardFlat(extrudeShape);
        //     this.setMesh(mesh);
        // } else {
        const mesh = CreateExtrude('card', extrudeShape, this.system.scene);
        this.setCardMesh(mesh)
        // }

        this.mesh.scaling.z = Math.max(depth, 0.001);
    }
}
