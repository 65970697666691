import { Color3 } from '@babylonjs/core/Maths/math';

/** **************** COLOR ***************** */
const hex = (value: number) => Math.floor(value).toString(16);

const hue2rgb = (p: number, q: number, t: number) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
};

export const hslToRgb = (hsl: number[]) => {
    const h = hsl[0]; const s = hsl[1]; const l = hsl[2];
    let r; let g; let
        b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return new Color3(r, g, b);
};

export const getRgbArrayFromString = (rgb: string): number[] => {
    if (!rgb) return [0, 0, 0, 0];
    const rgbString = (rgb.includes('a')) ? rgb.substring(5, rgb.length - 1) : rgb.substring(4, rgb.length - 1);
    const rgbbArrayString = rgbString.replace(/ /g, '').split(',');
    const rgbbArrayNumber = rgbbArrayString.map((value) => parseFloat(value));
    return rgbbArrayNumber;
};

export const getRgbStringFromHslArray = (hslArray: number[]): string => {
    const rgbColor3 = hslToRgb(hslArray);
    let rgbArray = [rgbColor3.r, rgbColor3.g, rgbColor3.b];
    rgbArray = rgbArray.map((v) => v * 255);
    if (hslArray[3] !== undefined) {
        return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, ${hslArray[3]})`;
    }
    return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
};

export const getRgbStringFromArray = (rgbArray: number[]): string => {
    if (rgbArray[3] !== undefined) {
        return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, ${rgbArray[3]})`;
    }
    return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
};

// https://stackoverflow.com/questions/10593337/is-there-any-way-to-create-mongodb-like-id-strings-without-mongodb
export const getRandomId = (): string => hex(Date.now() / 1000)
    + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16));

export const limit = (perc: number, min: number, max: number) => Math.min(Math.max(perc, min), max);

export const clamp = (perc: number) => limit(perc, 0, 1);

export const getObjectValue = (key: string, value: number) => {
    const option = {};
    option[key] = value;
    return option;
};

export const minifyHTML = (s: string): string => s
    .replace(/\>[\r\n ]+\</g, '><')
    .replace(/(<.*?>)|\s+/g, (m, $1) => ($1 || ' '))
    .trim();

export const getElementString = (html: HTMLElement): string => {
    const el = document.createElement('div');
    el.appendChild(html);
    const string = el.innerHTML;
    return minifyHTML(string);
}

export const getQueryParam = (param: string): string => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    return searchParams.get(param);
};

export const getExtensionFromUrl = (url: string): string => {
    const urlWithoutQuery = url.split('?')[0];
    const ext = urlWithoutQuery.substr(urlWithoutQuery.lastIndexOf('.') + 1);
    return ext.toLocaleLowerCase();
};
export const getNameFromUrl = (url: string): string => url.substring(url.lastIndexOf('/') + 1).replace(/\..+$/, '');
export const removeQueryFromUrl = (url: string): string => url.split('?')[0];

// str = str.replace(/ /g, '&nbsp'); // Marche pas avec la 3D
export const textToHtml = (str: string): string => str.replace(/(?:\r\n|\r|\n)/g, '<br>').replaceAll('&', '&amp;');
// str = str.replace('&nbsp', ' ');
export const htmlToText = (str: string): string => str.replace(/<br\s*\/?>/gi, '\r\n').replaceAll('&amp;', '&');

const IMAGE_EXTENSION = ['png', 'jpg', 'jpeg', 'webp', 'svg'];
const VIDEO_EXTENSION = ['mp4', 'webm'];
const MODEL_EXTENSION = ['gltf', 'glb'];

// For local test purpose
const BLOB_IS_IMAGE = false;

export const isImageFile = (url: string) => {
    const ext = getExtensionFromUrl(url);
    return IMAGE_EXTENSION.includes(ext) || url.includes('unsplash.com') || (url.includes('blob:') && BLOB_IS_IMAGE);
};

export const isVideoFile = (url: string) => {
    const ext = getExtensionFromUrl(url);
    return VIDEO_EXTENSION.includes(ext) || ((url.includes('blob:') && !BLOB_IS_IMAGE));
};
export const isModelFile = (url: string) => {
    const ext = getExtensionFromUrl(url);
    return MODEL_EXTENSION.includes(ext);
};

export const isAssetFile = (url: string) => isImageFile(url) || isVideoFile(url) || isModelFile(url);

// a little function to help us with reordering the result
export const reorderArray = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const getDevice = (): 'Mobile' | 'Computer' => {
    const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i);
    const isTablet = navigator.userAgent.toLowerCase().match(/tablet/i);
    const isAndroid = navigator.userAgent.toLowerCase().match(/android/i);
    const isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i);
    const isiPad = navigator.userAgent.toLowerCase().match(/ipad/i);
    if (isiPhone || isiPad) {
        return 'Mobile';
    } if (isMobile || isTablet || isAndroid) {
        return 'Mobile';
    }
    return 'Computer';
};

export const hrefNeedTargetBlank = (href: string): boolean => {
    if (href && !href.includes('http')) {
        try {
            if (!window.document.querySelector(href)) {
                return true;
            }
            return false;
        } catch {
            return true;
        }
    }
    return true;
};

export const getLinkTargetAttrValue = (href: string): string => ((hrefNeedTargetBlank(href)) ? '_blank' : null);

export const getLinkTargetAttrString = (href: string): string => ((hrefNeedTargetBlank(href)) ? 'target="_blank"' : '');

export const upload = (name: string, data: string, type: string) => {
    const exportElement = document.createElement('a');
    let dataUri = '';
    if (type === 'image') {
        dataUri = data;
    } else {
        dataUri = `data:${type};charset=utf-8,${encodeURIComponent(data)}`;
    }
    exportElement.setAttribute('href', dataUri);
    exportElement.setAttribute('download', name);
    exportElement.click();
};

export const getVisibleHeightPx = (html: HTMLElement, viewportHeight: number) => {
    const rect = html.getBoundingClientRect();
    const height = rect.bottom - rect.top;
    const visible = {
        top: rect.top >= 0 && rect.top < viewportHeight,
        bottom: rect.bottom > 0 && rect.bottom < viewportHeight
    };
    let visiblePx = 0;

    if (visible.top && visible.bottom) {
        // Whole section is visible
        visiblePx = height;
    } else if (visible.top) {
        visiblePx = viewportHeight - rect.top;
    } else if (visible.bottom) {
        visiblePx = rect.bottom;
    } else if (height > viewportHeight && rect.top < 0) {
        const absTop = Math.abs(rect.top);

        if (absTop < height) {
            // Part of the element is visible
            visiblePx = height - absTop;
        }
    }

    return visiblePx;
};

export const stringToHtml = (s: string): HTMLElement => {
    const minified = minifyHTML(s);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = minified;
    return tempDiv.childNodes[0];
}

export const htmlToString = (h: HTMLElement): string => {
    const tempDiv = document.createElement('div');
    tempDiv.appendChild(h);
    return tempDiv.innerHTML;
}