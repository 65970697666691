import { setStyle } from 'redom';

import { minifyHTML } from '~/services/util';

export interface IPageCameraSection {
    scrollSnap?: boolean,
    height?: number,
}

export class PageCameraSection {
    private browser: HTMLElement;

    private heightStyleSheet: HTMLStyleElement;

    public _options: IPageCameraSection = {};

    constructor(
        browser: HTMLElement,
    ) {
        this.browser = browser;

        this.heightStyleSheet = document.createElement('style');
        document.head.appendChild(this.heightStyleSheet);
        this.setVisibleHTML(false);
    }

    public setScrollSnap(scrollSnap: boolean = false) {
        this._options.scrollSnap = scrollSnap;
        if (scrollSnap) {
            setStyle(this.browser, { 'scroll-snap-type': 'y mandatory' });
        } else {
            setStyle(this.browser, { 'scroll-snap-type': 'none' });
        }
    }

    private sectionHeight: number;

    private sectionFirstLastHeight: number;

    public resetHeight() {
        let { clientHeight } = this.browser;
        this.sectionFirstLastHeight = clientHeight;
        if (this._options.height) clientHeight *= this._options.height;
        this.sectionHeight = Math.round(clientHeight);
        this.updateSectionStyle();
    }

    private visibleHtml = false;

    public setVisibleHTML(visibleHtml: boolean) {
        this.visibleHtml = visibleHtml;
        //! Do not hide browser too keep scroll bar visible
        // setStyle(this.browser, { opacity: (visibleHtml) ? 1 : 0 });
        this.updateSectionStyle();
    }

    private updateSectionStyle() {
        let { clientHeight } = this.browser;
        let margin = Math.max((clientHeight - this.sectionHeight) / 2, 0);
        const styles = `
            .browser .sections {
                opacity: ${(this.visibleHtml) ? 1 : 0} !important;
            }
            /* So that we don't have the cursor shown in 3D */
            .sections a {
                pointer-events: ${(this.visibleHtml) ? 'visible' : 'none'} 
            }
            .browser section { 
                min-height: ${this.sectionHeight}px;
            }
            .browser section:first-of-type {
                margin-top: ${margin}px;
            }
            .browser section:last-of-type {
                margin-bottom: ${margin}px;
            }
            // .browser img {
            //     max-height: ${this.sectionHeight - 100}px;
            // }
        `;
        this.heightStyleSheet.innerText = minifyHTML(styles);
    }
}
