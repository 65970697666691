import { Vector3, Color3 } from '@babylonjs/core/Maths/math';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { CreateBox } from '@babylonjs/core/Meshes/Builders/boxBuilder';
import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';

import { RESPONSIVE_BREAKPOINT_SM } from '../Tools/toolStyleTranslation';
import { System } from '~src/System/system';
import { getRandomId } from '~services/util';
import { NodePosition } from '~src/Node/nodePosition';
import { ElementShape } from '~src/Element/elementShape';
import { ScrollPath } from '~src/Catchers/scrollPath';

export const findParentHtmlByTag = (html: HTMLElement, tags: string[]): HTMLElement => {
    let notFound = true;
    while (notFound) {
        if (!html) return null;
        if (tags.includes(html.tagName)) {
            notFound = false;
        } else {
            html = html.parentElement;
        }
    }
    return html;
};

export const findParentHtmlByClass = (html: HTMLElement, clas: string): HTMLElement => {
    let notFound = true;
    while (notFound) {
        if (!html) return null;
        if (html.className.includes(clas)) {
            notFound = false;
        } else {
            html = html.parentElement;
        }
    }
    return html;
};

export interface ColOptions {
    alignItems?: string
    gap?: number
}

interface ColSize {
    size: number
    col: number
}

export interface Col extends ColSize {
    id: string
    el: HTMLElement
    node: NodePosition
    elements: ElementShape[]
}

export type RowSize = ColSize[]
export type Row = Col[]

export class Section extends NodePosition {
    elements: ElementShape[] = [];

    rows: Row[] = [];

    name: string;

    id: string;

    scrollPath: ScrollPath;

    constructor(system: System, html: HTMLElement, scrollPath: ScrollPath) {
        super(system, html);
        this.scrollPath = scrollPath;
        this.id = getRandomId();
        this.checkAttributes();
        this.checkColumns();
        // this.addHelper();

        //! HotFix to remove former height/opacity management
        html.style.height = null;
        html.style.opacity = null;
    }

    private addHelper() {
        this.getCubeHelper(this.geometryParent, new Color3(1, 0, 0));
        this.rows.forEach((cols) => {
            cols.forEach((col) => {
                this.getCubeHelper(col.node.geometryParent, new Color3(0, 1, 0));
            });
        });
    }

    private getCubeHelper(parent: TransformNode, color: Color3) {
        const mesh = CreateBox('cube_section', { size: 0.5 }, this.system.scene);
        mesh.parent = parent;
        const mat = new StandardMaterial('');
        mat.emissiveColor = color;
        mesh.material = mat;
    }

    private checkAttributes() {
        this.name = this.html.getAttribute('name');
        // 14/02/2023 Former attribute
        if (!this.name) this.name = this.html.getAttribute('data-title');
    }

    private getHeightOverflow(): number {
        const { height } = this.system;
        // Because section has a fixed height
        const sectionContainer = this.html.childNodes[0];
        const { clientHeight } = sectionContainer;
        return (height) ? clientHeight - height : 0;
    }

    private checkColumns() {
        this.rows = [];
        const rows = this.html.getElementsByClassName('row');
        // rows.forEach((row) => {
        [...rows].forEach((row) => {
            const newRow = [];
            row.childNodes.forEach((el: HTMLElement) => {
                const col = this.addCol(el);
                newRow.push(col);
            });
            this.checkRow(newRow);
            this.rows.push(newRow);
        });
    }

    private checkRow(row: Row) {
        let total = 0;
        const l = row.length;
        row.forEach((column) => {
            total += column.col;
        });
        if (total === 0 || total > 12) {
            row.forEach((column) => {
                column.col = Math.floor(12 / l);
                column.size = this.setElementCol(column.el, column.col);
            });
        }
    }

    public setRowAllColSize(row: Row, rowSize: RowSize): Row {
        let i = 0;
        const colsChanged: Row = [];
        let totalChanged = 0;
        rowSize.forEach((colSize) => {
            const { size } = colSize;
            const col = row[i];
            // There is a size value only if col has been resize by Splitter component
            if (size !== undefined) {
                const newCol = Math.round((size * 12) / 100);
                const colChange = newCol - col.col;
                if (colChange !== 0) {
                    colsChanged.push(col);
                    totalChanged += colChange;
                }
                this.setRowColSize(row, i, newCol);
            }
            i++;
        });
        // Can be not even due to round values so we need to adjust it
        if (totalChanged !== 0) {
            const col = colsChanged[0];
            const newCol = col.col - totalChanged;
            const i = findIndex(row, (c) => c === col);
            this.setRowColSize(row, i, newCol);
        }
        this.checkRow(row);
        return row;
    }

    public setRowColSize(row: Row, i: number, col: number): Row {
        row[i].col = col;
        row[i].size = this.setElementCol(row[i].el, col);
        return row;
    }

    private setElementCol(el: HTMLElement, col: number): number {
        // el.className = `col-md-${col}`;
        //* Floor and -0.2 otherwise sum can be greater than 100 making splitter bug
        return Math.floor((col * 100) / 12) - 0.2;
    }

    private getColWidth(el: HTMLElement): number {
        const { className } = el;
        let col = 0;
        let md = className.split('col-md-')[1];
        if (!md) md = className.split('col-sm-')[1];
        if (!md) md = className.split('col-')[1];
        if (md) {
            const colTest = Number(md.split(' ')[0]);
            if (colTest) col = colTest;
        }
        return col;
    }

    private addCol(el: HTMLElement): Col {
        const col = this.getColWidth(el);
        const size = this.setElementCol(el, col);
        if (!el.id) el.id = getRandomId();
        const { id } = el;
        const node = new NodePosition(this.system, el);
        node.setNodeParent(this);
        const elements = [];
        return {
            el, id, size, col, node, elements
        };
    }

    public checkGeometry(perc: number) {
        const geo = this.scrollPath.getGeometryFromPercentage(perc);
        this.setPosition(geo.position);
        this.setRotationRadian(geo.rotation);
        this.checkColumnsAndElementsGeometry();
    }

    private checkColumnsDirection() {
        const overflow = this.getHeightOverflow();
        // Si trop petit et sur mobile, on aligne les élements dans le sens du scroll
        // const { width } = this.system;
        // if (overflow > 0 && width < RESPONSIVE_BREAKPOINT_SM) {
        if (overflow > 0) {
            this.rows.forEach((cols) => {
                cols.forEach((col) => {
                    col.node.unsetNodeParent();
                    const colPerc = col.node.getTopPercentage();
                    const geo = this.scrollPath.getGeometryFromPercentage(colPerc);
                    const { position, rotation } = geo;
                    col.node.setPosition(position);
                    col.node.setRotationRadian(rotation);
                });
            });
        } else {
            this.rows.forEach((cols) => {
                cols.forEach((col) => {
                    col.node.setNodeParent(this);
                    const pos = col.node.getPosition();
                    const posVec3 = new Vector3(pos.x, pos.y, 0);
                    col.node.setPixelPositionVector3(posVec3);
                });
            });
        }
    }

    public checkColumnsAndElementsGeometry() {
        // this.checkColumnsDirection();
        this.checkElementsGeometry();
        this.system.quickRender();
    }

    public rename(name: string) {
        this.html.setAttribute('name', name);
        this.html.id = name;
        this.name = name;
    }

    public getColumnFromHtml(column: HTMLElement): Col {
        let test: Col;
        this.rows.forEach((cols) => {
            cols.forEach((col) => {
                if (col.el === column) test = col;
            });
        });
        return test;
    }

    public addElement(element: ElementShape) {
        this.elements.push(element);
        const columnParent = findParentHtmlByClass(element.html, 'col-');
        if (columnParent) {
            const col = this.getColumnFromHtml(columnParent);
            col.elements.push(element);
            element.setNodeParent(col.node);
            col.elements = this.filterElements(col.elements);
        } else {
            element.setNodeParent(this);
        }
        this.checkColumnsAndElementsGeometry();
    }

    private filterElements(elements: ElementShape[]): ElementShape[] {
        const elementsTop = [];
        elements.forEach((element) => {
            const elementTop = element.html.getBoundingClientRect().top;
            const sectionTop = this.html.getBoundingClientRect().top;
            const top = elementTop - sectionTop;
            elementsTop.push({ element, top });
        });
        const elementsTopSorted = elementsTop.sort((e1, e2) => e1.top - e2.top);
        const sortedElements = [];
        elementsTopSorted.forEach((elementTop) => {
            sortedElements.push(elementTop.element);
        });
        return sortedElements;
    }

    private checkElementsGeometry() {
        this.elements.forEach((element) => {
            element.checkGeometry();
        });
    }

    public removeElement(element: NodePosition) {
        remove(this.elements, (e) => e === element);
        this.rows.forEach((cols) => {
            cols.forEach((col) => {
                remove(col.elements, (el) => el === element);
            });
        });
        element.unsetNodeParent();
        this.checkColumnsAndElementsGeometry();
    }
}
