import find from 'lodash/find';

import { getRgbArrayFromString, getRgbStringFromArray } from '~/services/util';
import { Geometry, getGeometryFromTransform, getTransformFromGeometry } from '~src/Node/nodePosition';

type TCss = 'number' | 'string' | 'color' | 'geometry';
interface ICssToFury {
    fury: string
    css: string
    type: TCss
}

interface IBreakpoint {
    width: number
    value: number | string
}

export const RESPONSIVE_BREAKPOINT_SM = 576;

export const RESPONSIVE_BREAKPOINTS: IBreakpoint[] = [
    { width: 0, value: '' },
    { width: RESPONSIVE_BREAKPOINT_SM, value: 'sm' },
    { width: 768, value: 'md' },
    { width: 992, value: 'lg' },
    { width: 1200, value: 'xl' },
]

export const FONTS_BREAKPOINTS: IBreakpoint[] = [
    { width: 0, value: 16 },
    { width: 576, value: 18 },
    { width: 768, value: 20 },
    { width: 992, value: 22 },
    { width: 1200, value: 22 },
]

export const furyToCssFont = (rem: number): string => {
    return 'max(16px, min(80px, calc(' + rem.toString() + 'rem + 1.5vw)))';
}

export const cssToFuryFont = (font: string): number => {
    if (font.includes('calc')) {
        return parseFloat(font.split('calc(')[1].split('rem')[0])
    } else {
        return parseFloat(font)
    }
}

export const CSS_TO_FURY: ICssToFury[] = [
    {
        fury: 'border', css: 'border-width', type: 'number'
    },
    {
        fury: 'borderColor', css: 'border-color', type: 'color'
    },
    {
        fury: 'outlineWidth', css: '-webkit-text-stroke-width', type: 'number'
    },
    {
        fury: 'outlineColor', css: '-webkit-text-stroke-color', type: 'color'
    },
    {
        fury: 'radius', css: 'border-radius', type: 'number'
    },
    {
        fury: 'fontStyle', css: 'font-style', type: 'string'
    },
    {
        fury: 'fontWeight', css: 'font-weight', type: 'string'
    },
    {
        fury: 'textAlign', css: 'text-align', type: 'string'
    },
    {
        fury: 'padding', css: 'padding', type: 'number'
    },
    {
        fury: 'fontFamily', css: 'font-family', type: 'string'
    },
    {
        fury: 'fontSize', css: 'font-size', type: 'number'
    },
    {
        fury: 'lineHeight', css: 'line-height', type: 'number'
    },
    {
        fury: 'textDecoration', css: 'text-decoration-line', type: 'string'
    },
    {
        fury: 'color', css: 'color', type: 'color'
    },
    {
        fury: 'backgroundColor', css: 'background-color', type: 'color'
    },
    {
        fury: 'geometry', css: 'transform', type: 'geometry'
    },
];

export const furyToCss = (furyName: string, value) => {
    const cf = find(CSS_TO_FURY, (f) => f.fury === furyName);
    const { type } = cf
    let cssValue = null;
    if (value !== null) {
        if (type === 'number' && value) {
            if (furyName === 'fontSize') cssValue = furyToCssFont(value);
            else cssValue = `${value.toString()}px`;
        }
        if (type === 'string') cssValue = value;
        if (type === 'color') cssValue = getRgbStringFromArray(value);
        if (type === 'geometry') cssValue = getTransformFromGeometry(value);
    }
    return cssValue;
};

export const cssToFury = (cssName: string, value, keepColorString?: boolean): Geometry | number | string | null => {
    const cf = find(CSS_TO_FURY, (f) => f.fury === cssName);
    let furyValue = null;
    const { type, fury } = cf
    if (type === 'geometry') furyValue = (value) ? getGeometryFromTransform(value, true) : {};
    if (type === 'number') {
        if (fury === 'fontSize') furyValue = cssToFuryFont(value);
        else furyValue = parseFloat(value) || 0;
    }
    if (type === 'string') furyValue = value || '';
    if (type === 'color') furyValue = (keepColorString) ? value : getRgbArrayFromString(value);
    return furyValue;
};

export const styleToFury = (style): Object => {
    const furyStyle = {}
    CSS_TO_FURY.forEach((cf) => {
        if (style[cf.css]) {
            furyStyle[cf.fury] = cssToFury(cf.fury, style[cf.css]);
        } else {
            furyStyle[cf.fury] = null;
        }
    });
    return furyStyle;
}

export const elementPropertiesToFury = (el: HTMLElement): Object => {
    let cs = window.getComputedStyle(el, null);
    const furyStyle = {}
    CSS_TO_FURY.forEach((cf) => {
        let css = cs.getPropertyValue(cf.css)
        furyStyle[cf.fury] = cssToFury(cf.fury, css, true);
    });
    return furyStyle;
}
