#include<ColorMapping>
#include<NoiseTechniques>
#include<Fog>

precision highp float;

// Alpha needs textureSampler to work
uniform sampler2D textureSampler;
#include<Alpha>

uniform float visibility;
uniform float useLight;
uniform float isLayer;
uniform vec3 lightRotHeight;

uniform vec2 offset;
uniform vec4 shaderColor1;
uniform vec4 shaderColor2;

// Different light algorythm
// https://www.shadertoy.com/view/Dt33zM
float LIGHT_DIST = 100.;

vec3 getDiffuse(vec3 objectColor)
{
    if (lightRotHeight.z != 1.)  {
        vec3 lightPos = vec3(lightRotHeight.xy, -LIGHT_DIST);
        vec3 lightColor = vec3(1., 1., 1.);
        // ambient
        float ambientStrength = lightRotHeight.z;
        vec3 ambient = ambientStrength * lightColor;
        
        // diffuse 
        vec3 norm = normalize(vNormalW);
        vec3 lightDir = normalize(lightPos - vec3(vPositionW));
        float diff = max(dot(norm, lightDir), 0.0);
        vec3 diffuse = diff * lightColor;
        
        // specular
        // float specularStrength = 0.5;
        
        // vec3 reflectDir = reflect(-lightDir, norm);  
        // float spec = pow(max(dot(cameraNormal, reflectDir), 0.0), 32);
        // vec3 specular = specularStrength * spec * lightColor;  
        // vec3 specular = specularStrength * lightColor;  

        // Point light
        // attenuation = max(0., 1.0 - length(direction) / range);
            
        // return (ambient + diffuse + specular) * objectColor;

        return min(ambient + diffuse, 1.) * objectColor;
    } else {
        return objectColor;
    }
} 

vec3 getColor()
{
    // Offset from center
    vec2 centeredUV = vUV * 2.0 - 1.0;
    vec2 noiseUV = centeredUV + offset;
    vec2 colorUV = noiseUV;
    // Scale from center
    float minFrequency = max(textureFrequency, 0.1);
    float scale = 10.0 * minFrequency;
    colorUV = (colorUV * scale) * 0.5 - 0.5;
    float colorFilter = texture2D(textureSampler, colorUV).r;
    vec3 hsl1 = shaderColor1.xyz;
    vec3 hsl2 = shaderColor2.xyz;
    vec3 rgb1 = hsl2rgb(hsl1);
    vec3 rgb2 = hsl2rgb(hsl2);

    vec3 colorImage1 = colorFilter * rgb1;
    vec3 colorImage2 = (1.0 - colorFilter) * rgb2;
    return colorImage1 + colorImage2;
} 

void main(void) {
    vec4 imageSampler = texture2D(textureSampler, vUV);
    vec3 imageSource = vec3(0.);
    // Color Effect
    if (isLayer == 1.) {
        imageSource = getColor();
    } else {
        imageSource = imageSampler.rgb;
    }

    // LIGHT
    vec3 imageWithDiffuse = imageSource;
    if (useLight == 1.) {
        imageWithDiffuse = getDiffuse(imageSource);
    } 
    //vec3 imageWithDiffuse = getDiffuse(imageSource);

    // FOG
    vec3 imageWithFog = getFogColor(imageWithDiffuse); 

    // ALPHA
    float alphaMask = getAlphaFromNoiseTexture();
    float alpha = imageSampler.a * visibility * alphaMask;

    gl_FragColor = vec4(imageWithFog, alpha);
}