#include<Filter>
#include<Alpha>

uniform vec4 clearColor;
uniform sampler2D textureMouseTrail;

void main(void) {
    vec4 circleMask = texture2D(textureMouseTrail, vUV);
    float trailMouseMask = circleMask.r;
    vec3 filterImage = getFilterImage(trailMouseMask);

    float alphaMask = getAlphaFromNoiseTexture();

    // vec3 color = (1. - alphaMask) * filterImage.xyz + alphaMask * clearColor.xyz;
    vec3 color = alphaMask * filterImage.xyz + (1. - alphaMask) * clearColor.xyz;
    gl_FragColor = vec4(color, 1.);
}