import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { VideoTexture } from '@babylonjs/core/Materials/Textures/videoTexture';

import { SystemEvent } from './systemBuild';
import { isVideoFile } from '~services/util';
import { SystemCamera } from './systemCamera';

export class SystemLoad extends SystemCamera {
    public textureMouseTrail: Texture;

    public setMouseTrailTexture(texture: Texture) {
        this.textureMouseTrail = texture;
        this.notify(SystemEvent.MouseTrailTexture, 0);
    }

    private _renderAfterLoading = false;

    public set renderAfterLoading(v: boolean) {
        this._renderAfterLoading = v;
    }

    public get renderAfterLoading(): boolean {
        return this._renderAfterLoading;
    }

    public getTexture(
        buffer: string | HTMLVideoElement | HTMLImageElement,
        callback?: (t: Texture) => void,
        invertY?: boolean
    ): Texture {
        let texture: Texture;
        invertY = (invertY) || false;
        const url = (typeof buffer === 'string' || buffer instanceof String) ? buffer as string : buffer.src;
        if (isVideoFile(url)) {
            texture = this.getVideoTexture(buffer, callback, invertY);
        } else {
            texture = this.getImageTexture(buffer, callback, invertY);
        }
        texture.hasAlpha = true;
        return texture;
    }

    private getVideoTexture(
        buffer: string | HTMLVideoElement | HTMLImageElement,
        callback?: (t: Texture) => void,
        invertY?: boolean
    ): Texture {
        const url = (typeof buffer === 'string' || buffer instanceof String) ? buffer as string : buffer.src;
        const bufVideo = buffer as HTMLVideoElement;
        if (bufVideo) bufVideo.muted = true;
        //! Keep mipmap set to true or we see lines in shaders
        const texture = new VideoTexture(
            url,
            bufVideo,
            this.scene,
            true,
            !invertY,
            Texture.TRILINEAR_SAMPLINGMODE,
        );
        texture.video.pause();
        texture.video.onplaying = () => {
            texture.video.onplaying = () => { };
            texture.video.play();
            this.textureLoaded(texture, callback);
        };
        return texture;
    }

    private getImageTexture(
        buffer: string | HTMLVideoElement | HTMLImageElement,
        callback?: (t: Texture) => void,
        invertY?: boolean
    ): Texture {
        let texture: Texture;
        const url = (typeof buffer === 'string' || buffer instanceof String) ? buffer as string : buffer.src;
        if (typeof buffer === 'string' || buffer instanceof String) {
            texture = new Texture(
                url,
                this.scene,
                true,
                invertY,
                Texture.TRILINEAR_SAMPLINGMODE,
                () => {
                    this.textureLoaded(texture, callback);
                }
            );
        } else {
            const bufImage = buffer as HTMLImageElement;
            texture = new Texture(
                url,
                this.scene,
                true,
                invertY,
                Texture.TRILINEAR_SAMPLINGMODE,
                () => {
                    this.textureLoaded(texture, callback);
                },
                null,
                bufImage,
                true
            );
        }
        return texture;
    }

    private textureLoaded(texture: Texture, callback?: (t: Texture) => void) {
        //! Callback needed when the texture is changed in editor
        if (this._renderAfterLoading && this.launched) {
            this.scene.render();
            this.quickRender(200);
        }
        if (callback) callback(texture);
    }

    // Light for model
    protected directionnalLight;

    public getModel(url: string, callback: (t: Mesh) => void) {
        import('../Modules/loadGltf').then((mod) => {
            this.directionnalLight = mod.addLight(this.scene);
            mod.getModel(url, this.scene, callback);
        });
    }
}
