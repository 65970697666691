import { minifyHTML } from '~services/util';

export interface LinkOptions {
    text: string
    href: string
}

export const getChildrenLinks = (children: HTMLCollection, textKey: string): LinkOptions[] => {
    const childrenArr = [...children];
    const links = [];
    childrenArr.forEach((child) => {
        //! getAttribute('href') to avoid having entire url
        const text = child[textKey] || '';
        const href = child.getAttribute('href') || '';
        links.push({ text, href });
    });
    return links;
}

export const setLinksFromArray = (parent: HTMLElement, links: LinkOptions[], callback: (link: LinkOptions) => string) => {
    if (links) {
        let html = '';
        links.forEach((link) => {
            html += callback(link);
        });
        parent.innerHTML = html;
    } else {
        parent.innerHTML = '';
    }
}

export const setSocialLinks = (parent: HTMLElement, links: LinkOptions[]) => {
    setLinksFromArray(
        parent,
        links,
        (social) => `
            <a id="${social.text}" href="${social.href}" target="_blank">
                <i class="fa-brands fa-${social.text}" aria-hidden="true"></i>
            </a>`
    );
}

export class SectionLinks {
    body: HTMLElement;

    html: HTMLElement;

    constructor(body: HTMLElement) {
        this.body = body;
    }

    protected _setFromString(footerString: string, first?: boolean) {
        const el = document.createElement('div');
        el.innerHTML = footerString;
        this.html = el.childNodes[0];
        this.putInBody(first);
    }

    public getString(): string {
        const el = document.createElement('div');
        el.appendChild(this.html);
        const string = el.innerHTML;
        this.putInBody(this.first);
        return minifyHTML(string);
    }

    private first = false;

    protected putInBody(first?: boolean) {
        this.first = first;
        if (first) this.body.insertBefore(this.html, this.body.childNodes[0]);
        else this.body.append(this.html);
    }
}
