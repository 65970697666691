import { SolidParticleSystem } from '@babylonjs/core/Particles/solidParticleSystem';
import { SolidParticle } from '@babylonjs/core/Particles/solidParticle';
import { Scalar } from '@babylonjs/core/Maths/math.scalar';
import {
    Matrix, Vector2, Vector3, TmpVectors, Axis, Quaternion
} from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';

import { getRandomId } from '~/services/util';
import { ElementShape, TShape, isShapeNeedCulling } from './elementShape';
import {
    TPath,
    TPathFunction,
    PARTICLE_BY_LINE,
    PARTICLE_LINES,
    Plane,
    Square,
    Circle,
    Triangle,
    getDensityByShape,
    PARTICLE_LINES_LENGTH
} from './elementLayerPath';
import { ScrollPath } from '~src/Catchers/scrollPath';
import { System } from '~src/System/system';
import { DegreeToRadian } from '~src/Node/nodePosition';
import { WHITE_NOISE } from '~src/Material/Shader/shaderSave';

type OffsetType = 'Sin' | 'Random'

export interface ILayerNeedUpdateOnly {
    rotation?: number;
    scope?: number;
    offsetType?: OffsetType;
    offsetSize?: number;
    randomSize?: number;
    randomRotation?: number;
    path?: TPath;
    fixed?: boolean,
}

export interface ILayer extends ILayerNeedUpdateOnly {
    shapeDensity?: number;
    pathDensity?: number;
    shape?: TShape;
}

export const DEFAULT_LAYER: ILayer = {
    path: 'Plane',
    scope: 0.5,
    rotation: 0,
    shapeDensity: 0.5,
    pathDensity: 0.5,
    shape: 'Ribbon',
    fixed: false,
    offsetType: 'Sin',
    offsetSize: 0,
    randomSize: 1,
    randomRotation: 0,
};

const MAX_SCOPE = 15;

export class ElementLayer extends ElementShape {
    scrollPath: ScrollPath;

    constructor(system: System, scrollPath: ScrollPath) {
        super(system);
        this.scrollPath = scrollPath;
        //! Needed or layer transparency is not right
        this.shaderMaterial.needDepthPrePass = true;
        // Particle does not have a sampler otherwise
        this.setTextureSamplerUrl(WHITE_NOISE);
    }

    protected sps: SolidParticleSystem;

    protected _layer: ILayer = { ...DEFAULT_LAYER };

    private shapeDensity = 1;

    private pathDensity = 1;

    private densityRatio = 1;

    private buildDensityShapePath() {
        const {
            shapeDensity, pathDensity, path, shape
        } = this._layer;
        const lineDensity = Math.round(shapeDensity * PARTICLE_BY_LINE);
        this.shapeDensity = getDensityByShape(path, lineDensity);
        // this.pathDensity = this.shapeDensity;
        this.pathDensity = Math.round(PARTICLE_LINES * pathDensity);
        this.densityRatio = Math.round(PARTICLE_LINES / this.pathDensity) * PARTICLE_LINES_LENGTH;

        this.removeSolidParticle();
        this.initVectors();
        if (shape === 'Ribbon') {
            this.updateRibbon(this.linesVector);
        } else {
            const meshShape = this.getShape(shape);
            this.setParticle(meshShape);
            meshShape.dispose();
        }
        this.mesh.alwaysSelectAsActiveMesh = true; // Otherwise it is hidden
        this.shaderMaterial.backFaceCulling = isShapeNeedCulling(shape);
        this.checkLayerScrollPosition(this.layerScrollPosition);
    }

    public setLayerAndUpdate(layer: ILayerNeedUpdateOnly) {
        const keys = Object.keys(layer);
        keys.forEach((key) => {
            this._layer[key] = layer[key];
        });
        this.updateLayerPath();
    }

    public setLayerAndRebuild() {
        this.setVectorPositionFromPath(this._layer.path);
        this.buildDensityShapePath();
        this.updateLayerPath();
    }

    private getVectorPosition: TPathFunction = Plane;

    private setVectorPositionFromPath(path: TPath) {
        if (path === 'Plane') this.getVectorPosition = Plane;
        if (path === 'Circle') this.getVectorPosition = Circle;
        if (path === 'Triangle') this.getVectorPosition = Triangle;
        if (path === 'Square') this.getVectorPosition = Square;
    }

    private updateAlphaIndex() {
        const { scope } = this._layer;
        // * So that layers stay behind texts + images
        //! +1 otherwise at 0 it will always be 0 and above texts
        this.mesh.alphaIndex = -1000000 * (scope + 1);
        //* Otherwise it is hidden with scroll
        this.mesh.alwaysSelectAsActiveMesh = true;
    }

    // public setMoveRotation(moveRotation: number = this._layer.moveRotation) {
    //     this._layer.moveRotation = moveRotation;
    //     if (moveRotation) {
    //         this.system.scene.onBeforeRenderObservable.add(this.rotateParticles, 1, false, this);
    //     } else {
    //         const obs = this.system.scene.onAfterRenderObservable;
    //         obs.removeCallback(this.rotateParticles, this);
    //     }
    // }

    private linesVector: Vector3[][] = [];

    private linesParticle: SolidParticle[][] = [];

    // to avoid shader alpha issue
    public updateVertices() {
        if (this.mesh && this.mesh.isReady()) {
            //! Dirty fix as disableFacetData set this value to null creating a bug. Should do a BJS Pull Request
            this.mesh._internalAbstractMeshDataInfo._facetData.facetParameters = {};
            this.mesh.updateFacetData();
            setTimeout(() => {
                this.mesh.disableFacetData();
            }, 10);
            this.updateAlphaIndex();
        }
    }

    private setParticle(shape: Mesh) {
        const id = getRandomId();
        this.sps = new SolidParticleSystem(`SPS${id}`, this.system.scene, { enableDepthSort: true });
        const particleNumberNeeded = this.shapeDensity * this.pathDensity;
        this.sps.addShape(shape, particleNumberNeeded);
        const mesh = this.sps.buildMesh();
        this.setMesh(mesh);
        this.updateVertices();
    }

    private initVectors() {
        //! Even if linesVector get replaced, we need it for ribbon
        this.linesVector = [];
        for (let j = 0; j < this.pathDensity; j++) {
            this.linesVector.push([]);
            for (let i = 0; i < this.shapeDensity; i++) {
                //! Important to have X and Y setted so that shader is applied correctly on shape
                const y = -j;
                const x = i - this.shapeDensity / 2;
                this.linesVector[j].push(new Vector3(x, y, 0));
            }
        }
        this.offsetWithScroll();
    }

    private initParticleLines() {
        this.linesParticle = [];
        this.linesParticle.push([]);
        let j = 0;
        let i = 0;
        for (let p = 0; p < this.sps.nbParticles; p++) {
            const particle = this.sps.particles[p];
            this.linesParticle[j].push(particle);
            particle.position = this.linesVector[j][i].clone();
            i++;
            if (i === this.shapeDensity) {
                i = 0;
                if (j < this.pathDensity - 1) {
                    this.linesParticle.push([]);
                }
                j++;
            }
        }
        this.sps.setParticles();
    }

    private offsetWithScroll() {
        if (this.shape === 'Ribbon') {
            const pos = this.layerScrollPosition;
            // const ratio = 0.225 marche avec this.pathDensity = 0.1
            const ratio = 2.25 / this.pathDensity;
            this.setShaderVector2('offset', new Vector2(0, pos * ratio));
        }
    }

    private layerScrollPosition = 0;

    public checkLayerScrollPosition(position: number) {
        return
        const { fixed } = this._layer;
        //! position > 0 as scroll can sometime be negative
        if (!fixed) {
            const posRound = Math.round(position / this.densityRatio);
            const currentPosition = this.layerScrollPosition;
            const nextPos = currentPosition + 1;
            const previusPos = currentPosition - 1;
            // if (this._layer.shape === 'Ribbon') console.log(posRound, nextPos, previusPos);

            if (posRound >= nextPos) {
                // Move forward
                for (let l = currentPosition; l < posRound; l++) {
                    // if (this._layer.shape === 'Ribbon') console.log('push', l);
                    this.putFirstLast(l);
                }
                this.layerScrollPosition = posRound;
                this.offsetWithScroll();
            } else if (posRound <= previusPos) {
                // Move backward
                for (let l = previusPos; l > posRound - 1; l--) {
                    // if (this._layer.shape === 'Ribbon') console.log('pull', l);
                    this.putLastFirst(l);
                }
                this.layerScrollPosition = posRound;
                this.offsetWithScroll();
            }
        }
    }

    private updateParticleLine(line: number, source: Vector3[]) {
        if (!this.linesParticle.length) return;
        const lineParticle = this.linesParticle[line];
        for (let i = 0; i < lineParticle.length; i++) {
            const particle = lineParticle[i];
            particle.position = source[i];
        }
        this.sps.setParticles();
    }

    private putFirstLast(line: number) {
        const newPos = line + this.pathDensity;
        const newLine = this.getPathArrayFromPosition(newPos);
        if (newLine) {
            this.linesVector.shift();
            this.linesVector.push(newLine);
            if (this.sps) {
                this.updateParticleLine(0, newLine);
                this.linesParticle.push(this.linesParticle.shift());
            } else {
                this.updateRibbon(this.linesVector);
            }
        }
    }

    private putLastFirst(line: number) {
        const newPos = line;
        const newLine = this.getPathArrayFromPosition(newPos);
        if (newLine) {
            this.linesVector.pop();
            this.linesVector.unshift(newLine);
            if (this.sps) {
                this.updateParticleLine(this.pathDensity - 1, newLine);
                this.linesParticle.unshift(this.linesParticle.pop());
            } else {
                this.updateRibbon(this.linesVector);
            }
        }
    }

    public updateLayerPath() {
        this.updateVectors();
        if (this.sps) {
            this.initParticleLines();
            this.updateParticlesRotationScaling();
        } else {
            this.updateRibbon(this.linesVector);
        }
        this.updateAlphaIndex();
    }

    // extrusion geometry
    // https://playground.babylonjs.com/#MR8LEL#1660
    private extrusionPathArray(): Vector3[][] {
        const path3D = this.scrollPath.fullpath;
        const shape = this.lineShape;
        const shapePaths = [];
        const tangents = path3D.getTangents();
        const normals = path3D.getNormals();
        const binormals = path3D.getBinormals();
        const angle = 0;
        let index = 0;
        const rotationMatrix: Matrix = TmpVectors.Matrix[0];

        const curve = path3D.getCurve();
        for (let i = 0; i < curve.length; i += this.densityRatio) {
            const shapePath = new Array<Vector3>();
            Matrix.RotationAxisToRef(tangents[i], angle, rotationMatrix);
            for (let p = 0; p < shape.length; p++) {
                const planed = tangents[i].scale(shape[p].z).add(normals[i].scale(shape[p].x)).add(binormals[i].scale(shape[p].y));
                const rotated = Vector3.Zero();
                Vector3.TransformCoordinatesToRef(planed, rotationMatrix, rotated);
                rotated.addInPlace(curve[i]);
                this.addOffset(rotated, i, p);
                shapePath[p] = rotated;
            }
            shapePaths[index] = shapePath;
            index++;
        }
        return shapePaths;
    }

    private lineShape: Vector3[] = [];

    private getLineShapeWithScopeAndRotation(): Vector3[] {
        const { scope, rotation } = this._layer;
        const lineShape = [];
        const adjustedScope = ((scope / 2) + 0.2) * MAX_SCOPE;
        const adjustedRotation = rotation * DegreeToRadian;
        for (let i = 0; i < this.shapeDensity; i++) {
            const pos = this.getVectorPosition(i, this.shapeDensity, adjustedScope);
            const linePoint = new Vector3(pos.x, pos.y, 0);
            const rotQuart = Quaternion.RotationAxis(Axis.Z, adjustedRotation);
            linePoint.rotateByQuaternionAroundPointToRef(rotQuart, Vector3.Zero(), linePoint);
            lineShape.push(linePoint);
        }
        return lineShape;
    }

    private getPathArrayFromPosition(position: number): Vector3[] {
        return this._fullLinesVectors[position];
    }

    private getPathArrayFromStarEnd(start: number, end: number): Vector3[][] {
        return this._fullLinesVectors.slice(start, end);
    }

    private _fullLinesVectors: Vector3[][];

    private updateVectors() {
        this.lineShape = this.getLineShapeWithScopeAndRotation();
        this._fullLinesVectors = this.extrusionPathArray();
        const start = this.layerScrollPosition;
        const end = this.layerScrollPosition + this.pathDensity;
        // this.linesVector = this.getPathArrayFromStarEnd(start, end);
        this.linesVector = this._fullLinesVectors;
    }

    private offsetRatio = 3;

    private addPointOffset(vec: Vector3, i: number, p: number) {
        const { offsetSize, offsetType } = this._layer;
        const offset = (offsetSize || 0) * this.offsetRatio;
        let offX = 0, offY = 0, offZ = 0;
        if (!offsetType || offsetType === 'Random') {
            offX = Scalar.RandomRange(-offset, offset);
            offY = Scalar.RandomRange(-offset, offset);
            offZ = Scalar.RandomRange(-offset, offset);
        } else if (offsetType === 'Sin') {
            offX = Math.sin(i / 5) * offset;
            offY = Math.cos(i / 5) * offset;
            offZ = Math.cos(p / 5) * offset;
        }
        vec.x += offX;
        vec.y += offY;
        vec.z += offZ;
    }

    private addOffset(pos: Vector3, i: number, p: number) {
        if (this.shape === 'Ribbon') {
            // To avoid breaking shape and showing hole in plane
            if (p !== 0 && p !== this.shapeDensity - 1) {
                this.addPointOffset(pos, i, p);
            }
        } else {
            this.addPointOffset(pos, i, p);
        }
    }

    private mapParticles(func: (p: SolidParticle) => void) {
        for (let j = 0; j < this.linesParticle.length; j++) {
            const lineParticle = this.linesParticle[j];
            for (let i = 0; i < lineParticle.length; i++) {
                const particle = lineParticle[i];
                func(particle);
            }
        }
    }

    private updateParticlesRotationScaling() {
        const { randomRotation, randomSize } = this._layer;
        const rot = randomRotation;
        this.mapParticles((p) => {
            // Rotation
            p.rotation.x = Scalar.RandomRange(-rot, rot);
            p.rotation.y = Scalar.RandomRange(-rot, rot);
            p.rotation.z = Scalar.RandomRange(-rot, rot);
            // Scaling
            p.scaling = new Vector3(randomSize, randomSize, randomSize);
        });
        this.sps.setParticles();
    }

    // private rotateParticles() {
    //     if (this.sps) {
    //         const { moveRotation } = this._layer;
    //         const moveRotationRatio = 0.01 * moveRotation;
    //         this.mapParticles((p) => {
    //             p.rotation.x += moveRotationRatio;
    //             p.rotation.y += moveRotationRatio;
    //             p.rotation.z += moveRotationRatio;
    //         });
    //         this.sps.setParticles();
    //     }
    // }

    public removeSolidParticle() {
        if (this.sps) {
            this.sps.dispose();
            this.sps = null;
        }
        if (this.mesh) {
            this.mesh.dispose();
            this.mesh = null;
        }
    }

    public remove() {
        this._remove();
        this.removeSolidParticle();
    }
}
