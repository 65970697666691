import { Vector3 } from '@babylonjs/core/Maths/math';
import { setAttr } from 'redom';
import { Tools } from '@babylonjs/core/Misc/tools';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';

import { System } from '../System/system';
import { isModelFile } from '~/services/util';
import { ElementCard } from './elementCard';
import { TShape } from './elementShape';

export const IMAGE_TAG = 'img';
export const VIDEO_TAG = 'video';

export class ElementImage extends ElementCard {
    public html: HTMLImageElement;

    constructor(system: System, html: HTMLElement) {
        super(system, html);
        // We make sure image has the crossorigin attribute to avoid cors errors
        // loading: 'lazy' so that it get loaded only by scroll
        //! Lazy loading not working because of callback not called and image height jumps after loading
        //! Lazy should be managed entirely in javascript:
        // https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
        // https://itnext.io/how-to-stop-content-jumping-when-images-load-7c915e47f576
        setAttr(html, {
            draggable: false, loading: 'null', autoplay: false, loop: false, muted: true
        });
        //! Do not load here and let elementsManager manage it
        //! to have the Loaded notification event working
        // this.loadVisual();
    }

    public source: string;

    public loadVisual(callback?: () => void) {
        this.source = this.html.src;
        Tools.SetCorsBehavior(this.source, this.html);
        if (isModelFile(this.source)) {
            this.loadModel(callback);
        } else {
            // Do not check isImageFile as some image url does not have the extension
            this.loadImage(callback);
        }
    }

    private loadModel(callback?: () => void) {
        this.useShaderMaterial = false;
        this.system.getModel(this.source, (mesh) => {
            this.setMesh(mesh);
            this.checkGeometry();
            if (callback) callback();
        });
    }

    private checkVisualReadyInHtml(callback: () => void) {
        //! Force onload event to make sure we reset geometries
        //! when image is actually loaded and visible in html
        // const isLoaded = this.html.complete && this.html.naturalHeight !== 0;
        // if (isLoaded) {
        //     callback();
        // } else {
        //! onload will be called with lazy loading also
        //! Fit so that it takes full space even if no image yet
        this.html.style.backgroundSize = 'contain';
        // For Image
        this.html.onload = () => {
            this.html.onload = () => { };
            this.html.style.backgroundSize = 'none';
            callback();
        };
        // For Video
        //! onloadedmetadata faster than oncanplay
        // and oncanplay not working on mobile
        this.html.onloadedmetadata = () => {
            this.html.onloadedmetadata = () => { };
            this.html.style.backgroundSize = 'none';
            callback();
        };
        this.html.src = this.html.src;
        // }
    }

    private loadImage(callback?: () => void) {
        this.useShaderMaterial = true;
        //! Wait for HTML to be loaded
        this.checkVisualReadyInHtml(() => {
            this.checkScaling();
            // Can't load from url because of this S3/Chrome issue
            // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
            // So we add a query so that it erases the cache
            // fakeQuery only if cloudfront, because it can slow down other requests like unsplash
            // const fakeQuery = getRandomId();
            // const url = (this.source.includes('cloudfront')) ? `${this.source}?test=${fakeQuery}` : this.source;
            //! We do not need that anymore as we use the HTML buffer instead of URL
            const texture = this.system.getTexture(this.html, callback, true);
            this.setTextureSampler(texture);
        });
    }

    private isModel = false;

    protected checkScaling() {
        if (!this.mesh) return;
        const isModel = isModelFile(this.source);
        if (isModel) {
            this.setCssSize(null);
            const { clientWidth, clientHeight } = this.html.parentElement;
            const max = Math.max(clientWidth, clientHeight);
            this.setCssSize(max);
            const size = this.getSize();
            const min = Math.min(size.width, size.height);
            this.mesh.scaling = new Vector3(min, min, min);
            this.isModel = true;
        } else if (this.source) {
            if (this.isModel) this.setCssSize(null);
            if (this.shape === 'Card') {
                this.checkScalingCard();
            } else {
                const size = this.getSize();
                this.setScaling(size);
            }
        }
        this.isModel = isModel;
    }

    public setCardShape(shape: TShape) {
        this.shape = shape;
        if (shape === 'Card') {
            const extrudeShape = this.getExtrudeShapeOptions();
            this.getExtrudedCard(extrudeShape)
        } else {
            const mesh = this.getShape(shape);
            this.setCardMesh(mesh);
            this.checkScaling();
        }
    }

    private setCssSize(size: number) {
        const { style } = this.html;
        const pixel = size ? `${size}px` : null;
        style.height = pixel;
        style.width = pixel;
        style.display = size ? 'inline-block' : null;
    }
}
