import { IVector3Like } from '@babylonjs/core/Maths/math.like';
import { Vector3 } from '@babylonjs/core/Maths/math';

import { System } from '../System/system';
import { Timeline } from '~src/System/systemTimeline';
import { DegreeToRadian } from '~src/Node/nodePosition';
import {
    DEFAULTSCROLLOPTIONS, IScroll, ScrollEvent, ScrollPath
} from './scrollPath';
import { EScrollPath } from './scrollPathFunction';

export class ScrollCatcher extends ScrollPath {
    private animation: Timeline;

    constructor(system: System, browser: HTMLElement) {
        super(system, browser);
        this.animation = new Timeline(system, 'scroll', { ease: 'power4.out' });
    }

    public set options(options: IScroll) {
        if (!options) options = DEFAULTSCROLLOPTIONS;
        this.setCatchSpeed(options.catchSpeed);
        this.setPath(options.path);
        this.setOrientation(options.orientation);
        this.setFollowOrientation(options.followOrientation);
    }

    public get options(): IScroll {
        return {
            ...this._options,
        };
    }

    public setCatchSpeed(catchSpeed = this._options.catchSpeed) {
        this._options.catchSpeed = catchSpeed;
    }

    public setPath(path: EScrollPath = EScrollPath.LINEAR) {
        this._options.path = path;
        this._setPath(path);
        this.resetScroll();
    }

    public setOrientation(orientation: IVector3Like = { x: 0, y: 0, z: 0 }) {
        this._options.orientation = orientation;
        this.orientationVector = new Vector3(
            orientation.x * DegreeToRadian,
            orientation.y * DegreeToRadian,
            orientation.z * DegreeToRadian
        );
        this.updateFullPath(true);
        this.resetScroll();
    }

    public setFollowOrientation(followOrientation: boolean = false) {
        this._options.followOrientation = followOrientation;
    }

    public onChange(funct: (perc: number) => void, scope?: any, first?: boolean) {
        this.on(ScrollEvent.Progress, funct, scope, first);
    }

    private notifyChange(perc: number) {
        this.notify(ScrollEvent.Progress, perc);
    }

    public startScroll() {
        const pathLength = this.getPathLength();
        this.setPageScrollPercentage(-10 / pathLength)
        setTimeout(() => {
            this.catchScroll(0, 0.5)
            this.setScrollEvents();
        }, 100)
    }

    private setScrollEvents() {
        this.browser.addEventListener('scroll', () => {
            if (!this.freezed) this.checkScroll();
        });

        this.browser.addEventListener('pointerup', () => {
            setTimeout(() => {
                const { hash } = window.location;
                if (hash) {
                    const scrollTarget = this.browser.querySelector(hash);
                    if (scrollTarget) {
                        // scrollIntoView not compatible Apple yet (Safari & Iphone)
                        if (scrollTarget.scrollIntoView) {
                            scrollTarget.scrollIntoView();
                        } else if (scrollTarget.scrollIntoViewIfNeeded) {
                            scrollTarget.scrollIntoViewIfNeeded(true);
                        }
                    }
                }
            }, 10);
        });
    }

    //! -0.1 so that it start with a small animation
    public pageScrollPercentage = 0;

    private isScrolling = false;

    private scrollingTimeout;

    private checkScroll() {
        //* Test to change how scroll works
        //* If scroll seems buggy on iOS, it is probably due to low battery mode
        // if (!this.isScrolling) {
        //     this.animation.infinite(() => {
        //         const scroll = this.getScrollPercentage();
        //         this.catchScroll(scroll);
        //     });
        // }
        // this.isScrolling = true;
        // clearTimeout(this.scrollingTimeout);
        // this.scrollingTimeout = setTimeout(() => {
        //     this.animation.stop();
        //     this.isScrolling = false;
        // }, 150);

        const scroll = this.getScrollPercentage();
        this.catchScroll(scroll);
    }

    private scrollGap = 0;

    private start = 0;

    private catchScroll(scroll: number, speed?: number) {
        const { catchSpeed } = this._options;
        if (!speed) speed = catchSpeed;
        if (!this.animation.running) this.notify(ScrollEvent.Start, 0);
        this.start = this.pageScrollPercentage;
        this.scrollGap = scroll - this.start;
        let howmany = (1 - speed) * 5;
        this.animation.simple(howmany, (perc) => {
            this.scrollChange(perc);
        });
    }

    public resetScroll() {
        // End of animation to have correct position
        this.notify(ScrollEvent.Start, 0);
        this.start = 0;
        this.scrollChange(0)
    }

    private scrollChange(perc: number) {
        const progressGap = perc * this.scrollGap;
        this.pageScrollPercentage = this.start + progressGap;
        this.setPercentage(perc);
    }

    public setPageScrollPercentage(pageScrollPercentage: number) {
        this.start = pageScrollPercentage;
        this.scrollChange(0)
    }

    public percentage = 0;

    public setPercentage(percentage: number) {
        this.percentage = percentage;
        this.setGeometryFromPercentage(this.pageScrollPercentage);
        this.notifyChange(percentage);
    }
}
