import { ISize, Vector3 } from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';

import { NodePosition, getElementScrollPositionFromCenter } from './nodePosition';

export class NodeScaling extends NodePosition {
    public mesh: Mesh;

    public checkGeometry() {
        this._checkGeometry();
    }

    protected _checkGeometry() {
        if (!this.mesh) return;
        this.checkScaling();
        this.checkPosition();
        // this.checkAlphaIndex();
    }

    protected checkAlphaIndex() {
        //! Alpha index not working with some scroll like circle
        //! To make sure rendering order is right
        const { htmlParent } = this.system;
        const nodeRelativePos = getElementScrollPositionFromCenter(htmlParent, this.html);
        this.mesh.alphaIndex = -nodeRelativePos.y / 100;
    }

    protected checkScaling() {
        const size = this.getSize();
        this.setScaling(size);
    }

    public setScaling(size: ISize) {
        if (!this.mesh) return;
        let min = Math.min(size.width, size.height)
        this.mesh.scaling = new Vector3(min, min, min);
    }

    public setHeightWidth(size: ISize) {
        if (!this.mesh) return;
        this.mesh.scaling.x = size.width;
        this.mesh.scaling.y = size.height;
    }


    private getPixelSize(): ISize {
        //! Do not use getBoundingClientRect as it follows css transform
        // const rect = this.html.getBoundingClientRect();
        // Use scrollWidth and Height to manage the case where text is outside element
        const size = {
            width: this.html.scrollWidth || 1,
            height: this.html.scrollHeight
        };
        return size;
    }

    protected getSize(): ISize {
        const size = this.getPixelSize();
        return this.getLimitSize(size);
    }

    private getLimitSize(size: ISize): ISize {
        const maxSize = Math.max(size.height, size.width);
        const limitSize = { width: 1, height: 1 };
        const ratio = size.height / size.width;
        if (maxSize === size.width) {
            limitSize.width = maxSize;
            limitSize.height = maxSize * ratio;
        } else {
            limitSize.width = maxSize / ratio;
            limitSize.height = maxSize;
        }
        limitSize.width /= this.system.scenePixelRatio;
        limitSize.height /= this.system.scenePixelRatio;
        limitSize.width = Math.round(limitSize.width * 100) / 100;
        limitSize.height = Math.round(limitSize.height * 100) / 100;
        return limitSize;
    }
}
