import remove from 'lodash/remove';
import filter from 'lodash/filter';

import { System } from '../System/system';
import { ShaderCamera } from '~src/Material/Camera/shaderCamera';
import { ElementEvent, ElementsManager } from '~src/Element/elementsManager';
import { ShaderInteraction, EVENT } from '~src/Material/Shader/shaderInteraction';
import { InteractionOptions } from './interactionElements';
import { Interaction } from './interaction';

export class InteractionManagerPlay {
    protected browser: HTMLElement;

    protected system: System;

    protected elementsManager: ElementsManager;

    protected shaderCamera: ShaderCamera;

    public _interactions: Interaction[] = [];

    constructor(
        browser: HTMLElement,
        system: System,
        elementsManager: ElementsManager,
        shaderCamera: ShaderCamera
    ) {
        this.system = system;
        this.browser = browser;
        this.elementsManager = elementsManager;
        this.shaderCamera = shaderCamera;

        this.elementsManager.on(ElementEvent.Added, () => {
            this.checkInteractionElementsAndUpdate();
        });

        this.elementsManager.on(ElementEvent.Removed, (element) => {
            this.removeElementFromInteractions(element);
        });
    }

    protected getInteractionByEvent(event: EVENT): Interaction[] {
        return filter(this._interactions, (a) => a.event === event);
    }

    protected setInteraction(interactionOptions: InteractionOptions, callback?: () => void): Interaction {
        const interaction = new Interaction(
            this.system,
            this.browser,
            this.elementsManager,
            this.shaderCamera,
            interactionOptions
        );
        this._interactions.push(interaction);
        interaction.checkSteps();
        //! Wait for render or it doesn't work
        this.system.addOnce(() => {
            interaction.checkInteractionElements();
            if (callback) callback();
        });
        return interaction;
    }

    protected unsetInteraction(interaction: Interaction) {
        remove(this._interactions, (i) => i === interaction);
    }

    public checkElementsInInteractions() {
        this._interactions.forEach((interaction) => {
            interaction.checkInteractionElements();
        });
    }

    public checkInteractionElementsAndUpdate() {
        this._interactions.forEach((interaction) => {
            interaction.checkInteractionElementsAndUpdate();
        });
    }

    public removeElementFromInteractions(element: ShaderInteraction) {
        this._interactions.forEach((interaction) => {
            remove(interaction.elements, (e) => e === element);
            element.killTimelines();
        });
    }

    protected getInteractionElementByEvent(
        element: ShaderInteraction,
        event: EVENT
    ): Interaction {
        const eventInteractions = this.getInteractionByEvent(event);
        let interactionByEvent = null;
        eventInteractions.forEach((interaction) => {
            if (interaction.elements.includes(element)) {
                interactionByEvent = interaction;
            }
        });
        return interactionByEvent;
    }

    public checkElementsAttributes() {
        const { elements } = this.elementsManager;
        elements.forEach((element) => {
            element.checkAttributes();
        });
    }
}
