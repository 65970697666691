import {
    mount, unmount, setChildren
} from 'redom';
import remove from 'lodash/remove';
import find from 'lodash/find';

import { System } from '~src/System/system';
import { ScrollCatcher } from '../Catchers/scrollCatcher';
import { ElementEvent, ElementsManager } from '../Element/elementsManager';
import { getVisibleHeightPx, minifyHTML, reorderArray, stringToHtml } from '~/services/util';
import { ElementShape } from '~src/Element/elementShape';
import { Section, findParentHtmlByTag } from '~src/Section/section';
import { Observable } from '~src/Tools/observable';
import { ScrollEvent } from '~src/Catchers/scrollPath';

export const SECTION_TAG = ['SECTION'];
// export const SECTION_TAG = ['SECTION', 'HEADER', 'FOOTER'];

export enum SectionEvent {
    Moved,
    Rename
}

export class SectionsManager extends Observable<SectionEvent, Section> {
    private htmlSections: HTMLElement[] = [];

    protected sections: Section[] = [];

    protected system: System;

    private browser: HTMLElement;

    private scrollCatcher: ScrollCatcher;

    protected elementsManager: ElementsManager;

    constructor(
        system: System,
        browser: HTMLElement,
        scrollCatcher: ScrollCatcher,
        elementsManager: ElementsManager
    ) {
        super('SECTION');
        this.system = system;
        this.browser = browser;
        this.scrollCatcher = scrollCatcher;
        this.elementsManager = elementsManager;

        this.setPage(browser);
        this.setAlignmentEvent();
        this.setElementEvent();
    }

    private setAlignmentEvent() {
        this.scrollCatcher.on(ScrollEvent.Alignment, () => {
            this.checkAlignment();
        });
    }

    private setElementEvent() {
        this.elementsManager.on(ElementEvent.Added, (element) => {
            this.addElementSection(element);
        });

        this.elementsManager.on(ElementEvent.Removed, (element) => {
            this.removeElementSection(element);
        });

        this.elementsManager.on(ElementEvent.Loaded, () => {
            this.checkSectionsGeometry();
        });
    }

    private checkAlignment() {
        this.checkSectionsGeometry();
        setTimeout(() => {
            //! To make sure all alphaIndex are right depending on alignement
            this.elementsManager.checkGeometries();
        }, 100);
    }

    public checkSectionsGeometry() {
        this.sections.forEach((section) => {
            const perc = section.getTopPercentage();
            section.checkGeometry(perc);
        });
    }

    private addElementSection(element: ElementShape) {
        const section = this.getSectionFromElement(element);
        if (section) section.addElement(element);
        this.checkSectionsGeometry();
    }

    private removeElementSection(element: ElementShape) {
        const section = this.getSectionFromElement(element);
        if (section) section.removeElement(element);
        this.checkSectionsGeometry();
    }

    private getSectionFromElement(element: ElementShape) {
        const sectionHtml = findParentHtmlByTag(element.html, SECTION_TAG);
        const section = this.getSectionFromHtml(sectionHtml);
        return section;
    }

    public getSectionFromHtml(section: HTMLElement): Section {
        return find(this.sections, (s) => s.html === section);
    }

    public setPageFromString(sectionsString: string) {
        this.browser.innerHTML = sectionsString;
        const children = this.browser.childNodes;
        this.addSections(children);
    }

    private setPage(sectionsParent: HTMLElement) {
        const sections = sectionsParent.querySelectorAll('section');
        this.addSections(sections);
    }

    public getPageSectionsString(): string {
        const html = this.browser.innerHTML;
        return minifyHTML(html);
    }

    private addSections(sections: NodeListOf<HTMLElement>) {
        this.sections = [];
        sections.forEach((child) => {
            if (SECTION_TAG.includes(child.tagName)) {
                this.addSection(child);
            }
        });
        //! Can't do that as childNodes is not an array
        // this.sections = this.browser.childNodes;
        this.checkSectionsGeometry();
    }

    private addSection(sectionHtml: HTMLElement, force?: boolean): Section {
        if (!sectionHtml.parentNode || force) mount(this.browser, sectionHtml);
        this.htmlSections.push(sectionHtml);
        const section = new Section(this.system, sectionHtml, this.scrollCatcher);
        this.sections.push(section);
        this.checkSectionsGeometry();
        return section;
    }

    public addSectionFromString(section: string): Section {
        const newSection = stringToHtml(section);
        return this.addSection(newSection, true);
    }

    public removeSection(section: Section) {
        const sectionHtml = section.html;
        remove(this.htmlSections, (s) => s === sectionHtml);
        unmount(this.browser, sectionHtml);
        remove(this.sections, (s) => s.html === sectionHtml)[0];
        section.elements.forEach((element) => {
            this.elementsManager.removeElement(element);
        });
        this.checkSectionsGeometry();
    }

    public removeAllSections() {
        setChildren(this.browser, []);
        this.htmlSections = [];
        this.sections = [];
        this.elementsManager.removeAllElements();
    }

    public reorderSections(startIndex: number, endIndex: number): Section[] {
        this.sections = reorderArray(this.sections, startIndex, endIndex);
        this.htmlSections = reorderArray(this.htmlSections, startIndex, endIndex);
        setChildren(this.browser, this.htmlSections);
        this.checkSectionsGeometry();
        this.notify(SectionEvent.Moved, null);
        return this.sections;
    }

    public getSections(): Section[] {
        return this.sections;
    }

    public getSectionFocused(): Section {
        let sectionFocused: Section;
        const viewportHeight = window.innerHeight;
        let max = 0;

        this.sections.forEach((s) => {
            const visiblePx = getVisibleHeightPx(s.html, viewportHeight);
            if (visiblePx > max) {
                max = visiblePx;
                sectionFocused = s;
            }
        });

        return sectionFocused;
    }
}
