import remove from 'lodash/remove';

import { System } from '../System/system';
import { ScrollEvent } from '~src/Catchers/scrollPath';
import { ScrollCatcher } from '~src/Catchers/scrollCatcher';
import { DEFAULT_ELEMENT_LAYER, ElementBackground, IElementBackground } from '~src/Element/elementBackground';

export class PageBackground {
    private system: System;

    private scrollCatcher: ScrollCatcher;

    public layers: ElementBackground[] = [];

    constructor(
        system: System,
        scrollCatcher: ScrollCatcher,
    ) {
        this.system = system;
        this.scrollCatcher = scrollCatcher;

        scrollCatcher.onChange(() => {
            const { progressInUnit } = scrollCatcher;
            this.layers.forEach((layer) => {
                layer.checkLayerScrollPosition(progressInUnit);
            });
        });

        scrollCatcher.on(ScrollEvent.Alignment, () => {
            this.layers.forEach((layer) => {
                layer.updateLayerPath();
            });
        });

        scrollCatcher.on(ScrollEvent.Start, () => {
            this.updateVertices();
        });

        // TEST to have texture as fog
        // const backLayer = new ElementBackground(this.system);
        // backLayer.optiions = DEFAULT_ELEMENT_LAYER;
        // backLayer.mesh.rotation.x = Math.PI;
        // backLayer.mesh.position.z = 100;
        // backLayer.mesh.scaling.x = 2;
        // backLayer.mesh.scaling.y = 2;
        // backLayer.mesh.parent = this.system.freeCamera;
    }

    public set options(v: IElementBackground[]) {
        v.forEach((option) => {
            this.addLayer(option);
        });
    }

    public get options(): IElementBackground[] {
        const opt = [];
        this.layers.forEach((l) => {
            opt.push(l.options);
        });
        return opt;
    }

    public updateVertices() {
        this.layers.forEach((layer) => {
            layer.updateVertices();
        });
    }

    public addLayer(elementBackground: IElementBackground): ElementBackground {
        const newLayer = new ElementBackground(this.system, this.scrollCatcher);
        newLayer.options = elementBackground;
        this.layers.push(newLayer);
        return newLayer;
    }

    public removeLayer(id: string) {
        const removed: ElementBackground = remove(
            this.layers,
            (l: ElementBackground) => l.id === id
        )[0];
        removed.remove();
    }
}
