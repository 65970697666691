import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

import { EVENT } from '~src/Material/Shader/shaderInteraction';
import { InteractionOptions } from './interactionElements';
import { InteractionManagerScroll, ScrollInteractionOptions } from './interactionManagerScroll';
import { Interaction } from './interaction';

export class InteractionManager extends InteractionManagerScroll {
    public set options(v: InteractionOptions[]) {
        if (!v) v = [];
        v.forEach((interaction) => {
            this.addInteraction(interaction);
        });
    }

    public get options(): InteractionOptions[] {
        const interactionsOptions: InteractionOptions[] = [];
        this._interactions.forEach((interaction) => {
            const exportInteraction = interaction.options;
            interactionsOptions.push(exportInteraction);
        });
        return interactionsOptions;
    }

    public get interactions(): Interaction[] {
        return this._interactions;
    }

    public setInteractionProperty(interaction: Interaction, property: string, value): Interaction {
        if (property === 'event') {
            this.removeInteraction(interaction);
            const interactionOptions = interaction.options;
            interactionOptions[property] = value;
            return this.addInteraction(interactionOptions);
        }
        interaction[property] = value;
        interaction.reset();
        return interaction;
    }

    public resetInteractionByTarget(target: string[]): void {
        this._interactions.forEach((int) => {
            if (isEqual(int.target, target)) {
                int.resetTimelines();
            }
        });
    }

    public getInteractionByTargetAndEvent(target: string[], event: EVENT): Interaction {
        return find(this._interactions, (i) => isEqual(i.target, target) && i.event === event);
    }

    public addInteraction(interactionOptions: InteractionOptions): Interaction {
        const { event } = interactionOptions;
        //! Move interaction does not work on mobile
        if (event === EVENT.MOVE && this.system.isOnMobile) return null;

        if (event === EVENT.SCROLL || event === EVENT.SCROLLINTO) {
            return this.addScrollInteraction(interactionOptions as ScrollInteractionOptions);
        } if (event === EVENT.MOVE) {
            return this.addMoveInteraction(interactionOptions);
        }
        return this.setInteraction(interactionOptions);
    }

    public removeInteraction(interaction: Interaction): void {
        const { event } = interaction;
        if (event === EVENT.SCROLL || event === EVENT.SCROLLINTO) {
            this.removeScrollInteraction(interaction);
        } else if (event === EVENT.MOVE) {
            this.removeMoveInteraction(interaction);
        }
        this.unsetInteraction(interaction);
    }
}
