#include<ColorMapping>

varying vec2 vUV;

// MASK
// uniform float time;
uniform sampler2D textureSampler;

// GENERAL
uniform vec2 resolution;
// uniform vec4 shaderColor1;
// uniform vec4 shaderColor2;

// MASK PARAM
uniform vec4 mouseTrailOffsetTime;

// FILTERS
uniform float chromatic;
uniform float blur;
uniform float pixel;
uniform float zoom;
uniform float glass;
uniform float grain;
uniform float invert;

/* Advise to improve shader
Use vector and matrix operations: GLSL is designed to work with vector and matrix operations, so try to take advantage of this by using built-in functions like dot, cross, and matrix multiplication instead of writing your own operations.

Avoid branching: Branching (if/else statements) can be expensive in shaders, so try to use boolean operators and built-in functions like mix or step instead whenever possible.
*/

vec3 getFilterImage(float mouseTrailMask) {

    float power = 5.;
    vec2 filterUV = vUV;

    ivec2 textureSize2d = textureSize(textureSampler,0);
    float textureX = float(textureSize2d.x);
    float textureY = float(textureSize2d.y);
    float textureRatio = textureX/textureY;

    float filterMask = mouseTrailMask * power;

    // Pixel Effect
    if (pixel != 0.0) {
        float filteredPixel = pixel * filterMask;
        filteredPixel = filteredPixel * power / 50. + 0.0001;
        // float filteredPixel = pixel * power * filterMask / 5. + 0.0001;

        float pixel_w = 0.1 * filteredPixel;
        float pixel_h = 0.1 * filteredPixel * textureRatio;
        vec2 pixeldUV = 2.0 * filterUV - 1.0;
    
        pixeldUV = vec2(
            pixel_w * floor(pixeldUV.x/pixel_w) + (pixel_w / 2.0), 
            pixel_h * floor(pixeldUV.y/pixel_h) + (pixel_h / 2.0)
        );
        filterUV = 0.5 * (pixeldUV + 1.0);
    }

    // Fisheye Effect
    if (zoom != 0.0) {
        float filteredZoom = zoom * filterMask;
        filteredZoom = 1. - filteredZoom / 20.;

        vec2 scaleCenter = vec2(0.5);
        filterUV = (filterUV - scaleCenter) * filteredZoom + scaleCenter;
    }

    // Glass Effect
    // if (glass != 0.0) {
    //     float displace = (filterMask - 0.5) * glass / 10.0;
    //     filterUV += vec2(displace);
    // }   

    vec4 originImage = texture2D(textureSampler, filterUV);

    // Chromatic & Blur Effect
    vec3 blurChromImage = originImage.rgb;
    if (blur != 0.0 || chromatic != 0.0) {
        // Chromatic effect
        float filteredChromatic = chromatic * filterMask;
        filteredChromatic = filteredChromatic * 0.005;

        vec2 rUV = vec2(filterUV.x + filteredChromatic, filterUV.y + filteredChromatic);
        vec2 gUV = vec2(filterUV.x - filteredChromatic, filterUV.y + filteredChromatic);
        vec2 bUV = vec2(filterUV.x, filterUV.y - filteredChromatic);

        // Blur effect
        float filteredBlur = blur * filterMask;
        filteredBlur = filteredBlur * 0.0005;

        int qualityInt = 15;
        float qualityFloat = 15.0;
        int halfQuality = 7;
        float weights[15] = float[15](0.004, 0.008, 0.016, 0.032, 0.07, 0.1, 0.15, 0.22, 0.15, 0.1, 0.07, 0.032, 0.016, 0.008, 0.004);

        vec2 texelStep = vec2(1.0, 1.0) * filteredBlur;
        vec2 texelStep2 = vec2(-1.0, 1.0) * filteredBlur;

        blurChromImage = vec3(0., 0., 0.);
        for (int i = 0; i < qualityInt; i++)
        {
            vec2 tc1 = texelStep * float(i - halfQuality);
            blurChromImage.r += weights[i] * texture2D(textureSampler, rUV + tc1).r;
            blurChromImage.g += weights[i] * texture2D(textureSampler, gUV + tc1).g;
            blurChromImage.b += weights[i] * texture2D(textureSampler, bUV + tc1).b;

            vec2 tc2 = texelStep2 * float(i - halfQuality);
            blurChromImage.r += weights[i] * texture2D(textureSampler, rUV + tc2).r;
            blurChromImage.g += weights[i] * texture2D(textureSampler, gUV + tc2).g;
            blurChromImage.b += weights[i] * texture2D(textureSampler, bUV + tc2).b;
        }
        blurChromImage /= 2.0;

        // float filteredBlur = blur * 0.002;
        // float Pi = 6.28318530718; // Pi*2
        // float Directions = 8.0; // BLUR DIRECTIONS (Default 16.0 - More is better but slower)
        // float Quality = 2.0; // BLUR QUALITY (Default 4.0 - More is better but slower)
        // blurChromImage = texture2D(textureSampler, filterUV).rgb;
        
        // for( float d = 0.0; d < Pi; d += Pi / Directions)
        // {
        //     for(float i = 1.0 / Quality; i <= 1.0; i += 1.0 / Quality)
        //     {
        //         vec2 tc = vec2(cos(d),sin(d)) * filteredBlur * i;
        //         blurChromImage.r += texture2D(textureSampler, rUV + tc).r;		
        //         blurChromImage.g += texture2D(textureSampler, gUV + tc).g;		
        //         blurChromImage.b += texture2D(textureSampler, bUV + tc).b;		
        //     }
        // }
        // blurChromImage /= Quality * Directions * 1.5;
    }

    // Color Effect
    vec3 colorImage = blurChromImage;
    // vec3 hsl1 = shaderColor1.xyz;
    // vec3 hsl2 = shaderColor2.xyz;
    // vec3 rgb1 = hsl2rgb(hsl1);
    // vec3 rgb2 = hsl2rgb(hsl2);

    // vec3 blendColorHsl1 = filterMask * hsl1;
    // vec3 blendColorHsl2 = (1.0 - filterMask) * hsl2;
    // vec3 blendColorHsl = blendColorHsl1 + blendColorHsl2;

    // vec3 blendColorRgb1 = filterMask * rgb1;
    // vec3 blendColorRgb2 = (1.0 - filterMask) * rgb2;
    // vec3 blendColorRgb = blendColorRgb1 + blendColorRgb2;

    // float lightness = blendColorHsl.b;
    // float saturation = blendColorHsl.g;
    // vec3 lightColor = colorImage * lightness;
    // vec3 lightnessVec3 = vec3(0.5, 0.5, 0.5);
    // float saturationDot = dot(lightColor, lightnessVec3);

    // colorImage = mix(vec3(saturationDot), lightColor, saturation) * blendColorRgb;

    // Invert Color Effect
    vec3 invertImage = colorImage;
    if (invert != 0.0) {
        float filteredInvert = invert * filterMask;
        vec3 colorGap = 2.0 * colorImage - 1.0;
        invertImage = colorImage - colorGap * filteredInvert;
    }

    // Grain Effect
    vec3 grainImage = invertImage;
    if (grain != 0.0) {
        float filteredGrain = grain * filterMask;
        filteredGrain = filteredGrain * 0.2;
        float noise = (fract(sin(dot(filterUV, vec2(12.9898,78.233)*2.0)) * 43758.5453));
        grainImage = grainImage - noise * filteredGrain;
    }

    return grainImage;
}