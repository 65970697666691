import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';
import { Rectangle } from '@babylonjs/gui/2D/controls/rectangle';
import { Control } from '@babylonjs/gui/2D/controls/control';
//! Depending on BabylonJS version, we could need that
// import '@babylonjs/core/Materials/Textures/dynamicTexture';
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture';

import { System } from '../System/system';
import { CardOptions, ElementCard } from './elementCard';
import { elementPropertiesToFury } from '~src/Tools/toolStyleTranslation';
import { getLinkTargetAttrValue } from '~services/util';

export type Alignement = 'start' | 'center' | 'end'

export interface BorderOptions {
    border?: number,
    borderColor?: string | number[],
    padding?: number,
}

export interface OutlineOptions {
    outlineWidth?: number,
    outlineColor?: string | number[],
}

export interface StyleOptions {
    fontStyle?: 'italic' | 'normal',
    fontWeight?: '800' | '400',
    textAlign?: Alignement,
    textDecoration?: 'underline' | 'none',
}

export interface TextOptions extends
    BorderOptions,
    OutlineOptions,
    StyleOptions,
    CardOptions {
    text?: string,
    fontFamily?: string,
    fontSize?: number,
    lineHeight?: number,
    color?: string | number[],
    backgroundColor?: string | number[],
}

export const HEADING_TAG = 'h1';
export const TEXT_TAG = 'p';
export const BUTTON_TAG = 'a';

export const TEXT_BUTTON_HEADING_TAGS = [HEADING_TAG, TEXT_TAG, BUTTON_TAG];

/* Playground for test purpose:
https://playground.babylonjs.com/#XCPP9Y#20891
https://www.babylonjs-playground.com/#XCPP9Y#20943
*/

export class ElementText extends ElementCard {
    constructor(system: System, html: HTMLElement) {
        super(system, html);
        this.buildText();
        this.checkHtmlTarget();
        this.setShaderValue('useLight', 0);
    }

    private advancedTexture: AdvancedDynamicTexture;

    private text: TextBlock;

    private container: Rectangle;

    private buildText() {
        this.container = new Rectangle();
        this.container.thickness = 0;
        this.container.isHitTestVisible = false;

        this.text = new TextBlock();
        // this.text.topInPixels = 1;
        this.text.textWrapping = true;
        this.text.isHitTestVisible = false;
        // this.text.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        // this.text.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;

        //! Rewrite function so that word split includes "-"
        this.text._parseLineWordWrap = (line = '', width: number, context): object[] => {
            const lines = [];
            // /(?<=-| )/ Doesn't work in Safari
            // /(?:-| )/ Doesn't keep the space
            // /[ -]+/
            // /(\s|-)/ Works everywhere but also split space which is not ideal

            const words = this.text.wordSplittingFunction ? this.text.wordSplittingFunction(line) : line.split(/(\s|-)/);
            let lineWidth = this.text._getTextMetricsWidth(context.measureText(line));

            for (let n = 0; n < words.length; n++) {
                const testLine = n > 0 ? line + words[n] : words[0];
                const testWidth = this.text._getTextMetricsWidth(context.measureText(testLine));
                if (testWidth > width && n > 0) {
                    lines.push({ text: line, width: lineWidth });
                    line = words[n];
                    lineWidth = this.text._getTextMetricsWidth(context.measureText(line));
                } else {
                    lineWidth = testWidth;
                    line = testLine;
                }
            }
            lines.push({ text: line, width: lineWidth });

            return lines;
        };

        // If adapt is needed
        // this.container.adaptHeightToChildren = true;
        // this.container.adaptWidthToChildren = true;
        // this.text.resizeToFit = true; // Or adaptToChildren doesn't work
        this.container.addControl(this.text);
    }

    private setAdvancedTexture() {
        if (this.advancedTexture) {
            this.advancedTexture.removeControl(this.container);
            this.advancedTexture.dispose();
        }
        const size = this.getSize();
        const width = size.width * this.textureQuality;
        const height = size.height * this.textureQuality;

        this.advancedTexture = AdvancedDynamicTexture.CreateForMeshTexture(
            this.mesh,
            width,
            height
        );
        //* Test so that border edge are perfect but on big planes it doesn't work
        // const scale = -0.01;
        // this.advancedTexture.uScale += scale;
        // this.advancedTexture.vScale += scale;
        // this.advancedTexture.uOffset -= scale / 2;
        // this.advancedTexture.vOffset -= scale / 2;

        this.advancedTexture.addControl(this.container);
        this.setTextureSampler(this.advancedTexture);
    }

    public checkGeometry() {
        this.checkPosition();
        const changed = this.checkScalingCard();
        if (changed || !this.advancedTexture) {
            this.setAdvancedTexture();
            this.checkOptions();
        } else {
            this.checkOptions();
        }
        //! Keep alphaIndex after so that the last mesh has the right index
        // this.checkAlphaIndex();
        this.checkHtmlTarget();
    }

    private forceRefresh() {
        // This force refresh, otherwise lineSpace is not good
        this.advancedTexture.removeControl(this.container);
        setTimeout(() => {
            this.advancedTexture.addControl(this.container);
        }, 10);
    }

    private checkHtmlTarget() {
        const href = this.html.getAttribute('href');
        if (href) {
            const target = getLinkTargetAttrValue(href);
            if (target) this.html.setAttribute('target', target);
            else this.html.removeAttribute('target');
        }
    }

    public getOptions(): TextOptions {
        const textOptions = elementPropertiesToFury(this.html) as TextOptions;
        textOptions.text = this.html.innerText;
        return textOptions;
    }

    private checkOptions() {
        const textOptions = this.getOptions();
        if (textOptions.text) {
            this.mesh.isVisible = true;
            this.setText(textOptions);
            this.setColor(textOptions);
            this.setFont(textOptions);
            this.setPadding(textOptions);
            this.setBorder(textOptions);
            this.setOutline(textOptions);
        } else {
            this.mesh.isVisible = false;
        }
    }

    private getExpetedLines(textOptions: TextOptions) {
        const {
            lineHeight, padding, border, fontSize
        } = textOptions;
        const divHeight = this.html.offsetHeight - padding * 2 - border * 2;
        const lineH = (!lineHeight) ? fontSize * 1.2 : lineHeight;
        const expetedLines = Math.round(divHeight / lineH);
        this.text.textWrapping = expetedLines > 1;
        if (expetedLines > 1) {
            this.text.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        } else {
            this.text.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
        }
        return;
        const adjustedFont = 0.01;
        const { lines } = this.text;
        if (lines.length < expetedLines) {
            this.text.fontSizeInPixels *= (1 + adjustedFont);
        } else if (lines.length > expetedLines) {
            this.text.fontSizeInPixels *= (1 - adjustedFont);
        }
    }

    private checkLineSpacing() {
        const { lines, heightInPixels } = this.text;
        if (lines) {
            const linesNumber = lines.length + 1;
            const textHeight = this.text.computeExpectedHeight();
            const diffHeight = heightInPixels - textHeight;
            const lineSpacingChange = Math.round(diffHeight / linesNumber);
            this.text.lineSpacing = parseInt(this.text.lineSpacing) + lineSpacingChange;
        }
    }

    private currentFontSize = 0;

    private setText(textOptions: TextOptions) {
        const { text, fontSize, outlineWidth } = textOptions;

        //! Prevent fontSize to be always updated but only when it really changes
        if (Math.abs(fontSize - this.currentFontSize) > 0.5) {
            this.text.fontSizeInPixels = (fontSize * this.fontRatio) - outlineWidth;
            this.currentFontSize = fontSize;
        }

        if (text) {
            const lines = text.split('\n');
            if (!lines[lines.length - 1]) lines.pop();
            const withoutEmptyLastLines = lines.join('\n');
            this.text.text = withoutEmptyLastLines;

            // Avoid break line if only one line is present
            this.system.addOnce(() => {
                this.getExpetedLines(textOptions);
                this.checkLineSpacing();
                // this.forceRefresh();
            });
        } else {
            this.text.text = '';
        }
    }

    private setColor(textOptions: TextOptions) {
        this.text.color = textOptions.color;
        this.container.background = textOptions.backgroundColor;
        // For test purpose
        // this.container.background = 'rgb(255,0,0, 1)';
        // this.advancedTexture.background = 'rgb(0,255,0, 1)';
    }

    private fontRatio = 1.63;

    private setFont(textOptions: TextOptions) {
        const {
            fontWeight, fontFamily, fontStyle, textDecoration, textAlign
        } = textOptions;
        this.text.fontWeight = fontWeight;
        this.text.fontFamily = fontFamily;
        this.text.fontStyle = fontStyle;
        this.text.underline = (textDecoration === 'underline');

        // Possibility to add stoke through
        // https://forum.babylonjs.com/t/adding-underline-line-through-to-textblock/13634/7

        // By default text is aligned left in HTML
        let babylonTextAlign = Control.HORIZONTAL_ALIGNMENT_LEFT;
        if (textAlign === 'start') babylonTextAlign = Control.HORIZONTAL_ALIGNMENT_LEFT;
        if (textAlign === 'end') babylonTextAlign = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        if (textAlign === 'center') babylonTextAlign = Control.HORIZONTAL_ALIGNMENT_CENTER;
        this.text.textHorizontalAlignment = babylonTextAlign;
    }

    private setBorder(borderOptions: BorderOptions) {
        const { border, borderColor } = borderOptions;
        const thicknessAdjusted = border * this.fontRatio;
        this.container.thickness = thicknessAdjusted;
        this.container.color = borderColor;
        const extrudeShape = this.getExtrudeShapeOptions();
        const { radius } = extrudeShape;
        this.container.cornerRadius = (this.textureQuality * radius) - (thicknessAdjusted / 2);
    }

    private setOutline(textOptions: OutlineOptions) {
        const { outlineWidth, outlineColor } = textOptions;
        this.text.outlineWidth = outlineWidth * 5;
        this.text.outlineColor = outlineColor;
    }

    private setPadding(textOptions: BorderOptions) {
        const { padding } = textOptions;
        const paddingAdjusted = padding * this.fontRatio;
        this.text.paddingTopInPixels = paddingAdjusted;
        this.text.paddingBottomInPixels = paddingAdjusted;
        this.text.paddingLeftInPixels = paddingAdjusted;
        this.text.paddingRightInPixels = paddingAdjusted;
    }
}
