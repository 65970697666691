import cloneDeep from 'lodash/cloneDeep';

import { SystemEvent } from './System/systemBuild';
import { System } from './System/system';
import { MouseCatcher } from './Catchers/mouseCatcher';
import { IScroll } from './Catchers/scrollPath';
import { ScrollCatcher } from './Catchers/scrollCatcher';
import { PageBackground } from './Page/pageBackground';
import { PageCameraSection } from './Page/pageCameraSection';
import { PageCamera, IPageCamera } from './Page/pageCamera';
import { IElementBackground } from './Element/elementBackground';
import { ShaderCamera } from './Material/CameraSimple/shaderCamera';
import { ElementsManager } from './Element/elementsManager';
import { InteractionManager } from './Interaction/interactionManager';
import { DocumentsManager } from './Document/documentsManager';
import { IMenuOption, SectionMenu } from './Section/sectionMenu';
import { InteractionOptions } from './Interaction/interactionElements';
import { IShader } from './Material/Shader/shader';
import { WaterMark } from './Tools/waterMark';
import { MaskMouseTrail } from './Material/Mask/maskMouseTrail';
import { IDocumentOption } from './Document/document';
import { IAboutOption, SectionAbout } from './Section/sectionAbout';
import { FONTS_BREAKPOINTS, RESPONSIVE_BREAKPOINTS } from './Tools/toolStyleTranslation';

import './engine.sass';

export interface PageDesign {
    scroll: IScroll;
    background: IElementBackground[];
    camera: IPageCamera;
    interactions: InteractionOptions[];
    shaders: IShader[];
    documents: IDocumentOption[],
    header: string,
    menu: IMenuOption,
    about: IAboutOption,
}

export class Engine {
    public body: HTMLElement;

    public browser: HTMLElement;

    public sections: HTMLElement;

    public canvas: HTMLCanvasElement;

    public system: System;

    public mouseCatcher: MouseCatcher;

    public scrollCatcher: ScrollCatcher;

    public elementsManager: ElementsManager;

    public shaderCamera: ShaderCamera;

    public interactionManager: InteractionManager;

    public documentsManager: DocumentsManager;

    public sectionMenu: SectionMenu;

    public sectionAbout: SectionAbout;

    public pageBackground: PageBackground;

    public pageCamera: PageCamera;

    public maskMouseTrail: MaskMouseTrail;

    public waterMark: WaterMark;

    public with3D = true;

    constructor(body: HTMLElement) {
        this.body = body;
        this.body.classList.add('body');

        this.browser = this.body.querySelector('.browser');
        this.sections = this.body.querySelector('.sections');
        if (!this.browser || !this.sections) throw Error('Missing Browser or section HTMLElement');

        this.canvas = document.createElement('canvas');
        this.body.appendChild(this.canvas);

        try {
            this.system = new System(this.canvas);
            this.with3D = this.system.engine.webGLVersion === 2;
            if (this.with3D) this.startWith3D();
        } catch (e) {
            console.error('Error launching 3D: ' + e);
            this.with3D = false;
        }
    }

    private startWith3D() {
        //! Keep system settings at the beginning
        // We espacially need the htmlParent to be setted
        // this.system.freeCamera.attachControl(this.system.canvas);
        this.system.setHtmlParent(this.browser);
        // this.system.setAdaptScalingToFps(true);

        this.mouseCatcher = new MouseCatcher(this.system, this.body);
        this.scrollCatcher = new ScrollCatcher(this.system, this.browser);
        this.shaderCamera = new ShaderCamera(this.system, this.body);
        this.maskMouseTrail = new MaskMouseTrail(this.system, this.mouseCatcher);
        this.elementsManager = new ElementsManager(
            this.system,
            this.sections,
            this.mouseCatcher,
            this.scrollCatcher
        );
        this.sectionMenu = new SectionMenu(this.browser, this.shaderCamera);
        this.sectionAbout = new SectionAbout(this.browser);
        this.sectionAbout.onAbout = () => {
            this.sectionMenu.showHtml(this.sectionAbout.aboutHtml)
        }
        this.documentsManager = new DocumentsManager(
            this.system,
            this.sections,
            this.scrollCatcher,
            this.elementsManager,
            this.sectionMenu
        );
        this.interactionManager = new InteractionManager(
            this.browser,
            this.system,
            this.shaderCamera,
            this.elementsManager,
            this.documentsManager,
            this.mouseCatcher,
            this.scrollCatcher
        );

        this.pageBackground = new PageBackground(
            this.system,
            this.scrollCatcher
        );

        this.pageCamera = new PageCamera(
            this.browser,
            this.system,
            this.scrollCatcher,
            this.shaderCamera,
            this.maskMouseTrail,
            this.documentsManager
        );

        this.sectionMenu.onShow(() => {
            this.interactionManager.setMenuVisible(true);
            this.sectionAbout.footerHtml.style.opacity = '0'
        })
        this.sectionMenu.onHide(() => {
            this.interactionManager.setMenuVisible(false);
            this.sectionAbout.footerHtml.style.opacity = '1'
        })

        this.system.on(SystemEvent.Resize, () => {
            this.checkAfterResize();
        });

        //* For test purppose
        // this.addWaterMark();
    }

    private checkAfterResize() {
        this.checkResponsive();
        this.scrollCatcher.checkHeight();
        this.pageCamera.resetHeight();
        this.documentsManager.checkSectionsGeometry();
        this.maskMouseTrail.updateTargetResolution();
        this.elementsManager.checkStyle();
        this.pageBackground.updateVertices();
        this.interactionManager.refreshTriggers();
    }

    public checkResponsive() {
        const { classList } = this.browser;
        const systemWidth = this.system.width;

        // Responsive columns
        RESPONSIVE_BREAKPOINTS.forEach((rb) => {
            const { width, value } = rb;
            const breakpoint = value as string
            if (width < systemWidth) {
                classList.add('responsive-' + breakpoint);
            } else {
                classList.remove('responsive-' + breakpoint);
            }
        });

        // Responsive fonts
        FONTS_BREAKPOINTS.forEach((fb) => {
            const { width, value } = fb;
            if (width < systemWidth) {
                const pixelSize = `${value.toString()}px`;
                document.documentElement.style.fontSize = pixelSize;
            }
        });
    }

    public addWaterMark() {
        if (!this.with3D) return;
        let wat = document.querySelector("#watermark-container")
        this.waterMark = new WaterMark(wat);
    }

    public setPageDesign(pageDesign: PageDesign) {
        if (!this.with3D) {
            const pageCameraSection = new PageCameraSection(this.browser);
            const { scrollSnap } = pageDesign.camera;
            pageCameraSection.setScrollSnap(scrollSnap);
            pageCameraSection.setVisibleHTML(true);
        } else {
            //! launchRender first or we have issues with motionBlur
            this.system.launchRender();
            // Clone or values can be replaced
            const newDesign: PageDesign = cloneDeep(pageDesign);
            this.scrollCatcher.options = newDesign.scroll;
            this.elementsManager.shaders = newDesign.shaders;
            this.pageBackground.options = newDesign.background;
            //! pageCamera last or the rendering is transparent
            this.pageCamera.options = newDesign.camera;
            this.documentsManager.documentsOption = newDesign.documents;
            this.sectionAbout.options = newDesign.about;
            this.sectionMenu.options = newDesign.menu;

            this.pageCamera.updateSectionAndScroll();
            //! First get all element and then add interactions on it
            this.elementsManager.checkAll();
            this.interactionManager.options = newDesign.interactions;
            //! Check after interaction option setted
            this.interactionManager.checkElementsAttributes();
            this.interactionManager.checkPickableMeshes();
            //! Check scroll after otherwise interaction not setted properly
            this.interactionManager.checkScroll(0);
            this.documentsManager.setClickEvent();

            this.pageCamera.resetHeightAndPath();
            this.scrollCatcher.startScroll();
        }
    }

    public getPageDesign(): PageDesign {
        // Clone or values can be replaced
        return cloneDeep({
            scroll: this.scrollCatcher.options,
            background: this.pageBackground.options,
            camera: this.pageCamera.options,
            interactions: this.interactionManager.options,
            shaders: this.elementsManager.shaders,
            documents: this.documentsManager.documentsOption,
            menu: this.sectionMenu.options,
            about: this.sectionAbout.options,
        });
    }

    public getPageDesignString(): string {
        const pageDesign = this.getPageDesign();
        // Clone or values can be replaced
        return JSON.stringify(pageDesign);
    }
}

window.FuryEngine = Engine;
