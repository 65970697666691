
import { el } from 'redom';
import remove from 'lodash/remove';

import { getRandomId, stringToHtml, textToHtml } from '~services/util';
import { ElementImage } from '~src/Element/elementImage';
import { ElementText } from '~src/Element/elementText';
import { Section } from '~src/Section/section';
import { INameId, setHtmlSourceTag } from '~src/Tools/toolClasses';
import { InteractionStep } from '~src/Material/Shader/shaderInteraction';
import { Asset } from '~services/Bucket/bucketAsset';
import { SectionMenu } from '~src/Section/sectionMenu';
import { TShape } from '~src/Element/elementShape';

export interface Media extends INameId, Asset {
    html?: HTMLImageElement | HTMLVideoElement,
}

export interface IDocumentOption extends INameId {
    description?: string,
    date?: Date,
    medias?: Media[],
    thumbnail?: string,
    tags?: string[],
    softwares?: string[],
    shape?: TShape,
}

export interface IDocumentOptionSure extends INameId {
    description: string,
    date: Date,
    medias: Media[],
    thumbnail: string,
    tags: string[],
    softwares: string[],
    shape: TShape,
}

const MEDIA_CLASS = 'col-12 col-sm-6 col-md-6 col-lg-8'

const DOCUMENT_HTML_STRING = `
    <section class="fdb-block document document-text">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-10 col-md-8">
                    <h1 id="name" class="document-title">Second Action</h1>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="${MEDIA_CLASS}">
                    <img id="thumbnail" alt="image" src="https://res.cloudinary.com/dwrvacpsr/image/upload/v1617525478/no-picture_rvexjh.jpg">
                    <div id="medias"></div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                    <p id="description"></p>
                </div>
            </div>
        </div>
    </section>
`

const DOCUMENT_HTML = stringToHtml(DOCUMENT_HTML_STRING);

const hoverSteps: InteractionStep[] = [
    {
        percentage: 0,
        alpha: 0
    },
    {
        percentage: 1,
        alpha: 1
    }
]

export class Document implements INameId {

    public id: string;

    public section: Section;
    private sectionMenu: SectionMenu

    private html: HTMLElement;

    constructor(section: Section, sectionMenu: SectionMenu) {
        this.id = getRandomId();
        this.section = section;
        this.sectionMenu = sectionMenu;

        this.thumbnailShape = section.elements[0] as ElementImage;
        this.nameShape = section.elements[1] as ElementText;

        this.nameShape.setAndSaveShader({
            textureNoise: 'https://d2w9uul1fh5o24.cloudfront.net/editor/scrollImages/v2/512_webp/noise_003.webp',
            textureFrequency: 0.2,
            filters: [{
                "type": "alpha",
                "intensity": 1
            }],
        })

        this.html = DOCUMENT_HTML.cloneNode(true);
        this.thumbnailHtml = this.html.querySelector("#thumbnail");
        this.mediasHtml = this.html.querySelector("#medias");
        this.nameHtml = this.html.querySelector("#name");
        this.descriptionHtml = this.html.querySelector("#description");
    }

    private _options: IDocumentOptionSure = {
        id: getRandomId(),
        name: '',
        description: '',
        date: new Date(),
        medias: [],
        thumbnail: '',
        shape: 'Card',
        tags: [],
        softwares: [],
    }

    public set options(v: IDocumentOption) {
        if (v.name !== undefined) this.name = v.name
        if (v.thumbnail !== undefined) this.thumbnail = v.thumbnail
        if (v.description !== undefined) this.description = v.description
        if (v.medias !== undefined) this.medias = v.medias
        if (v.shape !== undefined) this.shape = v.shape
    }

    public get options(): IDocumentOptionSure {
        return { ...this._options };
    }

    private nameHtml: HTMLElement;
    public nameShape: ElementText;

    public set name(v: string) {
        this._options.name = v;
        this.nameHtml.innerHTML = textToHtml(v);
        this.nameShape.html.innerHTML = textToHtml(v);
    }

    public get name(): string {
        return this._options.name
    }

    private thumbnailHtml: HTMLImageElement;
    public thumbnailShape: ElementImage;

    public set thumbnail(v: string) {
        this._options.thumbnail = v;
        this.thumbnailHtml = setHtmlSourceTag(this.thumbnailHtml, v);
    }

    public get thumbnail(): string {
        return this._options.thumbnail
    }

    public set shape(v: TShape) {
        this._options.shape = v;
        this.thumbnailShape.setCardShape(v);
    }

    public get shape(): TShape {
        return this._options.shape
    }

    private descriptionHtml: HTMLElement;
    public set description(v: string) {
        this._options.description = v;
        this.descriptionHtml.innerHTML = textToHtml(v);
        const mediaWidth = (v) ? MEDIA_CLASS : 'col-12'
        this.mediasHtml.parentNode.className = mediaWidth;
    }

    public get description(): string {
        return this._options.description
    }

    public set medias(v: Media[]) {
        v.forEach((m) => {
            this.addMedia(m)
        })
    }

    public get medias(): Media[] {
        return [...this._options.medias]
    }

    private mediasHtml: HTMLImageElement;
    public addMedia(media: Media): Media[] {
        let mediaHtml = el('img')
        this.mediasHtml.appendChild(mediaHtml);
        mediaHtml = setHtmlSourceTag(mediaHtml, media.url);
        media.html = mediaHtml;
        this._options.medias.push(media);
        return this.medias;
    }

    public addMediaUrl(url: string): Media[] {
        let media = {
            id: getRandomId(),
            name: url,
            url,
        }
        return this.addMedia(media)
    }

    public deleteMedia(media: Media): Media[] {
        let removedMedia = remove(this._options.medias, (m) => media.id === m.id)[0];
        if (removedMedia) this.mediasHtml.removeChild(removedMedia.html)
        return this.medias;
    }

    public show() {
        this.sectionMenu.showHtml(this.html)
    }
}
