#include<NoiseTechniques>

precision highp float;
varying vec2 vUV;

// MASK
// uniform float time;
uniform float noiseIntensity;
uniform float noiseSpeed;
uniform float hardwareScaling;
uniform vec2 offset;
uniform sampler2D textureSampler;
uniform sampler2D textureNoise;
uniform float textureFrequency;

vec2 getTextureNoiseUV() {
    // Mask Static
    vec2 centeredUV = vUV * 2.0 - 1.0;
    vec2 noiseUV = centeredUV + offset;

    // textureSize not working on safari
    ivec2 textureSize2d = textureSize(textureSampler,0);
    float textureX = float(textureSize2d.x);
    float textureY = float(textureSize2d.y);
    noiseUV.x *= textureX/textureY;

    float maxTexture = max(textureX, textureY);
    noiseUV *= maxTexture;
    noiseUV *= 1.0/10000.0;

    // Texture Frequency
    float minFrequency = max(textureFrequency, 0.1);
    float scale = 50.0 * minFrequency * hardwareScaling;
    // Scale from center
    noiseUV = (noiseUV * scale) * 0.5 - 0.5;
    return noiseUV;
}

vec3 getNoiseUVAndMask() {
    // Mask Static
    vec2 noiseUV = getTextureNoiseUV();

    // Move effect
    if (noiseIntensity != 0.0) {
        float noiseIntensityRatio = .1 * noiseIntensity;
        float noiseSize = 5.;
        vec3 pos = vec3(noiseUV / noiseSize, time * noiseSpeed * 0.1);
        float sinNoise = sin_noise(pos);
        sinNoise *= noiseIntensityRatio;
        noiseUV.x += sinNoise - noiseIntensityRatio;
        noiseUV.y -= sinNoise - noiseIntensityRatio;
    }

    vec4 noiseImage = texture2D(textureNoise, noiseUV);
    float noiseWithMask = noiseImage.r;
    return vec3(noiseUV, noiseWithMask);
}
