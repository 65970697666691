import { Vector2 } from '@babylonjs/core/Maths/math';
import { RenderTargetTexture } from '@babylonjs/core/Materials/Textures/renderTargetTexture';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { Effect } from '@babylonjs/core/Materials/effect';

import { System } from '~src/System/system';
import { Timeline } from '~src/System/systemTimeline';
import { MouseCatcher, NakerMouseEvent } from '~src/Catchers/mouseCatcher';
import { Mask } from './mask';

import mouseTrailShader from './maskMouseTrail.glsl';

Effect.ShadersStore.mouseTrailFragmentShader = mouseTrailShader;

export interface IMaskMouseTrail {
    shapeTexture?: string
    size?: number
    trail?: number
    noiseIntensity?: number
    noiseSpeed?: number
}

export const MOUSETRAIL_KEYS = [
    'size',
    'trail',
    'noiseIntensity',
    'noiseSpeed',
];

//! https://forum.babylonjs.com/t/shadertoy-using-buffera-as-input-to-buffer-a/27681/21
//! Playground: https://playground.babylonjs.com/#4C900K#45
export class MaskMouseTrail extends Mask {
    public shapeTexture: Texture;

    private rttA: RenderTargetTexture;

    private rttB: RenderTargetTexture;

    private animation: Timeline;

    private mouseCatcher: MouseCatcher;

    constructor(system: System, mouseCatcher: MouseCatcher) {
        super(system, mouseTrailShader, ['iChannel0', 'shapeTexture'], MOUSETRAIL_KEYS);
        this.mouseCatcher = mouseCatcher;

        // Create the two textures to write to
        this.rttA = this.getRenderTargetTexture();
        this.rttB = this.getRenderTargetTexture();

        let pingpong = 0;
        this.texture = pingpong ? this.rttA : this.rttB;

        this.animation = new Timeline(system, 'mouseTrail', { ease: 'power0' });

        let time = 0;
        // Feed the last frame into the Wrapper being rendered this frame
        this.eWrapper.onApplyObservable.add(() => {
            this.eWrapper.effect.setVector2('mouse', this.mouse);
            this.eWrapper.effect.setTexture('iChannel0', pingpong ? this.rttB : this.rttA);
            this.eWrapper.effect.setTexture('shapeTexture', this.shapeTexture);
            this.eWrapper.effect.setFloat('time', time++);
        });

        system.scene.onBeforeCameraRenderObservable.add(() => {
            if (this.isVisible()) {
                this.texture = pingpong ? this.rttA : this.rttB;
                this.renderer.render(this.eWrapper, this.texture);
                pingpong ^= 1;
            }
        });

        system.setMouseTrailTexture(this.texture);
        //* To make sure we always have a texture or else it creates issues in shader
        this.options = { shapeTexture: 'https://d2w9uul1fh5o24.cloudfront.net/editor/particles/v0/basic_512/basic_1.png' };
        this.refreshResolution();
        // this.usePostProcess();
    }

    private mouse = Vector2.Zero();

    private manageMove(vec: Vector2) {
        this.mouse = vec.clone();
        //! Only to make sure rendering continue until trail disappear
        const duration = this._options.trail * 3;
        this.animation.simple(duration, () => { });
    }

    public updateTargetResolution() {
        if (this.isVisible()) {
            this.updateTargetTextureResolution(this.rttA);
            this.updateTargetTextureResolution(this.rttB);
            this.refreshResolution();
            this.renderer.render(this.eWrapper, this.texture);
        }
    }

    private isVisible(): boolean {
        return this._options.size !== 0 && this._options.size !== undefined;
    }

    private checkMouseCatchNeeded() {
        if (this.isVisible()) {
            this.mouseCatcher.on(NakerMouseEvent.FOLLOW, this.manageMove, this);
        } else {
            this.mouseCatcher.off(NakerMouseEvent.FOLLOW, this.manageMove);
        }
    }

    private _options: IMaskMouseTrail = {};

    public get options(): IMaskMouseTrail {
        return { ...this._options };
    }

    public set options(v: IMaskMouseTrail) {
        if (!v) return;
        const { effect } = this.eWrapper;
        //* We can't have MouseTrail on Mobile
        if (this.system.isOnMobile) v.size = 0;
        this.eWrapper.onApplyObservable.addOnce(() => {
            MOUSETRAIL_KEYS.forEach((key) => {
                if (v[key] !== undefined) {
                    effect.setFloat(key, v[key]);
                    this._options[key] = v[key];
                }
            });
            if (v.shapeTexture) {
                this.shapeTexture = this.system.getTexture(v.shapeTexture, null, true);
                this._options.shapeTexture = v.shapeTexture;
            }
            // Needed to make sure it iis updated in editor
            if (this._options.size === 0 && v.size !== 0) {
                this.updateTargetResolution();
            }
            this.checkMouseCatchNeeded();
        });

        this.renderer.render(this.eWrapper, this.texture);
    }
}
