import { Path3D, Vector3 } from '@babylonjs/core/Maths/math';
import { IVector3Like } from '@babylonjs/core/Maths/math.like';
import gsap from 'gsap';

import { RESPONSIVE_BREAKPOINT_SM } from '../Tools/toolStyleTranslation';
import { Observable } from '../Tools/observable';
import { System } from '../System/system';
import { GeometryVector } from '~src/Node/nodePosition';
import {
    ScrollPathLinear, EScrollPath, getScrollPathFromEnum
} from './scrollPathFunction';
import { PARTICLE_LINES, PARTICLE_LINES_LENGTH } from '~src/Element/elementLayerPath';

export enum ScrollEvent {
    Alignment,
    Progress,
    Start,
}

export interface IScroll {
    catchSpeed?: number,
    orientation?: IVector3Like,
    followOrientation?: boolean,
    path?: EScrollPath,
}

export const DEFAULTSCROLLOPTIONS: IScroll = {
    catchSpeed: 1,
    orientation: { x: 0, y: 0, z: 0 },
    followOrientation: false,
    path: EScrollPath.LINEAR,
};

const PARTICLE_START = 30;

const EMPTY_GEOMETRY: GeometryVector = { position: Vector3.Zero(), rotation: Vector3.Zero() };

export class ScrollPath extends Observable<ScrollEvent, number> {
    protected system: System;

    protected browser: HTMLElement;

    private scrollingElement: Element;

    private ease: gsap.EaseFunction;

    constructor(system: System, browser: HTMLElement) {
        super('Page Scroll');
        this.system = system;
        this.browser = browser;

        const easeString = 'power1.inOut';
        this.ease = gsap.parseEase(easeString);
    }

    protected _options: IScroll = DEFAULTSCROLLOPTIONS;

    protected scrollHeight: number;

    public checkHeight(): number {
        if (this.browser === document.body) this.scrollingElement = document.scrollingElement;
        else this.scrollingElement = this.browser;
        const browserHeight = this.scrollingElement.clientHeight;
        this.scrollHeight = this.scrollingElement.scrollHeight - browserHeight;
        return this.scrollHeight;
    }

    protected getScrollPercentage() {
        const top = this.scrollingElement.scrollTop;
        return (this.scrollHeight) ? top / this.scrollHeight : 0;
    }

    private _fullPath: Path3D;

    public get fullpath(): Path3D {
        return this._fullPath;
    }

    protected updateFullPath(doNotUpdatePath?: boolean) {
        const pathLength = this.getPathLength();
        const newPath3D = [];
        const start = -PARTICLE_START;
        //* PARTICLE_LINES as the maximum possible
        const end = (pathLength + PARTICLE_LINES) * PARTICLE_LINES_LENGTH;
        for (let i = start; i <= end; i++) {
            const geo = this.getScrollPath(i);
            newPath3D.push(geo.position);
        }
        this._fullPath = new Path3D(newPath3D);
        if (!doNotUpdatePath) this.notify(ScrollEvent.Alignment, 0);
    }

    private scrollPathFunction = ScrollPathLinear;

    public _setPath(path: EScrollPath) {
        const scrollPath = getScrollPathFromEnum(path);
        this.scrollPathFunction = scrollPath.fn;
        this.updateFullPath();
    }

    private sections = 1;

    private pathLength = 1;

    public setPathLength(length: number, sections: number, doNotUpdatePath?: boolean) {
        this.pathLength = length;
        this.sections = sections || 1;
        this.updateFullPath(doNotUpdatePath);
    }

    private calculatedPathLength = 100;

    protected getPathLength(): number {
        let responsivePathLength = this.pathLength;
        // If on mobile, we can't be smaller than html
        const { width } = this.system;
        if (width < RESPONSIVE_BREAKPOINT_SM) {
            responsivePathLength = Math.max(this.pathLength, 1);
        }
        //! Hard to manage change on path length as it makes layers bug
        // if (this.alignment !== EScrollAlignment.VERTICAL) {
        //     responsivePathLength *= 2;
        // }
        const totalLength = responsivePathLength * this.sections;
        //! * 8 so that it matches with section position
        this.calculatedPathLength = Math.ceil(totalLength * 8);
        return this.calculatedPathLength;
    }

    public scrollGeometry: GeometryVector = EMPTY_GEOMETRY;

    //! Needed for background layers positionning
    public progressInUnit = 0;

    public setGeometryFromPercentage(perc: number) {
        this.progressInUnit = perc * this.calculatedPathLength;
        this.scrollGeometry = this.getScrollPahtWithOrientation(this.progressInUnit);
    }

    protected orientationVector = Vector3.Zero()

    private getScrollPahtWithOrientation(unit: number, withOrientation = true): GeometryVector {
        const scrollPath = this.getScrollPath(unit);
        const { position, rotation } = scrollPath;
        if (withOrientation) rotation?.addInPlace(this.orientationVector)
        return { position, rotation };
    }

    private getScrollPath(unit: number): GeometryVector {
        return this.scrollPathFunction(unit);
    }

    public getGeometryFromPercentage(percentage): GeometryVector {
        const unit = percentage * this.calculatedPathLength;
        return this.getScrollPahtWithOrientation(unit, this._options.followOrientation);
    }
}
