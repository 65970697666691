import { stringToHtml } from '~services/util';

const WATERMARK_HTML_STRING = `
    <div class="watermark">
        <img class="watermark-logo" src="https://d2w9uul1fh5o24.cloudfront.net/editor/main/logo-font.png"/>
        <p class="watermark-text">Made with FuryPage</p>
    </div>
`
const WATERMARK_HTML = stringToHtml(WATERMARK_HTML_STRING);

export class WaterMark {
    constructor(parent: HTMLElement) {
        parent.appendChild(WATERMARK_HTML);
        WATERMARK_HTML.addEventListener('click', () => {
            window.open('https://fury-page.vercel.app/', '_blank');
        });
    }
}
