import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { Effect } from '@babylonjs/core/Materials/effect';
import { Vector2, Vector4 } from '@babylonjs/core/Maths/math';

import ColorMappingShader from './colorMapping.glsl';
import PositionShader from './position.glsl';
import AlphaShader from './alpha.glsl';
import MaskTextureShader from '../Mask/maskTexture.glsl';
import NoiseTechniquesShader from '../Noise/noiseTechniques.glsl';
import FilterShader from './simplefilter.glsl';

import { NodeInteraction } from '../../Node/nodeInteraction';
import { SystemEvent } from '~src/System/systemBuild';
import { System } from '~src/System/system';
import { Geometry, Point4 } from '~src/Node/nodePosition';

Effect.IncludesShadersStore.ColorMapping = ColorMappingShader;
Effect.IncludesShadersStore.Position = PositionShader;
Effect.IncludesShadersStore.Alpha = AlphaShader;
Effect.IncludesShadersStore.NoiseTechniques = NoiseTechniquesShader;
Effect.IncludesShadersStore.MaskTexture = MaskTextureShader;
Effect.IncludesShadersStore.Filter = FilterShader;

export type TFilter = 'none' | 'alpha' | 'chromatic' | 'blur' | 'pixel' | 'ripple' | 'fisheye' | 'grain' | 'amplitude' | 'zoom'

export interface IFilter {
    intensity: number,
    type: TFilter
}

export type IFilters = IFilter[]

export interface IShaderColor {
    shaderColor1?: number[]
    shaderColor2?: number[]
}

export interface IShaderFilter extends Geometry, IShaderColor {
    filters?: IFilters
}

export interface IShaderMask {
    textureNoise?: string
    textureFrequency?: number
    // Noise
    noiseIntensity?: number
    noiseSpeed?: number
    // Mask Vec
    textureOffsetTime?: Point4
    mouseTrailOffsetTime?: Point4
}

export interface IShader extends IShaderFilter, IShaderMask {
    wireframe?: boolean
    isLayer?: number
    name?: string
}

export const SHADER_COLOR_KEYS = [
    'shaderColor1',
    'shaderColor2'
];

export const SHADER_FILTER_KEYS = [
    'alpha',
    'chromatic',
    'blur',
    'pixel',
    'zoom',
    'grain',
    'invert',
    // 'fisheye',
    // 'ripple',
    // 'glass',
];

export const SHADER_NOISE_KEYS = [
    'noiseIntensity',
    'noiseSpeed',
];

export const SHADER_INTERACTION_KEYS = SHADER_FILTER_KEYS.concat(
    SHADER_COLOR_KEYS
);

export const SHADER_RESOLUTION_UNIFORMS = [
    'time',
    'mouse',
    'hardwareScaling',
    'resolution',
];

export const SHADER_MASK_VEC4_KEYS = [
    'textureOffsetTime',
    'mouseTrailOffsetTime'
];

export const SHADER_MASK_KEYS = SHADER_MASK_VEC4_KEYS.concat(SHADER_NOISE_KEYS, [
    'textureNoise',
    'textureFrequency',
]);

export const SHADER_OPTION_UNIFORMS = [
    'isLayer',
    'offset',
    'visibility', // Used for camera fadeout when mesh is too close
    'effectScale',
];

//! Avoid putting uniforms that are not used by shader
export const BABYLONJS_UNIFORMS = ['world', 'view', 'worldViewProjection'];
export const SHADER_UNIFORMS = BABYLONJS_UNIFORMS.concat(
    SHADER_RESOLUTION_UNIFORMS,
    SHADER_OPTION_UNIFORMS,
    SHADER_INTERACTION_KEYS,
    SHADER_MASK_KEYS
);

export const SHADER_SAMPLERS = ['textureSampler', 'textureNoise', 'textureMouseTrail'];

export const GEOMETRY_KEYS = [
    'position',
    'rotation',
    'scaling',
];

export abstract class Shader extends NodeInteraction {
    protected isReady = false;

    constructor(system: System, html: HTMLElement) {
        super(system, html);
        this.system.on(SystemEvent.Resize, () => {
            if (this.isReady) this.checkResolution();
        });

        // Otherwise trigger event
        this.system.on(SystemEvent.MouseTrailTexture, () => {
            this.setTextureMouseTrail(system.textureMouseTrail);
        });

        let time = 0;
        this.system.scene.registerAfterRender(() => {
            if (this.isReady) this.setShaderValue('time', time++);
        });
    }

    protected checkResolution() {
        this.setShaderValue('hardwareScaling', this.system.engine.getHardwareScalingLevel());
        this.setShaderVector2('resolution', new Vector2(this.system.width, this.system.canvas.height));
    }

    protected setShaderValues(shaderValues: IShader) {
        const shaderKeys = Object.keys(shaderValues);
        shaderKeys.forEach((key) => {
            const value = shaderValues[key];
            this.setShaderValue(key, value);
        });
    }

    public textureSamplerUrl: string;

    protected textureSampler: Texture;

    public setTextureSamplerUrl(url: string, callback?: () => void) {
        if (url && this.textureSamplerUrl !== url) {
            this.textureSamplerUrl = url;
            let t = this.system.getTexture(url, (t) => {
                if (url === this.textureSamplerUrl) {
                    this.textureSampler = t;
                }
                if (callback) callback();
            }, true);
            this.setTextureSampler(t);
        }
    }

    protected abstract setTextureMouseTrail(texture: Texture): void

    protected abstract setTextureSampler(texture: Texture): void

    public textureNoiseUrl: string;

    protected textureNoise: Texture;

    protected setTextureNoiseUrl(url: string, callback?: () => void) {
        if (url && this.textureNoiseUrl !== url) {
            this.textureNoiseUrl = url;
            this.system.getTexture(url, (t) => {
                this.textureNoise = t;
                this.setTextureNoise(t);
                if (callback) callback();
            }, true);
        } else if (this.textureNoise) {
            this.setTextureNoise(this.textureNoise);
        }
    }

    protected abstract setTextureNoise(texture: Texture): void

    protected abstract setTextureFrequency(frequency: number): void

    protected abstract setShaderVector2(option: string, vector: Vector2): void

    protected abstract setShaderVector4(option: string, vector: Vector4): void

    protected abstract setShaderValue(option: string, value: number): void

    protected abstract setShaderColor(filter: string, color: number[]): void
}
