import jss, { Styles, JssStyle, StyleSheet } from 'jss';
import global from 'jss-plugin-global';
import nested from 'jss-plugin-nested';
import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import isObject from 'lodash/isObject';
import find from 'lodash/find';
import { el } from 'redom';

import { IMAGE_TAG, VIDEO_TAG } from '~src/Element/elementImage';
import {
    HEADING_TAG, TEXT_TAG, BUTTON_TAG, TextOptions
} from '~src/Element/elementText';
import { CSS_TO_FURY, furyToCss, styleToFury } from './toolStyleTranslation';
import { EMPTY_IMAGE_LINK } from '~services/type';
import { htmlToString, isImageFile, isVideoFile } from '~services/util';

export type DefaultType =
    typeof TEXT_TAG |
    typeof HEADING_TAG |
    typeof BUTTON_TAG |
    typeof IMAGE_TAG |
    typeof VIDEO_TAG;

export interface DefaultClass extends INameId {
    id: DefaultType,
    attributes: Object,
    name: string,
    notDeletable: boolean,
}

export const DEFAULT_CLASSES: DefaultClass[] = [
    {
        id: TEXT_TAG,
        name: 'Text',
        notDeletable: true,
        attributes: { class: TEXT_TAG }
    },
    {
        id: HEADING_TAG,
        name: 'Heading',
        notDeletable: true,
        attributes: { class: HEADING_TAG }
    },
    {
        id: BUTTON_TAG,
        name: 'Button',
        notDeletable: true,
        attributes: { class: BUTTON_TAG }
    },
    {
        id: IMAGE_TAG,
        name: 'Image / Video',
        notDeletable: true,
        attributes: { src: EMPTY_IMAGE_LINK },
    }
];

const DEFAULT_CLASSES_CLONABLE = [...DEFAULT_CLASSES];
DEFAULT_CLASSES_CLONABLE.push(
    {
        id: VIDEO_TAG,
        name: 'Video',
        notDeletable: true,
        attributes: { src: EMPTY_IMAGE_LINK, autoplay: true, loop: true, muted: true, crossorigin: "anonymous", alt: true, draggable: "false" }
    },
)

export const getDefaultElement = (type: DefaultType): HTMLElement => {
    const content = find(DEFAULT_CLASSES_CLONABLE, (c) => c.id === type);
    const newHTML = el(type, content.attributes);
    //! Needed or video won't autoplay
    if (type === 'video') newHTML.muted = true
    return newHTML;
}

export const getDefaultElementFromUrl = (url: string): string => {
    const TAG = (isVideoFile(url)) ? VIDEO_TAG : IMAGE_TAG;
    const html = getDefaultElement(TAG);
    html.src = url;
    return htmlToString(html);
}

const setMediaHtmlTag = (html: HTMLElement, type: DefaultType): HTMLElement => {
    const newHTML = getDefaultElement(type);
    html.parentNode.replaceChild(newHTML, html);
    return newHTML
}

export const setHtmlSourceTag = (html: HTMLElement, url: string): HTMLElement => {
    const { src } = html;
    let newHtml = html;
    if (isVideoFile(url) && !isVideoFile(src)) {
        newHtml = setMediaHtmlTag(html, VIDEO_TAG);
    }
    if (isImageFile(url) && !isImageFile(src)) {
        newHtml = setMediaHtmlTag(html, IMAGE_TAG);
    }
    newHtml.src = url;
    return newHtml;
}

const PAGE_TAG_NAMES = ['header', 'footer'];

export const isHeadingTag = (tag: string): boolean => (HEADING_TAG === tag.toLowerCase());
export const isTextTag = (tag: string): boolean => (TEXT_TAG === tag.toLowerCase());
export const isButtonTag = (tag: string): boolean => (BUTTON_TAG === tag.toLowerCase());
export const isHeadingTextButtonTag = (tag: string): boolean => isHeadingTag(tag) || isTextTag(tag) || isButtonTag(tag);
export const isImageTag = (tag: string): boolean => (IMAGE_TAG === tag.toLowerCase());
export const isVideoTag = (tag: string): boolean => (VIDEO_TAG === tag.toLowerCase());
export const isImageOrVideoTag = (tag: string): boolean => (isVideoTag(tag) || isImageTag(tag));

export const isGlobalTag = (tag: string): boolean => (
    isHeadingTextButtonTag(tag)
    || isImageOrVideoTag(tag)
    || PAGE_TAG_NAMES.includes(tag.toLowerCase())
);

const NEXT_STRING = '& .';

export interface IName {
    name: string,
}

export interface INameId extends IName {
    id: string,
}

export class ToolClasses {
    protected styles: Styles = {};

    constructor() {
        // Avoid class name change when building stylesheet
        const createGenerateId = () => (rule, sheet) => `${rule.key}`;
        jss.setup({ createGenerateId });
        jss.use(global());
        //! Nested is not working with font-face
        jss.use(nested());
    }

    public setStyles(styles: Styles) {
        const stylesKeys = Object.keys(styles);
        stylesKeys.forEach((key) => {
            this.styles[key] = styles[key];
        });

        DEFAULT_CLASSES.forEach((c) => {
            if (!this.styles[NEXT_STRING + c.id]) this.styles[NEXT_STRING + c.id] = {};
            this.styles[NEXT_STRING + c.id].name = c.name;
        });

        PAGE_TAG_NAMES.forEach((t) => {
            if (!this.styles[NEXT_STRING + t]) this.styles[NEXT_STRING + t] = {};
            this.styles[NEXT_STRING + t].name = 'Header/Footer';
        });

        this.updateStyleSheet();
    }

    public getStyles(): Styles {
        return cloneDeep(this.styles);
    }

    public addClass(): INameId {
        let i = 0;
        while (this.styles[`${NEXT_STRING}style${i}`]) {
            i++;
        }
        const name = `New style ${i}`;
        const id = `style${i}`;

        this.setStyle(id, { name });
        return { id, name };
    }

    public renameClass(selector: string, newName: string): void {
        this.styles[`${NEXT_STRING}${selector}`].name = newName;
    }

    public getClassName(selector: string): string {
        // As this function is used for interaction name
        if (selector === 'camera') return 'Camera';
        const clas = this.styles[`${NEXT_STRING}${selector}`];
        return (clas) ? clas.name : selector;
    }

    public removeClass(selector: string): void {
        delete this.styles[`${NEXT_STRING}${selector}`];
        this.updateStyleSheet();
    }

    public getClassBySelector(classList: DOMTokenList): INameId {
        let clas = null;
        const keys = Object.keys(this.styles);
        keys.forEach((key) => {
            if (key.includes(NEXT_STRING)) {
                const styleSelector = key.replace(NEXT_STRING, '');
                classList.forEach((c) => {
                    if (c === styleSelector) {
                        const style = this.styles[key];
                        clas = { id: styleSelector, name: style.name };
                    }
                });
            }
        });
        return clas;
    }

    public getClasses(tagName: string): INameId[] {
        const keys = Object.keys(this.styles);
        const classNames = [];
        if (isGlobalTag(tagName)) {
            const globalClass = find(DEFAULT_CLASSES, (c) => c.id === tagName.toLowerCase());
            if (globalClass) classNames.push(globalClass);
        }
        keys.forEach((key) => {
            if (key.includes(NEXT_STRING)) {
                const selector = key.replace(NEXT_STRING, '');
                const style = this.styles[key];
                if (style && style.name && !isGlobalTag(selector)) {
                    classNames.push({ id: selector, name: style.name });
                }
            }
        });
        return classNames;
    }

    public getStylesString(): string {
        return this.currentSheet.renderer.element.innerHTML;
    }

    public setStyle(selector: string, style: JssStyle) {
        if (selector === 'body') {
            const styleKeys = Object.keys(style);
            styleKeys.forEach((key) => {
                this.styles[key] = style[key];
            });
        } else {
            this.styles[`${NEXT_STRING}${selector}`] = style;
        }
        this.updateStyleSheet();
    }

    public getStyle(selector: string): JssStyle {
        if (selector === 'body') {
            return cloneDeep(this.styles);
        }
        return cloneDeep(this.styles[`${NEXT_STRING}${selector}`]);
    }

    public setStyleProperty(selector: string, style: string, value: string) {
        if (selector === 'body') {
            this.styles[style.toLowerCase()] = value;
        } else {
            selector = `${NEXT_STRING}${selector}`;
            if (!this.styles[selector]) this.styles[selector] = {};
            this.styles[selector][style.toLowerCase()] = value;
        }
        this.updateStyleSheet();
    }

    public setFuryStyle(selector: string, style: TextOptions) {
        const keys = Object.keys(style);
        keys.forEach((name) => {
            const cf = find(CSS_TO_FURY, (f) => f.fury === name);
            if (!cf) {
                console.error(`Bad fury style name ${name}`);
            } else {
                const cssValue = furyToCss(cf.fury, style[name]);
                this.setStyleProperty(selector, cf.css, cssValue);
            }
        });
    }

    public getFuryStyle(selector: string): TextOptions {
        const selectorStyle = this.getStyle(selector);
        if (selectorStyle) {
            return styleToFury(selectorStyle) as TextOptions;
        }
        return {};
    }

    private currentSheet: StyleSheet;

    protected fontFaces = [];

    protected updateStyleSheet() {
        if (this.currentSheet) this.currentSheet.detach();
        this.currentSheet = jss.createStyleSheet({ body: this.styles, '@font-face': this.fontFaces }).attach();
    }

    private mapValuesDeep(v: Object, callback: (obj: Object) => void): Object {
        return isObject(v)
            ? mapValues(v, (v) => this.mapValuesDeep(v, callback))
            : callback(v);
    }
}
