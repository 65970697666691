import { System } from '../System/system';
import { IShader } from '../Material/Shader/shader';
import {
    DEFAULT_LAYER, ElementLayer, ILayer
} from './elementLayer';
import { WHITE_NOISE } from '~src/Material/Shader/shaderSave';
import { ScrollPath } from '~src/Catchers/scrollPath';

export interface IElementBackground {
    layer: ILayer;
    shader: IShader
}

export const DEFAULT_ELEMENT_LAYER: IElementBackground = {
    layer: { ...DEFAULT_LAYER },
    shader: {
        //! Do not import TEXTURE_NOISE from bucketAsset or viewer build is heavy
        // textureNoise: TEXTURE_NOISE[0].url,
        textureNoise: WHITE_NOISE,
        textureFrequency: 0.1,
        filters: [],
        // noiseIntensity: 0,
        // noiseSpeed: 0,
        shaderColor1: [0, 0.5, 1],
        shaderColor2: [0, 0.5, 0],
    }
};

export class ElementBackground extends ElementLayer {
    constructor(system: System, scrollPath: ScrollPath) {
        super(system, scrollPath);
        this.setShaderValue('isLayer', 1);
    }

    public name: string;

    public updateName() {
        const layerOp = this.layer;
        this.name = `${layerOp.path} with ${layerOp.shape}`;
    }

    public set options(v: IElementBackground) {
        this.layer = v.layer;
        this.setBackShader(v.shader);
        this.updateName();
    }

    public get options(): IElementBackground {
        return {
            layer: this.layer,
            shader: this.shader
        };
    }

    public setBackShader(shader = this.shader) {
        this.setAndSaveShader(shader);
        this.setTextureUrl(shader.textureNoise);
        this.setWireframe(shader.wireframe);
    }

    public setLayerOption(option: string, value) {
        this._layer[option] = value;
        this.layer = this._layer;
        this.system.quickRender(2000);

    }

    public set layer(v: ILayer) {
        this._layer = v;
        this.setLayerAndRebuild();
        this.updateName();
    }

    public get layer(): ILayer {
        return { ...this._layer };
    }

    private setTextureUrl(textureNoise = this.shader.textureNoise) {
        this.setTextureSamplerUrl(textureNoise, () => {
            // Otherwise we don't see the change in editor
            this.system.scene.render();
        });
    }

    public setWireframe(wireframe: boolean) {
        this.shader.wireframe = wireframe;
        this.shaderMaterial.wireframe = wireframe;
    }
}
