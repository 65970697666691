import remove from 'lodash/remove';
import { SystemResponsive } from './systemResponsive';

export class SystemAnimation extends SystemResponsive {
    /**
    * List of all process which need rendering
    * Allow to have engine stop if nothing need rendering
    * Thus improving performance
    */
    private list: Array<string> = [];

    /**
    * Add a rendering process
    */
    public addAnimation(animation: string) {
        if (this.list.indexOf(animation) === -1) {
            // console.log('add', animation);
            this.list.push(animation);
            this.checkStartRender();
        }
    }

    /**
    * @ignore
    */
    private needProcess = true;

    /**
    * @ignore
    */
    public setNeedProcess(needProcess: boolean) {
        this.needProcess = needProcess;
        if (!needProcess && this.launched) this.startRender();
    }

    /**
     * @ignore
     */
    public checkStartRender() {
        if (this.launched) {
            if (this.list.length !== 0 || !this.needProcess) this.startRender();
            else if (this.needProcess) this.pauseRender();
        }
    }

    /**
    * Remove a rendering process
    */
    public removeAnimation(key: string) {
        remove(this.list, (a: string) => a === key);
        // console.log('remove', key);
        this.checkStopRender();
    }

    /**
    * Check if there is still a process which need renderong
    */
    public checkStopRender() {
        // console.log(this.list.length, this.needProcess);
        if (this.list.length === 0 && this.needProcess) this.pauseRender();
    }

    /**
    * Make a quick render in order to update the scene
    */
    private timeout;

    public quickRender(time?: number) {
        clearTimeout(this.timeout);
        this.startRender();
        this.timeout = setTimeout(() => {
            this.checkStopRender();
        }, time || 20);
    }

    public addOnce(callback: () => void) {
        this.scene.onAfterRenderObservable.addOnce(callback)
    }
}
