// To Enable Picking
import '@babylonjs/core/Culling/ray';
import { FreeCamera } from '@babylonjs/core/Cameras/freeCamera';
import { Vector3, Vector2, Color4 } from '@babylonjs/core/Maths/math';
import { PassPostProcess } from '@babylonjs/core/PostProcesses/passPostProcess';
import { FxaaPostProcess } from '@babylonjs/core/PostProcesses/fxaaPostProcess';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { CreatePlane } from '@babylonjs/core/Meshes/Builders/planeBuilder';
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
// import { GridMaterial } from '@babylonjs/materials/grid/gridMaterial';

import { SystemAnimation } from '../System/systemAnimation';

const setMeshVisibility = (mesh: AbstractMesh, vis: number) => {
    mesh.visibility = vis * vis;
    if (mesh.material && mesh.material.setFloat) {
        mesh.material.setFloat('visibility', vis * vis);
    }
    mesh.isVisible = mesh.isPickable = vis !== 0;
};

const MESH_FADEOUT_DIST = 5;

export class SystemCamera extends SystemAnimation {
    public freeCamera: FreeCamera;

    private cameraFixed: FreeCamera;

    private planeFree: Mesh;

    private planeFollow: Mesh;

    private planeFixed: Mesh;

    private cameraZ = -11;

    public scenePixelRatio = 92;

    constructor(canvas: HTMLCanvasElement) {
        super(canvas);

        this.scene.hoverCursor = 'pointer';
        this.scene.clearColor = new Color4(1, 1, 1, 0);

        this.freeCamera = this.buildCamera('freeCamera');
        this.freeCamera.minZ = 0;
        // this.freeCamera.minZ = MESH_FADEOUT_DIST;
        this.planeFree = this.buildPickPlane('planeFree');
        // I thought planeFree needed to be fixed but it works without that

        this.cameraFixed = this.buildCamera('cameraFixed');
        this.planeFollow = this.buildPickPlane('planeFollow');
        this.planeFollow.parent = this.freeCamera;
        this.planeFixed = this.buildPickPlane('planeFixed');
        this.planeFixed.parent = this.cameraFixed;
        // this.addHelper();

        this.scene.activeCamera = this.freeCamera;

        // On mobile devices, the pixelratio can make the canvas bigger
        if (window.devicePixelRatio > 2) this.cameraZ = -8;

        // const cube = new CubeTexture('', this.scene);
        // cube.hasAlpha = false;
        // const test = new PBRMaterial('');
        // test.alpha = 0.5;

        this.addAntiAliasing()
        this.setMeshVisibilityEvents();
        // this.useSecondCamera();
    }

    private addAntiAliasing() {
        if (this.engine.webGLVersion === 2) {
            const msaa = new PassPostProcess('MSAA', 1.0, this.freeCamera);
            msaa.samples = 4;
        } else {
            const fxaa = new FxaaPostProcess('FXAA', 1.0, this.freeCamera);
        }
    }

    private useSecondCamera() {
        this.scene.activeCamera = this.cameraFixed;
        this.cameraFixed.attachControl(this.canvas);
        this.forceRender();
        this.canvas.style.zIndex = 100000000;
    }

    private setMeshVisibilityEvents() {
        let meshesNeedControl: AbstractMesh[] = [];
        setInterval(() => {
            meshesNeedControl = [];
            const camPos = this.freeCamera.globalPosition;
            this.scene.meshes.forEach((mesh) => {
                if (mesh.needVisibility) {
                    const dist = Vector3.Distance(mesh.absolutePosition, camPos);
                    if (dist < 10) {
                        meshesNeedControl.push(mesh);
                    } else {
                        setMeshVisibility(mesh, 1);
                    }
                }
            });
        }, 500);

        this.scene.registerBeforeRender(() => {
            const camPos = this.freeCamera.globalPosition;
            meshesNeedControl.forEach((mesh) => {
                let bounding = mesh.getBoundingInfo();
                const { maximumWorld, minimumWorld } = bounding.boundingBox;
                const distMax = Vector3.Distance(maximumWorld, camPos);
                const distMin = Vector3.Distance(minimumWorld, camPos);
                const distCenter = Vector3.Distance(mesh.absolutePosition, camPos);
                const dist = Math.min(distCenter, distMax, distMin);
                if (dist > 0 && dist < MESH_FADEOUT_DIST + 1) {
                    const vis = Math.max(dist - MESH_FADEOUT_DIST, 0);
                    setMeshVisibility(mesh, vis);
                } else {
                    setMeshVisibility(mesh, 1);
                }
            });
        });
    }

    private buildCamera(name: string): FreeCamera {
        const camera = new FreeCamera(name, new Vector3(0, 0, this.cameraZ), this.scene);
        camera.setTarget(Vector3.Zero());
        return camera;
    }

    private buildPickPlane(name: string): Mesh {
        const plane = CreatePlane(name, { width: 10000, height: 10000 }, this.scene);
        plane.position.z = 10;
        plane.isVisible = false;
        plane.isPickable = false;
        return plane;
    }

    // private addHelper() {
    //     this.planeFollow.alwaysSelectAsActiveMesh = true;
    //     this.planeFollow.isVisible = true;
    //     this.planeFollow.visibility = 0.5;
    //     const gridMaterial = new GridMaterial('', this.scene);
    //     this.planeFollow.material = gridMaterial;
    // }

    public htmlParent: HTMLElement;

    public setHtmlParent(htmlParent: HTMLElement) {
        this.htmlParent = htmlParent;
    }

    // private calculateUnitSize(zPosition: number): ISize {
    //     const distance = zPosition - this.cameraZ;
    //     const vFov = this.freeCamera.fov;
    //     const height = 2 * Math.tan(vFov / 2) * distance;
    //     const width = height * this.engine.getAspectRatio(this.freeCamera);
    //     return {
    //         width,
    //         height
    //     };
    // }

    public pickInFrontOfCamera(pos: Vector2): PickingInfo {
        return this.pick(pos, this.cameraFixed, this.planeFree);
    }

    public pickFromPosition(pos: Vector2, pickHtmlPlaneOnly: boolean): PickingInfo {
        if (pickHtmlPlaneOnly) {
            return this.pick(pos, this.cameraFixed, this.planeFixed);
        }
        return this.pick(pos, this.freeCamera);
    }

    public pickFromPositionStatic(pos: Vector2, plane?: Mesh): PickingInfo {
        const planeUsed = plane || this.planeFollow;
        const pick = this.pick(pos, this.freeCamera, planeUsed);
        if (plane && pick.hit) {
            pick.pickedPoint.subtractInPlace(this.freeCamera.globalPosition);
        }
        return pick;
    }

    private pick(pos: Vector2, camera: FreeCamera, plane?: Mesh) {
        if (plane) {
            return this.scene.pick(
                pos.x,
                pos.y,
                (m) => m === plane,
                true,
                camera
            );
        }
        //* Do not use fast check or pick is not precise enough
        // return this.scene.pick(pos.x, pos.y, null, true, camera);
        return this.scene.pick(pos.x, pos.y, null, false, camera);
    }

    public isMeshCloseToCamera(mesh: TransformNode): boolean {
        const dist = Vector3.Distance(this.freeCamera.globalPosition, mesh.getAbsolutePosition());
        return dist < 40;
    }
}
