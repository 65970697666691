import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Vector3 } from '@babylonjs/core/Maths/math';

import { System } from '../System/system';
import { Geometry } from './nodePosition';
import { NodeScaling } from './nodeScaling';

export class NodeInteraction extends NodeScaling {
    protected interactionParent: TransformNode;

    protected node: TransformNode;

    constructor(system: System, html?: HTMLElement) {
        super(system, html);
        this.interactionParent = new TransformNode('parent', this.system.scene);
        this.interactionParent.parent = this.geometryParent;
    }

    protected setNode(node: TransformNode) {
        node.parent = this.interactionParent;
        this.node = node;
    }

    public unsetNode() {
        this.node.parent = null;
    }

    protected resetGeometryInteraction() {
        this.interactionParent.position = Vector3.Zero();
        this.interactionParent.rotation = Vector3.Zero();
        this.interactionParent.scaling = Vector3.One();
    }

    public setGeometryInteraction(geometryOptions: Geometry) {
        const geoKeys = Object.keys(geometryOptions);
        geoKeys.forEach((key) => {
            const vector = geometryOptions[key];
            const vectorKeys = Object.keys(vector);
            vectorKeys.forEach((axe) => {
                const value = vector[axe];
                this.interactionParent[key][axe] = value;
            });
        });
    }
}
