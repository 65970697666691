import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import remove from 'lodash/remove';
import find from 'lodash/find';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';

import { Observable } from '../Tools/observable';
import { System } from '../System/system';
import { ElementImage, IMAGE_TAG, VIDEO_TAG } from './elementImage';
import { ElementText, TEXT_BUTTON_HEADING_TAGS } from './elementText';
import { ElementShape } from './elementShape';
import { MouseCatcher, NakerMouseEvent } from '~src/Catchers/mouseCatcher';
import { ScrollCatcher } from '~src/Catchers/scrollCatcher';
import { isButtonTag, isImageOrVideoTag, setHtmlSourceTag } from '~src/Tools/toolClasses';
import { Timeline } from '~src/System/systemTimeline';
import { IShader } from '~src/Material/Shader/shader';

export const isTextElement = (element: HTMLElement): boolean => {
    // Make sure this is a text parent
    //! With <br> you can have several children
    // if (element.childNodes.length <= 1) {
    const child = element.childNodes[0];
    if (!child || child.nodeType === Node.TEXT_NODE) return true;
    return false;
    // }
    // return false;
};

export enum ElementEvent {
    Added,
    Removed,
    Loaded,
    Hovered,
    Clicked
}

export class ElementsManager extends Observable<ElementEvent, ElementShape> {
    system: System;

    browser: HTMLElement;

    checkFontTimeline: Timeline;

    constructor(
        system: System,
        browser: HTMLElement,
        mouseCatcher: MouseCatcher,
        scrollCatcher: ScrollCatcher
    ) {
        super('Element Manager');
        this.system = system;
        this.browser = browser;

        mouseCatcher.on(NakerMouseEvent.MOVE, () => {
            if (!mouseCatcher.inCenter) {
                this.checkHoveredMeshAndElement(mouseCatcher.position);
                this.checkCursor();
            }
        });

        mouseCatcher.on(NakerMouseEvent.CLICK, () => {
            this.checkHoveredMeshAndElement(mouseCatcher.position);
            if (this.isClickableAndClose()) {
                const element = this.getHoveredElement();
                element.html.click(); //! Click to force href change
                this.notify(ElementEvent.Clicked, element);
            }
        });

        scrollCatcher.onChange(() => {
            this.checkHoveredMeshAndElement(mouseCatcher.position);
        });

        browser.addEventListener('pointerleave', () => {
            this.unHoverElement();
        });

        this.checkFontTimeline = new Timeline(system, 'checkfont');
    }

    private _listenEvent = true;

    public set listenEvent(v: boolean) {
        this._listenEvent = v;
    }

    private buttonMaxDistance = 30;

    private isClickableAndClose(): boolean {
        if (!this._listenEvent) return false;
        const element = this.getHoveredElement();
        if (element) {
            const { tagName } = element.html
            if (isButtonTag(tagName) || isImageOrVideoTag(tagName)) {
                const camPos = this.system.freeCamera.globalPosition;
                const elPos = element.mesh.absolutePosition;
                // So that we do not trigger cursor change when button is far from camera
                return Vector3.Distance(camPos, elPos) < this.buttonMaxDistance;
            }
        }
        return false;
    }

    private checkCursor() {
        if (this.isClickableAndClose()) {
            this.browser.style.cursor = 'pointer';
        } else {
            this.browser.style.cursor = 'default';
        }
    }

    private hoveredMesh: AbstractMesh;

    private hoveredElement: ElementShape;

    private unHoverElement() {
        if (this.hoveredElement) {
            this.hoveredMesh = null;
            this.hoveredElement = null;
            this.notify(ElementEvent.Hovered, null);
        }
    }

    private checkHoveredMeshAndElement(position: Vector2) {
        const pick = this.system.pickFromPosition(position, false);
        if (pick.hit) {
            this.hoveredMesh = pick.pickedMesh;
            const hoveredElement = this.getElementFromMesh(this.hoveredMesh);
            if (hoveredElement !== this.hoveredElement) {
                this.notify(ElementEvent.Hovered, hoveredElement);
                this.hoveredElement = hoveredElement;
            }
        } else {
            this.unHoverElement();
        }
    }

    public getHoveredMesh(): AbstractMesh {
        return this.hoveredMesh;
    }

    public getHoveredElement(): ElementShape {
        return this.hoveredElement;
    }

    public getElementImageFromMesh(mesh: AbstractMesh): ElementImage {
        return find(this.elementImages, (e) => e.mesh && e.mesh.name === mesh.name);
    }

    public getElementFromMesh(mesh: AbstractMesh): ElementImage {
        let element;
        while (!element && mesh.parent) {
            element = find(this.elements, (e) => e.mesh && e.mesh.name === mesh.name);
            mesh = mesh.parent;
        }
        return element;
    }

    public getElementTextFromHtml(html: HTMLElement): ElementText {
        return find(this.elementTexts, (e) => e.html === html);
    }

    public getElementFromHtml(html: HTMLElement): ElementText {
        return find(this.elements, (e) => e.html === html);
    }

    public getElementFromId(id: string): ElementText {
        return find(this.elements, (e) => e.id === id);
    }

    public getElementsFromClass(clas: string): ElementShape[] {
        return filter(this.elements, (e) => e.html.classList.contains(clas));
    }

    public checkAll() {
        if (this.system.launched) {
            this.checkNewImages();
            this.checkNewTexts();
            this.checkGeometries();
        }
    }

    public checkStyle() {
        setTimeout(() => {
            this.checkGeometries();
        }, 50);
        //* 200 to let time for the text font size to adjust
        this.system.quickRender(200);
    }

    private htmlImages: HTMLElement[] = [];

    private checkNewImages() {
        const images = this.browser.querySelectorAll(`${IMAGE_TAG}, ${VIDEO_TAG}`);
        this.htmlImages.forEach((image) => {
            //! Can't use indexOf here
            if (!find(images, (i) => i === image)) {
                this.removeImage(image);
            }
        });
        images.forEach((image) => {
            if (image.hasAttribute('src')) {
                if (this.htmlImages.indexOf(image) === -1) {
                    this.addImage(image);
                }
            }
        });
        this.checkImagesSource();
    }

    private elementImages: Array<ElementImage> = [];

    public addImage(imageHtml: HTMLImageElement): ElementImage {
        this.htmlImages.push(imageHtml);
        const elementImage = new ElementImage(this.system, imageHtml);
        this.elementImages.push(elementImage);
        this.addElement(elementImage);
        imageHtml.className = "img"
        return elementImage;
    }

    private removeImage(imageHtml: HTMLElement) {
        remove(this.htmlImages, (i) => i === imageHtml);
        const elementImage = remove(this.elementImages, (i) => i.html === imageHtml)[0];
        if (elementImage) this.removeElement(elementImage);
    }

    public checkImagesSource() {
        this.elementImages.forEach((image) => {
            if (image.source !== image.html.src) {
                image.loadVisual(() => {
                    // Wait a bit so that html is actually updated
                    setTimeout(() => {
                        // Once image loaded, height of section can change
                        this.checkGeometries();
                        this.notify(ElementEvent.Loaded, image);
                    }, 10)
                });
            }
        });
    }

    public setImageUrl(element: ElementImage, url: string): HTMLImageElement {
        const image = element as ElementImage;
        const { html } = image;
        let newHtml = setHtmlSourceTag(html, url);
        newHtml.className = "img"
        remove(this.htmlImages, (i) => i === html);
        this.htmlImages.push(newHtml);
        element.setHtml(newHtml);
        this.checkImagesSource();
        return newHtml;
    }

    private htmlTexts: HTMLElement[] = [];

    private checkNewTexts() {
        const seletorString = TEXT_BUTTON_HEADING_TAGS.join(', ').toLowerCase();
        const texts = this.browser.querySelectorAll(seletorString);
        this.htmlTexts.forEach((text) => {
            if (!find(texts, (i) => i === text)) {
                this.removeText(text);
            }
        });
        texts.forEach((text) => {
            if (isTextElement(text) && this.htmlTexts.indexOf(text) === -1) {
                this.addText(text);
            }
        });
    }

    private elementTexts: Array<ElementText> = [];

    public addText(textHtml: HTMLElement): ElementText {
        this.htmlTexts.push(textHtml);
        const elementText = new ElementText(this.system, textHtml);
        this.elementTexts.push(elementText);
        this.addElement(elementText);
        return elementText;
    }

    private removeText(textHtml: HTMLElement) {
        remove(this.htmlTexts, (i) => i === textHtml);
        const elmentText = remove(this.elementTexts, (i) => i.html === textHtml)[0];
        if (elmentText) this.removeElement(elmentText);
    }

    public removeClass(clas: string) {
        this.htmlTexts.forEach((t) => {
            t.classList.remove(clas);
        });
        this.htmlImages.forEach((i) => {
            i.classList.remove(clas);
        });
        this.removeShader(clas);
    }

    public checkGeometries() {
        if (!this.elements.length) return;
        this.elements.forEach((element) => {
            element.checkGeometry();
        });
    }

    public elements: ElementShape[] = [];

    public getElements(): ElementShape[] {
        return this.elements;
    }

    private addElement(element: ElementShape) {
        this.elements.push(element);
        this.checkElementShader(element);
        this.notify(ElementEvent.Added, element);
    }

    public removeElementFromId(id: string) {
        const element = this.getElementFromId(id);
        if (element) this.removeElement(element);
    }

    public removeElement(element: ElementShape) {
        this.notify(ElementEvent.Removed, element);
        element.remove();
    }

    public removeAllElements() {
        this.elements.forEach((element) => {
            element.remove();
        });
        this.elements = [];
    }

    public removeAllElementsAttributes() {
        this.elements.forEach((element) => {
            element.removeAttributes();
        });
    }

    private _shaders: IShader[] = [];

    public set shaders(shaders: IShader[]) {
        shaders.forEach((v) => {
            this.setShader(v.name, v);
        });
        this._shaders = shaders;
    }

    public get shaders(): IShader[] {
        return cloneDeep(this._shaders);
    }

    private checkElementShader(e: ElementShape) {
        const selector = e.html.classList[0];
        const s = this.getShader(selector);
        if (s) e.setAndSaveShader(s);
    }

    private updateShader(selector: string) {
        const s = this.getShader(selector);
        const elements = this.getElementsFromClass(selector);
        elements.forEach((e) => {
            //! Do not delete shader or it is super slow to update
            // e.deleteShader();
            e.setAndSaveShader(s);
        });
    }

    public deleteShader(selector: string) {
        const s = this.getShader(selector);
        const keys = Object.keys(s);
        keys.forEach((k) => {
            if (k !== 'name') delete s[k];
        });
        this.updateShader(selector);
    }

    public setShader(selector: string, shader: IShader) {
        if (Object.keys(shader).length === 0) return this.deleteShader(selector);
        const s = this.getShader(selector);
        const keys = Object.keys(shader);
        keys.forEach((k) => {
            s[k] = shader[k];
        });
        this.updateShader(selector);
    }

    public setElementShader(e: ElementShape, selector: string) {
        const s = this.getShader(selector);
        e.initShader();
        e.setAndSaveShader(s);
    }

    public getShader(selector: string): IShader {
        let s = find(this._shaders, (s) => s.name === selector);
        if (!s) {
            s = { name: selector };
            this._shaders.push(s);
        }
        return s;
    }

    private removeShader(selector: string) {
        remove(this._shaders, (s) => s.name === selector);
        const elements = this.getElementsFromClass(selector);
        elements.forEach((e) => {
            e.initShader();
        });
    }
}
