import remove from 'lodash/remove';
import find from 'lodash/find';

import { Document, IDocumentOption } from './document';
import { ElementEvent, ElementsManager } from '~src/Element/elementsManager';
import { SectionsManager } from '~src/Section/sectionsManager';
import { EMPTY_IMAGE_LINK } from '~services/type';
import { getRandomId, reorderArray } from '~services/util';
import { System } from '~src/System/system';
import { ScrollCatcher } from '~src/Catchers/scrollCatcher';
import { SectionMenu } from '~src/Section/sectionMenu';
import { getDefaultElementFromUrl } from '~src/Tools/toolClasses';
import { TShape } from '~src/Element/elementShape';

export class DocumentsManager extends SectionsManager {

    private sectionMenu: SectionMenu

    constructor(
        system: System,
        browser: HTMLElement,
        scrollCatcher: ScrollCatcher,
        elementsManager: ElementsManager,
        sectionMenu: SectionMenu
    ) {
        super(system, browser, scrollCatcher, elementsManager);
        this.sectionMenu = sectionMenu;

        sectionMenu.onHide(() => {
            this.openedDocument = null;
        })
    }

    public set documentsOption(v: IDocumentOption[]) {
        v.forEach((d) => {
            this.addDocument(d)
        });
    }

    public get documentsOption(): IDocumentOption[] {
        const documentsOption: IDocumentOption[] = []
        this._documents.forEach((d) => {
            documentsOption.push(d.options)
        });
        return documentsOption
    }

    public set shapes(v: TShape) {
        this._documents.forEach((d) => {
            d.shape = v
        });
    }

    public get shapes(): TShape {
        return (this._documents[0]) ? this._documents[0].shape : 'Card';
    }

    private _documents: Document[] = []

    public get documents(): Document[] {
        return [...this._documents]
    }

    public addNewDocument(): Document {
        const docNumber = this._documents.length + 1;
        const name = `Document ${docNumber}`;
        const document: IDocumentOption = {
            id: getRandomId(),
            name,
            thumbnail: EMPTY_IMAGE_LINK
        }
        const newDoc = this.addDocument(document);
        if (this.shapes) newDoc.shape = this.shapes
        return newDoc;
    }

    public addDocument(d: IDocumentOption): Document {
        const { name, thumbnail } = d
        const media = getDefaultElementFromUrl(thumbnail);
        const docHtml = `
            <section class="fdb-block" name="${name}">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10 col-md-8">
                            <h1>${name}</h1>
                            ${media}
                        </div>
                    </div>
                </div>
            </section>
        `
        const section = this.addSectionFromString(docHtml);
        this.elementsManager.checkAll()
        const document = new Document(section, this.sectionMenu);
        document.options = d;
        this._documents.push(document)
        return document;
    }

    public removeDocument(d: Document) {
        remove(this._documents, (s) => s.id === d.id);
        this.removeSection(d.section)
    }

    public reorderDocuments(startIndex: number, endIndex: number): Document[] {
        this._documents = reorderArray(this._documents, startIndex, endIndex);
        this.reorderSections(startIndex, endIndex);
        return this._documents;
    }

    private openedDocument: Document;

    public onClickedDoc: (doc: Document) => void;

    public setClickEvent() {
        this.elementsManager.on(ElementEvent.Clicked, (e) => {
            if (this.openedDocument) return
            let doc = find(this._documents, (s) => s.thumbnailShape === e) as Document;
            if (doc) {
                if (this.onClickedDoc) this.onClickedDoc(doc);
                if (doc.medias.length || doc.description) {
                    doc.show();
                    this.openedDocument = doc;
                }
            }
        })
    }

    public getDocumentFocused(): Document {
        const sectionFocused = this.getSectionFocused()
        return find(this.documents, (d) => d.section === sectionFocused)
    }
}
