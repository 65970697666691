import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import { getRandomId } from '~services/util';
import { InteractionStep, ShaderInteraction, EVENT } from '~src/Material/Shader/shaderInteraction';
import { ElementsManager } from '~src/Element/elementsManager';
import { ShaderCamera } from '~src/Material/Camera/shaderCamera';
import { System } from '~src/System/system';
import { IShaderFilter } from '~src/Material/Shader/shader';

export interface InteractionOptions {
    event: EVENT,
    target: string[],
    delay: number,
    duration: number,
    ease: string,
    reverse: boolean,
    change: IShaderFilter,
    percentage?: number,
    preset?: string,
}

export const EMPTYINTERACTION: InteractionOptions = {
    event: EVENT.MOVE,
    target: ['camera'],
    delay: 0,
    duration: 1,
    percentage: 0,
    ease: 'power0.in',
    reverse: false,
    change: {},
};

export class InteractionElements implements InteractionOptions {
    public id: string;

    public name: string;

    public elements: ShaderInteraction[] = [];

    public percentage: number;

    public event: EVENT;

    public target: string[];

    public delay: number;

    public duration: number;

    public ease: string;

    public reverse: boolean;

    public change: IShaderFilter;

    public steps: InteractionStep[];

    protected system: System;

    protected browser: HTMLElement;

    private elementsManager: ElementsManager;

    protected shaderCamera: ShaderCamera;

    constructor(
        system: System,
        browser: HTMLElement,
        elementsManager: ElementsManager,
        shaderCamera: ShaderCamera,
        options: InteractionOptions
    ) {
        this.system = system;
        this.browser = browser;
        this.elementsManager = elementsManager;
        this.shaderCamera = shaderCamera;

        this.steps = [
            { percentage: 0 },
            { percentage: 1 }
        ];
        const keys = Object.keys(options);
        keys.forEach((key) => {
            this[key] = options[key];
        });
        this.id = getRandomId();
    }

    public get options(): InteractionOptions {
        const exportInteraction: InteractionOptions = {
            event: this.event,
            target: this.target,
            delay: this.delay,
            duration: this.duration,
            ease: this.ease,
            reverse: this.reverse,
            change: cloneDeep(this.change),
        };
        return exportInteraction;
    }

    public setStartInteraction() {
        const step = this.steps[0];
        this.setElementsStep(step);
    }

    public setEndInteraction() {
        const step = this.steps[this.steps.length - 1];
        this.setElementsStep(step);
    }

    protected setElementsStep(interactionStep: InteractionStep) {
        this.elements.forEach((element) => {
            element.setInteractionValues(interactionStep);
        });
    }

    protected isMoveEvent(): boolean {
        return this.event === EVENT.MOVE;
    }

    protected isMouseHoverEvent(): boolean {
        return this.event === EVENT.HOVER;
    }

    protected isMouseHoverClickEvent(): boolean {
        return this.event === EVENT.HOVER
            || this.event === EVENT.CLICK
            || this.event === EVENT.MENU;
    }

    public isMoveCameraInteraction(): boolean {
        return this.isCameraInteraction() && (this.isMouseHoverEvent() || this.isMoveEvent());
    }

    protected isScrollEvent(): boolean {
        return this.event === EVENT.SCROLL;
    }

    public isGeometricInteraction(): boolean {
        return (this.isCameraInteraction() && !this.isMouseHoverEvent())
            || !this.isMouseHoverClickEvent();
    }

    public isCameraInteraction(): boolean {
        return isEqual(this.target, ['camera']);
    }

    public checkInteractionElements() {
        this.elements = this.getInteractionElements();
        //! Do not set move and scroll as it already already constantly changing
        if (!this.isScrollEvent() && !this.isMoveEvent()) {
            const startStep = this.reverse ? this.steps[1] : this.steps[0];
            this.elements.forEach((e) => {
                e.setStep(startStep);
            });
        }
    }

    protected getInteractionElements(): ShaderInteraction[] {
        let elements: ShaderInteraction[];
        if (this.isCameraInteraction()) {
            elements = [this.shaderCamera];
        } else {
            elements = this.elementsManager.getElementsFromClass(this.target[0]);
        }
        return elements;
    }

    public getVisibleElements(notVisible?: boolean): ShaderInteraction[] {
        const visibleElements = [];
        this.elements.forEach((element) => {
            if (element.mesh) {
                const elementVisible = this.system.isMeshCloseToCamera(element.geometryParent);
                const check = (notVisible) ? !elementVisible : elementVisible;
                if (check) visibleElements.push(element);
            }
        });
        return visibleElements;
    }

    public setName(name: string) {
        this.name = name;
    }
}
