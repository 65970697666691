import { getLinkTargetAttrString, getRgbArrayFromString, stringToHtml } from '~services/util';
import { LinkOptions, getChildrenLinks, setLinksFromArray } from './sectionLinks';

import { ShaderCamera } from '~src/Material/CameraSimple/shaderCamera';
import './section.sass'

const CROSS_ICON = '<svg class="center" aria-hidden="true" focusable="false" viewBox="0 0 24 24" role="img" width="30px" height="30px" fill="none"><path stroke="currentColor" stroke-width="2" d="M18.972 5.027L5.027 18.972m0-13.945l13.945 13.945"></path></svg>';
const MENU_ICON = '<i class="fa-solid fa-bars"></i>';

export interface IMenuOption {
    image?: string,
    links?: LinkOptions[]
}

export interface IMenuOptionSure {
    image: string,
    links: LinkOptions[]
}

const HEADER_HTML_STRING = `
    <header class="header">
        <div class="container">
            <nav class="navbar no-gutters">
                <div class="col-2 text-left">
                    <img src="https://d2w9uul1fh5o24.cloudfront.net/editor/main/logo-font.png" alt="image" />
                </div>
                
                <div class="navbar-toggler h1" data-toggle="collapse">
                    ${MENU_ICON}
                </div>
            </nav>
        </div>
    </header>
`
const HEADER_HTML = stringToHtml(HEADER_HTML_STRING);

const MENU_HTML_STRING = `
    <section class="fdb-block justify-content-center menu menu-button"></section>
`

const MENU_HTML = stringToHtml(MENU_HTML_STRING);

export class SectionMenu {

    public headerHtml: HTMLElement;

    public menuHtml: HTMLElement;

    private menuButtonHtml: HTMLElement;

    private imgHtml: HTMLImageElement;

    private containerHtml: HTMLElement;

    private shaderCamera: ShaderCamera;

    constructor(parent: HTMLElement, shaderCamera: ShaderCamera) {
        this.shaderCamera = shaderCamera;
        this.headerHtml = HEADER_HTML;
        parent.insertBefore(this.headerHtml, parent.childNodes[0]);
        this.menuHtml = MENU_HTML;

        this.imgHtml = this.headerHtml.querySelector('img') as HTMLImageElement;
        this.menuButtonHtml = this.headerHtml.querySelector('.navbar-toggler') as HTMLElement;
        this.containerHtml = document.createElement('div');
        this.containerHtml.className = 'html-container';
        parent.append(this.containerHtml);

        this.checkVisibility();

        // Wait for footer to be built
        setTimeout(() => {
            this.menuButtonHtml.addEventListener('click', () => {
                if (this.htmlShown) {
                    this.hideHtml();
                } else {
                    this.showHtml(this.menuHtml);
                }
            });
            this.menuHtml.addEventListener('click', (e) => {
                const { target } = e;
                if (target.className.includes('nav-link')) {
                    this.hideHtml();
                }
            });
        }, 200)
    }

    private _options: IMenuOptionSure = {
        image: '',
        links: [],
    }

    public set options(v: IMenuOption) {
        if (v.image !== undefined) this.image = v.image
        if (v.links !== undefined) this.links = v.links
    }

    public get options(): IMenuOptionSure {
        return { ...this._options };
    }

    public get image(): string {
        return this.imgHtml.src;
    }

    public set image(v: string) {
        this._options.image = v
        if (v) {
            this.imgHtml.src = v;
            this.imgHtml.style.display = 'flex';
        } else {
            this.imgHtml.removeAttribute('src');
            this.imgHtml.style.display = 'none';
        }
    }

    public get links(): LinkOptions[] {
        return getChildrenLinks(this.menuHtml.children, 'innerText');
    }

    public set links(links: LinkOptions[]) {
        this._options.links = links
        setLinksFromArray(
            this.menuHtml,
            links,
            (link) => {
                const target = getLinkTargetAttrString(link.href);
                return `<a class="nav-link" href="${link.href}" ${target}><div class="menu-button">${link.text}</div></a>`;
            }
        );
        this.checkVisibility();
    }

    private checkVisibility() {
        if (this._options.links.length === 0) this.menuButtonHtml.style.display = 'none';
        else this.menuButtonHtml.style.display = 'block';
    }

    private _onShow: (() => void)[] = []

    public onShow(callback: () => void) {
        this._onShow.push(callback)
    }

    private _onHide: (() => void)[] = []
    public onHide(callback: () => void) {
        this._onHide.push(callback)
    }

    public htmlShown: HTMLElement = null;

    public showHtml(html: HTMLElement) {
        this.containerHtml.classList.add('show');
        if (this.containerHtml.childNodes.length) {
            this.containerHtml.replaceChild(html, this.containerHtml.childNodes[0]);
        } else {
            this.containerHtml.appendChild(html);
        }
        this.menuButtonHtml.innerHTML = CROSS_ICON;
        this.htmlShown = html;
        this._onShow.forEach(c => { c() });
        this.containerHtml.scrollTo(0, 0);

        // Manage color
        const style = window.getComputedStyle(html, null);
        const backgroundColor = style.getPropertyValue('background-color');
        const clearColor = getRgbArrayFromString(backgroundColor);
        this.shaderCamera.setClearColor(clearColor);
        this.menuButtonHtml.style.display = 'block'
        const color = style.getPropertyValue('color');
        this.menuButtonHtml.style.color = color
    }

    public hideHtml() {
        this.containerHtml.classList.remove('show');
        this.menuButtonHtml.innerHTML = MENU_ICON;
        this.htmlShown = null;
        this.checkVisibility()
        this._onHide.forEach(c => { c() });
        // So that it follow default color again
        this.menuButtonHtml.style.color = null
    }
}
